import { HaeshLogoSmall } from '@assets/HaeshLogoSmall'
import { Spinner } from '@components/common'
import React from 'react'

type IAuthLayout = {
  children: React.ReactNode
  link?: { label: string; location: string }
  loading?: boolean
  title?: string
}

const AuthLayout = ({ children, loading, title, link }: IAuthLayout) => {
  return (
    <div className='min-h-screen bg-gray-50 min-w-screen'>
      <div className='flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8'>
        <div className='h-24 mx-auto text-center'>
          <HaeshLogoSmall height='115' />
        </div>
        {title && (
          <h2 className='mt-6 text-2xl font-extrabold text-center text-slate-800'>
            {title}
          </h2>
        )}
        {link && (
          <p className='mt-2 text-sm text-center text-gray-600'>
            <a
              className='font-medium text-orange-600 hover:text-orange-500'
              href={link.location}
            >
              {link.label}
            </a>
          </p>
        )}
        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10'>
            {loading && <Spinner />}
            <div className={loading ? 'hidden' : ''}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
