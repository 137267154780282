import jsonataMode from './jsonataMode'
import { FormLabel } from '@chakra-ui/react'
import Editor, { type Monaco } from '@monaco-editor/react'
import { useField } from 'formik'
import { useCallback, useEffect, useState } from 'react'

type JsonataInputProps = {
  disabled?: boolean
  label?: string
  maxHeight?: number
  name: string
  onValueChanged?: (
    event?: React.ChangeEvent | boolean | number | string | unknown
  ) => void
  size: 'multiLine' | 'oneLine'
}

const LINE_HEIGHT = 20

export const JsonataInput = ({
  label,
  disabled = false,
  name,
  size,
  maxHeight = 200,
  onValueChanged,
}: JsonataInputProps) => {
  const [fieldInputProps, fieldMetaProps, fieldHelperProps] = useField(name)
  const [value, setValue] = useState(fieldInputProps.value)
  const minHeightEditor = size === 'multiLine' ? 150 : 45

  const [height, setHeight] = useState(
    Math.min(
      maxHeight,
      minHeightEditor +
        ((value === undefined ? 1 : value.split('\n').length) - 1) * LINE_HEIGHT
    )
  )

  const handleChange = useCallback(
    (
      newValue: string | undefined,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      fieldHelperProps.setTouched(true)
      if (typeof onValueChanged === 'function') {
        onValueChanged(event)
      }

      if (newValue === undefined) {
        fieldHelperProps.setValue(undefined)
        return
      }

      setValue(newValue)
      fieldHelperProps.setValue(newValue)

      setHeight(
        Math.min(
          minHeightEditor + (newValue.split('\n').length - 1) * LINE_HEIGHT,
          maxHeight
        )
      )
    },
    [fieldHelperProps, maxHeight, minHeightEditor, onValueChanged]
  )

  useEffect(() => {
    setValue(fieldInputProps.value)
  }, [fieldInputProps.value])

  const isLabelShown = label !== undefined

  const isErrorShown = fieldMetaProps.error !== undefined

  const handleEditorMount = (editor: Monaco) => {
    editor.onDidBlurEditorText(() => fieldHelperProps.setTouched(true))
  }

  return (
    <div className={disabled ? `cursor-not-allowed ` : ''}>
      {isLabelShown && (
        <FormLabel>
          {label}
          {(fieldInputProps.value === undefined ||
            fieldInputProps.value === '') && (
            <span className='text-red-500'> *</span>
          )}
        </FormLabel>
      )}
      <Editor
        beforeMount={jsonataMode}
        className={`${
          disabled ? 'bg-gray-100 pointer-events-none' : ''
        } pt-2 mt-1 cursor-not-allowed overflow-hidden border
        border-slate-300 rounded-md mouse-pointer`}
        height={height}
        language='jsonata'
        onChange={handleChange}
        onMount={handleEditorMount}
        options={{
          automaticLayout: true,
          disabled,
          lineNumbers: 'off',
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          tabSize: 2,
        }}
        theme={disabled ? 'jsonataThemeDisabled' : 'jsonataTheme'}
        value={value}
      />
      {isErrorShown && fieldMetaProps.touched && (
        <p className='mt-2 text-sm text-red-600'>{fieldMetaProps.error}</p>
      )}
    </div>
  )
}
