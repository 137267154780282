import { Button } from '@chakra-ui/react'
import { FormikInput } from '@components/forms'
import { type DiceApiStreamInfoScheduledCreate } from '@haesh/dice-api'
import { useFormikContext } from 'formik'

type CronEditorProps = {
  disabled?: boolean
  formikKey: string
}

const CronEditor = ({ disabled = false, formikKey }: CronEditorProps) => {
  const { setFieldValue, isSubmitting } =
    useFormikContext<DiceApiStreamInfoScheduledCreate>()

  return (
    <div className='flex flex-col space-y-3'>
      <div className='flex flex-row content-end space-x-5'>
        <FormikInput
          disabled={disabled || isSubmitting}
          label='Cron Schedule'
          name={formikKey}
          tooltip='Cron expression to configure cron trigger. (e.g. 0 0 * * ? * - fire at 12am every day")'
          type='text'
        />
        <Button
          className='mt-9'
          colorScheme='brand'
          isDisabled={disabled || isSubmitting}
          onClick={() => {
            setFieldValue(formikKey, '0 0 * * ? *')
          }}
          variant='ghost'
        >
          Every Day
        </Button>
      </div>
    </div>
  )
}

export default CronEditor
