import {
  ClipboardCheckIcon,
  ClipboardListIcon,
  DatabaseIcon,
  DocumentSearchIcon,
  HomeIcon,
  UsersIcon,
  VariableIcon,
} from '@heroicons/react/outline'

export const sidebarNavigation = [
  {
    icon: HomeIcon,
    name: 'Home',
    path: '/',
  },
  {
    icon: DocumentSearchIcon,
    name: 'Controls',
    path: '/data/topics',
  },
  {
    icon: ClipboardCheckIcon,
    name: 'Tasks',
    path: '/tasks',
  },
  {
    icon: DatabaseIcon,
    name: 'Data Sources',
    path: '/data/streams',
  },
  {
    icon: ClipboardListIcon,
    name: 'Events',
    path: '/data/events',
  },
  {
    icon: UsersIcon,
    name: 'Users and Roles',
    path: '/organization/users',
  },
  {
    icon: VariableIcon,
    name: 'System Admin',
    path: '/systemadmin/tenants',
    systemAdmin: true,
  },
]
