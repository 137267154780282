import { Text } from '@chakra-ui/react'
import { type DiceApiEvent } from '@haesh/dice-api'

export type ScheduledStreamErrorEventDetailProps = { event: DiceApiEvent }

const ScheduledStreamErrorEventDetail = ({
  event,
}: ScheduledStreamErrorEventDetailProps) => {
  if (event.details.type !== 'scheduled-stream-error') {
    return null
  }

  switch (event.details.errorType) {
    case '4xx-server-response':
    case '5xx-server-response':
      return (
        <Text>
          The server responded with a {event.details.statusCode} status code.
        </Text>
      )
    case 'request-timeout':
      return <Text>The request timed out.</Text>
    case 'dns-resolution-error':
      return <Text>The DNS resolution failed.</Text>
    case 'stream-write-failure':
      return (
        <Text>
          The stream write failed. This may be a problem on our end, please
          contact support.
        </Text>
      )
    case 'parse-error':
      return (
        <>
          <Text>The response could not be parsed as JSON.</Text>
          {typeof event.details.serverResponse === 'string' && (
            <Text className='mt-2 font-mono text-sm font-light whitespace-pre-wrap'>
              {event.details.serverResponse}
            </Text>
          )}
        </>
      )

    default:
      return <Text>Unknown error.</Text>
  }
}

export default ScheduledStreamErrorEventDetail
