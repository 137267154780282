import { Text } from '@chakra-ui/react'
import { type DiceApiEvent } from '@haesh/dice-api'

export type DetectiveResultEventDetailProps = { event: DiceApiEvent }

const DetectiveResultEventDetail = ({
  event,
}: DetectiveResultEventDetailProps) => {
  if (event.details.type !== 'detective-result') {
    return null
  }

  if (event.details.pass) {
    return <Text>A pass was detected.</Text>
  }

  return (
    <Text>
      {event.details.reason ??
        'A fail was detected, but no fail reason was provided.'}
    </Text>
  )
}

export default DetectiveResultEventDetail
