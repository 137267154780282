import {
  type PostSignaturesMutationBody,
  type PostKeysMutationBody,
  type PatchKeysKeyIdMutationBody,
} from '@haesh/dice-api'
import * as yup from 'yup'

export const SignatureCreateShape: yup.ObjectSchema<PostSignaturesMutationBody> =
  yup
    .object({
      data: yup.object({
        action: yup.string().required(),
        callbackToken: yup.string(),
        payload: yup.object(),
      }),
      type: yup
        .string()
        .required()
        .oneOf(['signatures'] as const),
    })
    .noUnknown(true)
    .strict()
    .defined()

export const KeysCreateShape: yup.ObjectSchema<PostKeysMutationBody> = yup
  .object({
    data: yup.object({
      attributes: yup.object({ description: yup.string() }),
      type: yup
        .string()
        .required()
        .oneOf(['keys'] as const),
    }),
  })
  .noUnknown(true)
  .strict()
  .defined()

export const KeysUpdateShape: yup.ObjectSchema<PatchKeysKeyIdMutationBody> = yup
  .object({
    data: yup.object({
      attributes: yup.object({
        description: yup.string(),
        disabled: yup.bool(),
      }),
      id: yup.string().required(),
      type: yup
        .string()
        .required()
        .oneOf(['keys'] as const),
    }),
  })
  .noUnknown(true)
  .strict()
  .defined()
