import ControlEventsDetail from './ControlEventsDetail'
import DetectiveResultEventDetail from './DetectiveResultEventDetail'
import ScheduledStreamErrorEventDetail from './ScheduledStreamErrorEventDetail'
import StreamEventsDetail from './StreamEventsDetail'
import { type DiceApiEvent } from '@haesh/dice-api'

type EventDetailsProps = {
  event: DiceApiEvent
}

const EventDetails = ({ event }: EventDetailsProps) => {
  switch (event.details.type) {
    case 'control-created':
      return <ControlEventsDetail event={event} />
    case 'control-updated':
      return <ControlEventsDetail event={event} />
    case 'stream-row-created':
      return <StreamEventsDetail event={event} />
    case 'stream-row-deleted':
      return <StreamEventsDetail event={event} />
    case 'stream-row-updated':
      return <StreamEventsDetail event={event} />
    case 'detective-result':
      return <DetectiveResultEventDetail event={event} />
    case 'detective-result-error':
      return <div className='mt-2 text-sm'>{event.details.detail}</div>
    case 'scheduled-stream-error':
      return <ScheduledStreamErrorEventDetail event={event} />
    case 'scheduled-stream-fetch-success':
      return (
        <div className='mt-2 text-sm'>
          The server returned a valid JSON response and status code{' '}
          {event.details.statusCode}.
        </div>
      )
    default:
      return <div className='mt-2 text-sm'>Unknown event</div>
  }
}

export default EventDetails
