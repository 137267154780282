import { type User } from '@haesh/dice-types'
import * as yup from 'yup'

export const formatName = (user: Partial<User>): string =>
  [user.profile?.givenName, user.profile?.familyName].filter(Boolean).join(' ')

export const YupUserLogin = {
  password: yup.string().trim().required('Password is required'),
  username: yup.string().trim().required('Username or E-Mail is required'),
}

export const isSecurePassword = async (password?: string) => {
  if (!password) return false
  const encoder = new TextEncoder().encode(password)
  const test = Array.from(
    new Uint8Array(await crypto.subtle.digest('SHA-1', encoder))
  )
  const passwordHashed = test.map(b => b.toString(16).padStart(2, '0')).join('')
  const string5 = passwordHashed.slice(0, 5)
  const stringRest = passwordHashed.slice(5)
  try {
    return await (
      await fetch(`https://api.pwnedpasswords.com/range/${string5}`)
    )
      .text()
      .then(value => !value.toLowerCase().includes(stringRest))
  } catch {
    throw new Error('Failed to fetch pwnedpasswords')
  }
}
