import { ErrorScreenFallback } from './ErrorScreenFallback'
import {
  ErrorBoundary as SentryErrorBoundary,
  type ErrorBoundaryProps,
} from '@sentry/react'

export const ErrorBoundary = ({
  fallback = ErrorScreenFallback,
  ...props
}: ErrorBoundaryProps) => (
  <SentryErrorBoundary fallback={fallback} showDialog {...props} />
)

export { type ErrorBoundaryProps } from '@sentry/react'
