import { type DiceApiEvent } from '@haesh/dice-api'
import { utf8_to_b64 } from '@utils'
import { Link, type LinkProps } from 'react-router-dom'

type StreamRowLinkProps = {
  rowId: string
  streamId: string
}

const StreamRowLink = ({
  streamId,
  rowId,
  children,
  ...linkProps
}: Omit<LinkProps, 'className' | 'to'> & StreamRowLinkProps) => {
  return (
    <Link
      {...linkProps}
      className='text-base font-medium text-primary-500 hover:text-primary-550 focus:outline-none hover:underline'
      to={`/data/streams/${streamId}/rows-b64/${utf8_to_b64(rowId)}`}
    >
      {children}
    </Link>
  )
}

type ControlRowLinkProps = {
  controlId: string
  rowId: string
  timestamp: string
}

const ControlRowLink = ({
  controlId,
  rowId,
  timestamp,
  children,
  ...linkProps
}: ControlRowLinkProps & Omit<LinkProps, 'className' | 'to'>) => {
  return (
    <Link
      {...linkProps}
      className='text-base font-medium text-primary-500 hover:text-primary-550 focus:outline-none hover:underline'
      to={`/controls/${controlId}/rows-b64/${utf8_to_b64(
        rowId
      )}/timestamps/${encodeURIComponent(timestamp)}`}
    >
      {children}
    </Link>
  )
}

type EventTitleProps = {
  event: DiceApiEvent
}

const EventTitle = ({ event }: EventTitleProps) => {
  switch (event.details.type) {
    case 'control-created':
      return (
        <div>
          Control{' '}
          <Link
            className='text-base font-medium text-primary-500 hover:text-primary-550 focus:outline-none hover:underline'
            to={`/controls/${event.details.ruleId}`}
          >
            {event.details.controlInfo.displayName}
          </Link>{' '}
          created
        </div>
      )
    case 'control-updated':
      return (
        <div>
          Control{' '}
          <Link
            className='text-base font-medium text-primary-500 hover:text-primary-550 focus:outline-none hover:underline'
            to={`/controls/${event.details.ruleId}`}
          >
            {event.details.oldControlInfo.displayName}
          </Link>{' '}
          updated
        </div>
      )
    case 'stream-row-created':
      return (
        <div>
          Row{' '}
          <StreamRowLink
            rowId={event.details.rowId}
            streamId={event.details.streamId}
          >
            {event.details.after?.title ?? event.details.rowId}
          </StreamRowLink>{' '}
          created
        </div>
      )
    case 'stream-row-deleted':
      return (
        <div>
          Row{' '}
          <StreamRowLink
            rowId={event.details.rowId}
            streamId={event.details.streamId}
          >
            {event.details.before?.title ?? event.details.rowId}
          </StreamRowLink>{' '}
          deleted
        </div>
      )
    case 'stream-row-updated':
      return (
        <div>
          Row{' '}
          <StreamRowLink
            rowId={event.details.rowId}
            streamId={event.details.streamId}
          >
            {event.details.after?.title ?? event.details.rowId}
          </StreamRowLink>{' '}
          updated
        </div>
      )
    case 'detective-result':
      return (
        <div>
          Execution{' '}
          <ControlRowLink
            controlId={event.details.ruleId}
            rowId={event.details.rowId}
            timestamp={event.timestamp}
          >
            {event.details.rowId}
          </ControlRowLink>{' '}
          {event.details.pass === true ? 'passed' : 'failed'}
        </div>
      )
    case 'detective-result-error':
      return <div>Error {event.details.title}</div>
    case 'scheduled-stream-error':
      return (
        <div>
          Executing{' '}
          <Link
            className='text-base font-medium text-primary-500 hover:text-primary-550 focus:outline-none hover:underline'
            to={`/data/streams/${event.details.streamId}`}
          >
            scheduled HTTP request
          </Link>{' '}
          failed.
        </div>
      )
    case 'scheduled-stream-fetch-success':
      return (
        <div>
          A{' '}
          <Link
            className='text-base font-medium text-primary-500 hover:text-primary-550 focus:outline-none hover:underline'
            to={`/data/streams/${event.details.streamId}`}
          >
            scheduled HTTP request
          </Link>{' '}
          completed successfully.
        </div>
      )
    default:
      return <div>Unknown event</div>
  }
}

export default EventTitle
