import { type FilterProps } from '.'
import { Box } from '@chakra-ui/react'
import { TimeRangePicker } from '@components/common/timeRangePicker/TimeRangePicker'
import { type Range, useSearchRange } from '@hooks/useSearchRange'
import { useEffect } from 'react'

/**
 * Helper function to convert a time range to a filter query for the API,
 * or `null` if the range has no start and end date.
 */
export const rangeToFilter = ({ startDate, endDate }: Range) =>
  startDate === undefined && endDate === undefined
    ? null
    : {
        range: {
          timestamp: {
            gte: startDate?.toISOString(),
            lte: endDate?.toISOString(),
          },
        },
      }

export const TimeRangeFilterBox = ({ onChangeFilter }: FilterProps<Range>) => {
  const [range] = useSearchRange()

  useEffect(() => {
    const filter = rangeToFilter(range)
    onChangeFilter?.(filter, range)
    // onChangeFilter will most likely be a new function on every render, so
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range])

  return (
    <Box className='flex flex-row justify-end'>
      <TimeRangePicker />
    </Box>
  )
}
