import { setTag } from '@sentry/react'
import { setDefaultOptions } from 'date-fns'

// the first value is from modern browsers, the second is for IE
const browserLocale = navigator.languages[0] || navigator.language

// we only support de and date-fns' default en-US for now
const localeImport =
  browserLocale.startsWith('de') === true
    ? import('date-fns/locale/de')
    : Promise.resolve({ default: undefined })

localeImport.then(({ default: locale }) => {
  // sets date-fns locale, for when week starts etc
  setDefaultOptions({ locale })
  // sets sentry tag for debugging
  setTag('locale', locale?.code ?? 'en-US')
})

export { browserLocale }
