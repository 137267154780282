import { type DiceApiEvent } from '@haesh/dice-api'
import {
  CheckCircleIcon,
  ClockIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  QuestionMarkCircleIcon,
  RefreshIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

type EventIconProps = {
  event: DiceApiEvent
}

const EventIcon = ({
  event,
  ...props
}: EventIconProps & React.ComponentProps<'svg'>) => {
  switch (event.details.type) {
    case 'control-created':
      return <PlusCircleIcon {...props} />
    case 'control-updated':
      return <RefreshIcon {...props} />
    case 'stream-row-created':
      return <PlusCircleIcon {...props} />
    case 'stream-row-deleted':
      return <MinusCircleIcon {...props} />
    case 'stream-row-updated':
      return <RefreshIcon {...props} />
    case 'detective-result':
      return event.details.pass === true ? (
        <CheckCircleIcon
          {...props}
          className={`${props.className} !text-green-400`}
        />
      ) : (
        <XCircleIcon
          {...props}
          className={`${props.className} !text-red-400`}
        />
      )
    case 'scheduled-stream-fetch-success':
      return <ClockIcon {...props} />
    case 'scheduled-stream-error':
    case 'detective-result-error':
      return (
        <ExclamationCircleIcon
          {...props}
          className={`${props.className} !text-red-400`}
        />
      )
    default:
      return <QuestionMarkCircleIcon {...props} />
  }
}

export default EventIcon
