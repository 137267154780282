import { useCallback, useMemo, useState } from 'react'

/**
 *  Custom hook for managing a combined filter state and handling changes to the filter.
 *
 *  @param initialFilters The initial filter state. This is an key-value object where the key
 *   is the unique name of the filter and the value is the filter itself. You can use this
 *   to set a base filter that is always applied, or to set initial values for the filters.
 *  @returns [combinedFilter: object, handleChangeFilter: function]
 *    combinedFilter is an object that contains the combined filter state, ready to be
 *    sent as an OpenSearch `query` object. handleChangeFilter is a callback function that
 *    should be used to update the filter state.
 */
export const useCombinedFilter = (initialFilters?: Record<string, unknown>) => {
  const [filters, setFilters] = useState<Record<string, unknown>>({
    ...initialFilters,
  })

  /**
   *  Handle the change of a filter
   *
   *  @param filterName The unique name of the filter that has been changed.
   *    This name is used as the key in the filter state object.
   *  @param newFilter The new value of the filter.
   *  @example handleChangeFilter('timeRange', newTimeRangeFilter)
   */
  const handleChangeFilter = useCallback(
    (filterName: string, newFilter: unknown) => {
      setFilters(previousFilters => {
        return { ...previousFilters, [filterName]: newFilter }
      })
    },
    []
  )

  /**
   * The combined filter state. This is an object that contains all the filters
   * connected by 'and' operator, ready to be sent as an OpenSearch `query` object.
   */
  const combinedFilter = useMemo(() => {
    return {
      bool: {
        // Remove falsy values from the filter state, as they break the API
        must: Object.values(filters).filter(Boolean),
      },
    }
  }, [filters])

  return [combinedFilter, handleChangeFilter] as const
}
