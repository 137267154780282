import { Button } from '@chakra-ui/react'
import AuthenticationWrapper from '@components/auth/AuthenticationWrapper'
import { Spinner } from '@components/common'
import Header from '@components/layout/multiColumnLayoutParts/Header'
import { DiceApiAccessRole, useGetUsersUserId } from '@haesh/dice-api'
import { useUser } from '@haesh/react-use-dice'
import { AuthState, type UserAttributes } from '@haesh/react-use-dice/lib/types'
import ErrorScreen from '@views/ErrorScreen'
import UserNoAccessScreen from '@views/UserNoAccessScreen'
import { useEffect, useState } from 'react'

const AppWithAuthenticator = ({ component }: { component: JSX.Element }) => {
  const { user, userAttributes, tenant, authState, signOut, loadUser } =
    useUser()

  const userId = (userAttributes as UserAttributes | undefined)?.sub ?? ''

  const [isReloadingUser, setIsReloadingUser] = useState(false)

  const {
    data: userProfile,
    refetch,
    isFetching,
  } = useGetUsersUserId(userId, { query: { enabled: Boolean(userId) } })

  useEffect(() => {
    if (userId) void refetch()
  }, [userAttributes, user, refetch, userId])

  const hasNoAccess = userProfile?.accessRole === DiceApiAccessRole['No Access']

  const idTokenInSync =
    (userProfile?.accessRole as string[] | undefined) ===
      (userAttributes as UserAttributes | undefined)?.accessRole ||
    (userProfile?.businessRoles as string[] | undefined)?.length ===
      (userAttributes as UserAttributes | undefined)?.businessRoles?.length

  useEffect(() => {
    if (!idTokenInSync && userProfile && !isReloadingUser) {
      setIsReloadingUser(true)
      void loadUser().finally(() => setIsReloadingUser(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idTokenInSync, loadUser, userProfile])

  // display blank page while loading
  if (authState === AuthState.Loading) {
    return <div />
  }

  if (!tenant || authState !== AuthState.SignedIn) {
    return <AuthenticationWrapper />
  }

  if (!userProfile && isFetching) {
    return (
      <div className='grid h-screen place-items-center'>
        <div>
          <Spinner />
        </div>
      </div>
    )
  }

  if (hasNoAccess) {
    return (
      <div className='flex h-screen overflow-hidden bg-gray-100'>
        <div className='flex flex-col flex-1 overflow-hidden'>
          <Header setSidebarOpen={() => undefined} />
          <UserNoAccessScreen />
        </div>
      </div>
    )
  }

  if (!userProfile) {
    return (
      <ErrorScreen
        ActionButton={<Button onClick={signOut}>Sign out</Button>}
        header='Authentication Error'
        subtext="We couldn't find your profile"
        title='Something went wrong'
      />
    )
  }

  return component
}

export const withAuthenticator = (
  component: JSX.Element
): (() => JSX.Element) => {
  const authScreen = () => <AppWithAuthenticator component={component} />
  return authScreen
}
