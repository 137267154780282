import QuestionMarkCircleIconOutline from './QuestionMarkCircleIconOutline'
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'

type TooltipPreviewProps = {
  children: React.ReactNode | string
  renderTooltipTrigger?: () => JSX.Element
}

const TooltipPreview = ({
  renderTooltipTrigger,
  children,
}: TooltipPreviewProps) => {
  return (
    <Popover
      closeOnBlur={false}
      colorScheme='slate'
      placement='top-start'
      returnFocusOnClose={false}
      trigger='hover'
    >
      <PopoverTrigger>
        <div>
          {renderTooltipTrigger?.() ?? (
            <QuestionMarkCircleIconOutline className='w-[1.125rem] h-[1.125rem] text-slate-500 translate-y-0.5' />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent _focus={{ outline: 'none !important;' }} shadow='sm'>
        <PopoverHeader fontWeight='normal'>
          <div className='text-sm text-slate-500'>{children}</div>
        </PopoverHeader>
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  )
}

export default TooltipPreview
