import HaeshLogoWhite from '@assets/HaeshLogoWhite'
import { useUser } from '@haesh/react-use-dice'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { sidebarNavigation } from '@router/nav'
import { classNames } from '@utils'
import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

type ISidebar = {
  setSidebarOpen: (value: boolean) => void
  sidebarOpen: boolean
}

const SidebarContent = () => {
  const { idTokenPayload } = useUser()

  return (
    <>
      {sidebarNavigation
        .filter(navitem => {
          return (
            !(navitem.systemAdmin === true) ||
            Boolean(idTokenPayload?.isSystemAdmin)
          )
        })
        .map(({ name, path, icon: Icon }) => {
          return (
            <NavLink
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-4 py-2.5 text-sm font-medium'
                )
              }
              key={name}
              to={path}
            >
              <Icon aria-hidden='true' className='flex-shrink-0 w-6 h-6 mr-5' />
              {name}
            </NavLink>
          )
        })}
    </>
  )
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: ISidebar) => {
  return (
    <>
      <Transition.Root as={Fragment} show={sidebarOpen}>
        <Dialog
          as='div'
          className='fixed inset-0 z-40 flex md:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-800'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 pt-2 -mr-12'>
                  <button
                    className='flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => {
                      setSidebarOpen(false)
                    }}
                    type='button'
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XIcon aria-hidden='true' className='w-6 h-6 text-white' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex items-center flex-shrink-0 px-4'>
                <HaeshLogoWhite />
              </div>
              <div className='flex-1 h-0 mt-5 overflow-y-auto'>
                <nav className='space-y-1'>
                  <SidebarContent />
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div aria-hidden='true' className='flex-shrink-0 w-14'>
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div className='hidden md:flex md:flex-shrink-0'>
        <div className='flex flex-col w-52'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex flex-col flex-1 min-h-0'>
            <div className='flex items-center flex-shrink-0 h-16 px-4 text-3xl font-bold text-white bg-gray-900'>
              <div className='scale-75 -translate-x-3'>
                <HaeshLogoWhite />
              </div>
            </div>
            <div className='flex flex-col flex-1 overflow-y-auto'>
              <nav className='flex-1 py-4 bg-gray-800'>
                <SidebarContent />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(Sidebar)
