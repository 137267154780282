export type EventCollectionsType = {
  description: string
  includedEvents: string[]
  label: string
  renderDescription: () => JSX.Element
  value: string
}

type EventSearchType = {
  description: string
  openSearch: unknown
  value: string
}

export const EventCollections = [
  {
    description: 'shows all result events for controls that are errors',
    includedEvents: ['detective-result-error'],
    label: 'Control Error Events',
    renderDescription: () => (
      <div className='text-xs'>
        All{' '}
        <span className='font-bold'>
          Control Result Events
          <span className='font-normal'> that are</span> Errors
        </span>
      </div>
    ),
    value: 'controlError',
  },
  {
    description: 'shows all result events for controls that are fail',
    includedEvents: ['detective-result-failed'],
    label: 'Control Fail Events',
    renderDescription: () => (
      <div className='text-xs'>
        All{' '}
        <span className='font-bold'>
          Control Result Events
          <span className='font-normal'> that are</span> Fails
        </span>
      </div>
    ),
    value: 'controlFail',
  },
  {
    description: 'shows all result events for controls that are pass',
    includedEvents: ['detective-result-passed'],

    label: 'Control Pass Events',
    renderDescription: () => (
      <div className='text-xs'>
        All{' '}
        <span className='font-bold'>
          Control Result Events
          <span className='font-normal'> that are</span> Passes
        </span>
      </div>
    ),
    value: 'controlPass',
  },
  {
    description:
      'shows all events connected to streams that are data triggered and that are not errors',
    includedEvents: [
      'stream-row-created',
      'stream-row-deleted',
      'stream-row-updated',
    ],
    label: 'Data Events',
    renderDescription: () => (
      <div className='text-xs'>
        All{' '}
        <span className='font-bold'>
          Stream Events
          <span className='font-normal'> that are</span> data triggered
          <span className='font-normal'> and that are</span> not errors
        </span>
      </div>
    ),
    value: 'data',
  },
] as EventCollectionsType[]

export const EventsOptions = [
  {
    description: 'shows all result events for controls that are errors',
    openSearch: {
      term: { type: 'detective-result-error' },
    },
    value: 'detective-result-error',
  },
  {
    description: 'shows all result events for controls that fail',
    openSearch: {
      bool: {
        must: [
          { term: { type: 'detective-result' } },
          { match: { 'payload.pass': false } },
        ],
      },
    },
    value: 'detective-result-failed',
  },
  {
    description: 'shows all result events for controls that pass',
    openSearch: {
      bool: {
        must: [
          { term: { type: 'detective-result' } },
          { match: { 'payload.pass': true } },
        ],
      },
    },
    value: 'detective-result-passed',
  },
  {
    description:
      'shows all events for streams that create a new row in the stream',
    openSearch: {
      term: { type: 'stream-row-created' },
    },
    value: 'stream-row-created',
  },
  {
    description: 'shows all events for streams that delete a row in the stream',
    openSearch: {
      term: { type: 'stream-row-deleted' },
    },
    value: 'stream-row-deleted',
  },
  {
    description: 'shows all events for streams that update a row in the stream',
    openSearch: {
      term: { type: 'stream-row-updated' },
    },
    value: 'stream-row-updated',
  },
] as EventSearchType[]
