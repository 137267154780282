import * as baseComponents from '@bangle.dev/base-components'
import { SpecRegistry } from '@bangle.dev/core'
import { markdownParser } from '@bangle.dev/markdown'

export const bangleComponents = [
  'paragraph',
  'bold',
  'bulletList',
  'hardBreak',
  'italic',
  'link',
  'listItem',
  'orderedList',
  'blockquote',
  'strike',
  'underline',
  'heading',
] as const

export const bangleSpecRegistry = new SpecRegistry(
  bangleComponents.map(component => baseComponents[component].spec())
)

export const banglePlugins = [
  bangleComponents.map(component => baseComponents[component].plugins()),
] as const

export const bangleParser = markdownParser(bangleSpecRegistry)
