import { LoadMoreButton } from './LoadMoreButton'
import { Box, Divider, SkeletonText, Text } from '@chakra-ui/react'
import { type UseInfiniteQueryResult } from '@tanstack/react-query'
import { Fragment } from 'react'

/**
 * Props for the InfiniteList component
 *
 * @template TData - The type of data returned by the infinite query
 * @template TError - The type of error returned by the infinite query
 * @template TItem - The type of each item in the list
 */
export type InfiniteListProps<TData, TError, TItem> = {
  /**
   * Component to render between each item in the list, but not at the top or bottom.
   * Default is a Divider component from Chakra UI.
   */
  ItemSeparatorComponent?: React.ReactNode
  /**
   * Component to render when the list is empty
   */
  ListEmptyComponent?: React.ReactNode | null
  /**
   * The infinite query result object returned by the useInfiniteQuery hook
   */
  infiniteQueryResult: UseInfiniteQueryResult<TData, TError>
  /**
   * A function that takes in the data object and extracts the list of items
   */
  itemsExtractor: (data: TData) => TItem[]
  /**
   * A function that takes in an item and an index and returns a unique key for that item
   */
  keyExtractor?: (item: TItem, index: number) => string
  /**
   * A function that takes in an item and an index and returns a React component to render that item
   */
  renderItem: ({ item, index }: { index: number; item: TItem }) => JSX.Element
}

/**
 * Default key extractor function that returns the index as a string
 *
 * @param item - The item for which to extract a key
 * @param index - The index of the item in the list
 */

const defaultKeyExtractor = <TItem,>(item: TItem, index: number): string =>
  index.toString()

/**
 * Component that renders a list of items with a "Load More" button.
 */
export const InfiniteList = <TData, TError, TItem>({
  infiniteQueryResult,
  renderItem,
  keyExtractor = defaultKeyExtractor,
  itemsExtractor,
  ListEmptyComponent,
  ItemSeparatorComponent = <Divider />,
}: InfiniteListProps<TData, TError, TItem>) => {
  const items =
    infiniteQueryResult.data?.pages.flatMap(page => itemsExtractor(page)) ?? []

  return (
    <SkeletonText isLoaded={infiniteQueryResult.isFetched}>
      {items.length === 0 ? (
        ListEmptyComponent === undefined ? (
          <Text className='text-center'>No items found.</Text>
        ) : (
          ListEmptyComponent
        )
      ) : (
        <Box className='flex flex-col'>
          {items.map((item, index) => (
            <Fragment key={keyExtractor(item, index)}>
              {renderItem({ index, item })}
              {index !== items.length - 1 && ItemSeparatorComponent}
            </Fragment>
          ))}
          <LoadMoreButton className='self-center' {...infiniteQueryResult} />
        </Box>
      )}
    </SkeletonText>
  )
}
