/**
 * content of `./generated` is generated via `yarn api:generate`
 */

import { type Schema } from 'jtd'
import {
  ForbiddenResponseMessage,
  NotFoundResponseMessage,
  ServerErrorResponseMessage,
  ValidationFailedResponseMessage,
  type ForbiddenResponse,
  type NotFoundResponse,
  type ServerErrorResponse,
  type ValidationFailedResponse,
  type UnauthorizedResponse,
  UnauthorizedResponseMessage,
  // eslint-disable-next-line import/extensions
} from './generated/diceApi.schemas'

export * from './generated/diceApi'
// eslint-disable-next-line import/extensions
export * from './generated/diceApi.schemas'
// eslint-disable-next-line import/extensions
export * from './generated/diceApi.routes'

export { stages, axiosInstance } from './api'

export { default as diceApiDefinition } from './definition/openapi.json'

// overwrite with a more specific type from `jtd` package
export type DiceApiJsonTypeDefinition = Schema

const messages = {
  400: ValidationFailedResponseMessage,
  401: UnauthorizedResponseMessage,
  403: ForbiddenResponseMessage,
  404: NotFoundResponseMessage,
  500: ServerErrorResponseMessage,
} as const
type MessageKeys<StatusCode extends keyof typeof messages> =
  keyof typeof messages[StatusCode]

const createApiError = <StatusCode extends keyof typeof messages>(
  message: typeof messages[StatusCode][keyof typeof messages[StatusCode]]
) => ({ message })

export type CommonErrorResponses = {
  400: ValidationFailedResponse
  401: UnauthorizedResponse
  403: ForbiddenResponse
  404: NotFoundResponse
  500: ServerErrorResponse
}

const merge = <StatusCode extends keyof CommonErrorResponses>(
  ...items: Array<Record<string, CommonErrorResponses[StatusCode]['message']>>
) => {
  const entries = items.flatMap(item => Object.entries(item))
  return Object.fromEntries(
    entries.map(([key, value]) => [
      key,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      createApiError<StatusCode>(value as any),
    ])
  ) as unknown as Record<
    MessageKeys<StatusCode>,
    CommonErrorResponses[StatusCode]
  >
}

export const ApiError = {
  400: merge<400>(ValidationFailedResponseMessage),
  401: merge<401>(UnauthorizedResponseMessage),
  403: merge<403>(ForbiddenResponseMessage),
  404: merge<404>(NotFoundResponseMessage),
  500: merge<500>(ServerErrorResponseMessage),
}
