import AuthLayout from './AuthLayout'
import { Button } from '@chakra-ui/react'
import { FormikInput } from '@components/forms'
import { useUser } from '@haesh/react-use-dice'
import { isSecurePassword } from '@utils'
import { Formik, type FormikHelpers } from 'formik'
import { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

const NewPasswordTemplate = () => {
  const { completeNewPassword, resetPassword, signIn, userAttributes } =
    useUser()

  const email = userAttributes.email

  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const [searchParameters] = useSearchParams()
  const code = searchParameters.get('code')
  const encodedUsername = searchParameters.get('credentials')
  const username = encodedUsername ? atob(encodedUsername) : undefined

  const handleSignIn = useCallback(
    async (
      { password }: { password: string },
      formikHelpers: FormikHelpers<{
        confirmPassword: string
        password: string
      }>
    ) => {
      setIsLoading(true)
      // eslint-disable-next-line security/detect-possible-timing-attacks
      if (password.trim() === email) {
        formikHelpers.setFieldError(
          'password',
          'Your password must not be your email!'
        )
        setIsLoading(false)
        return
      }

      if (await isSecurePassword(password.trim())) {
        if (code && username) {
          void resetPassword(username, code, password.trim())
            .then(async success => {
              if (success) {
                await signIn(username, password.trim()).then(() => {
                  navigate('/', { replace: true })
                })
              } else {
                navigate('/', { replace: true })
              }
            })
            .finally(() => setIsLoading(false))
        } else {
          void completeNewPassword(password.trim()).finally(() =>
            setIsLoading(false)
          )
        }
      } else {
        formikHelpers.setFieldError(
          'password',
          'Your password is not strong enough!'
        )
        setIsLoading(false)
      }
    },
    [
      code,
      completeNewPassword,
      navigate,
      resetPassword,
      signIn,
      email,
      username,
    ]
  )

  const NewPasswordSchema = Yup.object().shape({
    confirmPassword: Yup.string()
      .trim()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please repeat the password'),
    password: Yup.string()
      .trim()
      .min(8, 'Your password must be at least 8 characters long.')
      .required('Please enter new password'),
  })
  return (
    <AuthLayout title='Create a new password'>
      <div className='mb-8 text-base text-slate-700'>
        Your new password must be at least 8 characters long and be different
        from previous used passwords.
      </div>
      <Formik
        initialValues={{
          confirmPassword: '',
          password: '',
        }}
        onSubmit={handleSignIn}
        validationSchema={NewPasswordSchema}
      >
        {({ handleSubmit, isValid, submitForm }) => {
          return (
            <form className='space-y-6' noValidate onSubmit={handleSubmit}>
              <FormikInput
                label='New Password'
                name='password'
                type='password'
              />
              <FormikInput
                label='Confirm Password'
                name='confirmPassword'
                type='password'
              />
              <div>
                <Button
                  className='mt-4'
                  isDisabled={isLoading || !isValid}
                  isLoading={isLoading}
                  loadingText='Loading...'
                  onClick={() => {
                    void submitForm()
                  }}
                  spinnerPlacement='end'
                  width='full'
                >
                  Continue
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </AuthLayout>
  )
}

export default NewPasswordTemplate
