import AuthLayout from './AuthLayout'
import { Button } from '@chakra-ui/react'
import { FormikInput } from '@components/forms'
import { AuthState, useUser } from '@haesh/react-use-dice'
import { YupUserLogin } from '@utils'
import { Formik } from 'formik'
import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

const LoginTemplate = () => {
  const {
    tenant,
    signIn,
    loadingAuth,
    federatedSignIn,
    setTenant,
    setAuthState,
  } = useUser()
  const signInForm = Yup.object(YupUserLogin)

  const navigate = useNavigate()

  const [searchParameters, setSearchParameters] = useSearchParams()

  const handleSignIn = useCallback(
    ({ username, password }: { password?: string; username?: string }) => {
      if (username && password) void signIn(username, password)
    },
    [signIn]
  )

  const parseCredentialsInSearchParameters = useCallback(() => {
    const encodedCredentials = searchParameters.get('credentials')

    const demoUsername = searchParameters.get('username')
    const demoPassword = searchParameters.get('password')

    if (demoUsername && demoPassword) {
      return { password: demoPassword, username: demoUsername }
    }

    const code = searchParameters.get('code')
    if (encodedCredentials !== null) {
      const parsedCreds = atob(encodedCredentials)
      const username = parsedCreds.split(';')[0] ?? ''
      const password = parsedCreds.split(';')[1] ?? code ?? ''
      return { password, username }
    }

    return { password: '', username: '' }
  }, [searchParameters])

  useEffect(() => {
    const { password, username } = parseCredentialsInSearchParameters()
    if (username && password) {
      void signIn(username, password)
    }
  }, [])

  const handleSwitchTenant = useCallback(() => {
    localStorage.clear()
    setSearchParameters({}, { replace: true })
    navigate('/', { replace: true })
    setTenant(null)
  }, [navigate, setTenant, setSearchParameters])

  return (
    <AuthLayout title='Sign in to your account'>
      <div className='text-slate mb-6 text-lg font-semibold'>
        {tenant?.displayName}
      </div>
      {tenant?.usernamePasswordIsEnabled && (
        <Formik
          initialValues={parseCredentialsInSearchParameters()}
          onSubmit={handleSignIn}
          validationSchema={signInForm}
        >
          {({ handleSubmit, isValid, dirty, submitForm }) => {
            return (
              <form className='space-y-6' noValidate onSubmit={handleSubmit}>
                <FormikInput label='E-Mail' name='username' type='text' />
                <FormikInput label='Password' name='password' type='password' />
                <div className='flex items-center justify-end'>
                  <div
                    className='hover:text-orange-600 text-sm font-medium text-orange-500'
                    onClick={() => {
                      setAuthState(AuthState.ForgotPassword)
                    }}
                    role='button'
                  >
                    Forgot password?
                  </div>
                </div>
                <Button
                  isDisabled={loadingAuth || !isValid || !dirty}
                  isLoading={loadingAuth}
                  loadingText='Signing in'
                  onClick={() => {
                    void submitForm()
                  }}
                  spinnerPlacement='end'
                  width='full'
                >
                  Sign In
                </Button>
              </form>
            )
          }}
        </Formik>
      )}

      {tenant?.identityProviders && tenant.identityProviders.length > 0 && (
        <div className='mt-6'>
          {tenant.usernamePasswordIsEnabled && (
            <div className='relative'>
              <div className='absolute inset-0 flex items-center'>
                <div className='w-full border-t border-gray-300' />
              </div>
              <div className='relative flex justify-center text-sm'>
                <span className='px-2 text-gray-500 bg-white'>
                  Or use Enterprise SSO
                </span>
              </div>
            </div>
          )}
          <div className='mt-6 space-y-3'>
            {tenant.identityProviders.map(prv => {
              return (
                <div key={prv.displayName}>
                  <Button
                    _hover={{
                      bgColor: 'slate.100',
                      borderColor: 'slate.400',
                      textColor: 'slate.800',
                    }}
                    bgColor='slate.50'
                    borderColor='slate.300'
                    fontWeight='medium'
                    onClick={() => {
                      void federatedSignIn(prv.providerName)
                    }}
                    size='sm'
                    textColor='slate.700'
                    variant='outline'
                    width='full'
                  >
                    {prv.displayName}
                  </Button>
                </div>
              )
            })}
          </div>
        </div>
      )}
      <Button
        colorScheme='slate'
        display='block'
        fontWeight='medium'
        ml='auto'
        mt='8'
        onClick={handleSwitchTenant}
        size='xs'
        textColor='slate.500'
        variant='ghost'
      >
        Switch Organization
      </Button>
    </AuthLayout>
  )
}

export default LoginTemplate
