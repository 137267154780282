/* eslint-disable react/forbid-elements */
/* eslint-disable complexity */
/* eslint-disable react/forbid-component-props */
import SelectListbox from '../SelectListbox'
import {
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  Textarea,
  Tooltip,
} from '@chakra-ui/react'
import { ComboBox } from '@components/common/ComboBox'
import TooltipPreview from '@components/common/TooltipPreview'
import { MarkdownInput } from '@components/typography'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { classNames, handleNestedYupError } from '@utils'
import { type FieldHookConfig, useField } from 'formik'
import React, { memo, useMemo, useState } from 'react'

export type SelectOption = {
  label: string
  renderDescription?: () => JSX.Element
  value: unknown
}

export type FormikInputProps = FieldHookConfig<number | string> & {
  additionalError?: string
  additionalTouch?: boolean
  containerClasses?: string
  description?: string
  disabled?: boolean
  filterOptions?: (value: string) => SelectOption[]
  label?: string
  onDeleteOwner?: () => void
  onValueChanged?: (
    event?: React.ChangeEvent | boolean | number | string | unknown
  ) => void
  options?: SelectOption[]
  tooltip?: string
  type:
    | React.HTMLInputTypeAttribute
    | 'markdown'
    | 'select'
    | 'splitbutton'
    | 'switch'
}

const FormikInput = ({
  label,
  tooltip,
  type,
  placeholder,
  disabled,
  containerClasses,
  additionalTouch,
  onDeleteOwner,
  description,
  onValueChanged,
  options = [],
  ...props
}: FormikInputProps) => {
  const [
    formikField,
    { touched: fieldTouched, error },
    { setValue, setTouched },
  ] = useField(props)

  const touched = useMemo(
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    () => Boolean(additionalTouch || fieldTouched),
    [additionalTouch, fieldTouched]
  )

  const [passwordVisible, setPasswordVisible] = useState(false)
  const field = useMemo(
    () => ({
      ...formikField,
      onChange: (event: React.ChangeEvent) => {
        formikField.onChange(event)
        if (typeof onValueChanged === 'function') onValueChanged(event)
      },
    }),
    [formikField, onValueChanged]
  )

  const InputComponent = useMemo(() => {
    if (type === 'markdown' && import.meta.env.PROD) {
      return (
        <MarkdownInput
          disabled={disabled}
          onBlur={() => setTouched(true)}
          onChange={value => {
            setValue(value)
            if (typeof onValueChanged === 'function') onValueChanged()
          }}
          value={field.value.toString()}
        />
      )
    } else if (type === 'select') {
      return (
        <SelectListbox
          disabled={disabled}
          error={error}
          listboxKey={`${type}-${field.name}`}
          onBlur={() => setTouched(true)}
          onChange={(result: unknown) => {
            if (typeof onValueChanged === 'function') onValueChanged(result)
            // @ts-expect-error: yes we store an object and have to be extra careful
            setValue(result, true)
          }}
          options={options}
          placeholder={placeholder}
          touched={touched}
          value={field.value}
        />
      )
    } else if (type === 'combo') {
      return (
        <ComboBox
          comboBoxKey={`${type}-${field.name}`}
          disabled={disabled}
          error={error}
          onChange={(result: unknown) => {
            if (typeof onValueChanged === 'function') onValueChanged(result)
            // @ts-expect-error: yes we store an object and have to be extra careful
            setValue(result, true)
          }}
          onDeleteOwner={onDeleteOwner}
          options={options}
          preSelectedOption={field.value as string}
          touched={touched}
          type='form'
        />
      )
    } else if (type === 'switch') {
      return (
        <Switch
          key={`${type}-${field.name}`}
          size='lg'
          {...field}
          colorScheme='brand'
          isChecked={field.value as unknown as boolean}
          isDisabled={disabled}
          isFocusable={false}
          mt='0.5'
        />
      )
    } else if (type === 'splitbutton') {
      return (
        <div
          className='transition-all inline-flex group p-0.5 rounded-lg bg-slate-100 hover:bg-slate-100'
          key={`${type}-${field.name}`}
        >
          <Tooltip label={options[0]?.label}>
            <button
              className='flex rounded-md focus:outline-none'
              onClick={() => {
                if (typeof onValueChanged === 'function')
                  onValueChanged(options[0]?.value)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setValue(options[0]?.value as any)
              }}
              type='button'
            >
              <span
                className={`${
                  field.value === options[0]?.value
                    ? 'bg-white font-bold'
                    : 'bg-slate-100 text-slate-400 hover:text-slate-700'
                } p-1.5 lg:pl-2.5 lg:pr-3.5 rounded-md flex items-center text-sm font-medium shadow-sm `}
              >
                {options[0]?.label}
              </span>
            </button>
          </Tooltip>
          <Tooltip label={options[1]?.label}>
            <button
              className={`${
                field.value === options[1]?.value
                  ? 'bg-white font-bold'
                  : 'bg-slate-100 text-slate-400 hover:text-slate-700'
              } ml-0.5 p-1.5 lg:pl-2.5 lg:pr-3.5 rounded-md flex items-center text-sm font-medium focus:outline-none`}
              onClick={() => {
                if (typeof onValueChanged === 'function')
                  onValueChanged(options[1]?.value)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setValue(options[1]?.value as any)
              }}
              type='button'
            >
              {options[1]?.label}
            </button>
          </Tooltip>
        </div>
      )
    } else if (type === 'password') {
      return (
        <InputGroup>
          <Input
            key={`${type}-${field.name}`}
            {...field}
            backgroundColor='white'
            borderColor='slate.300'
            borderWidth='1px'
            colorScheme=''
            errorBorderColor='red.300'
            focusBorderColor='brand.400'
            isDisabled={disabled}
            isInvalid={Boolean(touched) && Boolean(error)}
            placeholder={placeholder ?? undefined}
            type={passwordVisible ? 'text' : 'password'}
          />
          <InputRightElement
            className='cursor-pointer'
            onClick={() => setPasswordVisible(!passwordVisible)}
            width='3.5rem'
          >
            {passwordVisible ? (
              <EyeOffIcon className='w-5 h-5 text-gray-500' />
            ) : (
              <EyeIcon className='w-5 h-5 text-gray-500' />
            )}
          </InputRightElement>
        </InputGroup>
      )
    } else if (type === 'textarea') {
      return (
        <Textarea
          key={`${type}-${field.name}`}
          {...field}
          backgroundColor='white'
          borderColor='slate.300'
          borderWidth='1px'
          colorScheme=''
          errorBorderColor='red.300'
          focusBorderColor='brand.400'
          height='sm'
          isDisabled={disabled}
          isInvalid={Boolean(touched) && Boolean(error)}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (typeof onValueChanged === 'function') {
              onValueChanged(event.target.value)
            }

            setValue(event.target.value)
          }}
          placeholder={placeholder ?? undefined}
          spellCheck='false'
        />
      )
    } else {
      return (
        <Input
          key={`${type}-${field.name}`}
          {...field}
          backgroundColor={disabled ? 'gray.200' : 'white'}
          borderColor='slate.300'
          borderWidth='1px'
          colorScheme=''
          errorBorderColor='red.300'
          focusBorderColor='brand.400'
          isDisabled={disabled}
          isInvalid={Boolean(touched) && Boolean(error)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (typeof onValueChanged === 'function') {
              onValueChanged(event)
            }

            setValue(event.target.value)
          }}
          placeholder={placeholder ?? undefined}
        />
      )
    }
  }, [
    type,
    disabled,
    field,
    setTouched,
    setValue,
    onValueChanged,
    error,
    touched,
    options,
    placeholder,
    passwordVisible,
  ])

  return (
    <div className={containerClasses}>
      {label && (
        <div className='flex'>
          <FormLabel htmlFor={field.name}>
            {label}
            {error ? (
              <span className='inline-block ml-1 text-base font-bold leading-3 text-red-500'>
                *
              </span>
            ) : null}
          </FormLabel>
          {tooltip && <TooltipPreview>{tooltip}</TooltipPreview>}
        </div>
      )}
      {description !== undefined && (
        <div className='-mt-2 text-sm text-slate-500'>{description}</div>
      )}
      <div className='relative mt-1'>
        {/* Yeah, thats a pretty stupid syntax, but it works */}
        {InputComponent}
        {error && touched && (
          <div
            className={classNames(
              type === 'number' ? 'pr-8' : 'pr-3',
              'absolute inset-y-0 right-0 flex items-center pointer-events-none'
            )}
          >
            {!['password', 'select', 'combo'].includes(type) && (
              <ExclamationCircleIcon
                aria-hidden='true'
                className='w-5 h-5 text-red-500'
              />
            )}
          </div>
        )}
      </div>
      {(error || props.additionalError) && touched && (
        <div className='mt-2 text-sm text-red-600' id={`${field.name}-error`}>
          {handleNestedYupError(error, true) || props.additionalError}
        </div>
      )}
    </div>
  )
}

export default memo(FormikInput)
