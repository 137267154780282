import ChangePasswordModal from '@components/auth/ChangePasswordModal'
import { useGetUsersUserId } from '@haesh/dice-api'
import { useUser } from '@haesh/react-use-dice'
import { Menu, Transition } from '@headlessui/react'
import { MenuAlt2Icon } from '@heroicons/react/outline'
import { useDisclosure } from '@hooks/use-disclosure'
import { classNames, formatName } from '@utils'
import React, { Fragment } from 'react'

type IHeader = {
  setSidebarOpen: (value: boolean) => void
}

const Header = ({ setSidebarOpen }: IHeader) => {
  const { userAttributes, signOut } = useUser()
  const { data: userProfile } = useGetUsersUserId(userAttributes.sub)

  const [
    changePasswordOpen,
    { close: closePasswordModal, open: openPasswordModal },
  ] = useDisclosure(false)

  return (
    <div className='relative z-10 flex flex-shrink-0 h-16 bg-white shadow'>
      <ChangePasswordModal
        onClose={closePasswordModal}
        opened={changePasswordOpen}
      />
      <button
        className='px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500 md:hidden'
        onClick={() => {
          setSidebarOpen(true)
        }}
        type='button'
      >
        <span className='sr-only'>Open sidebar</span>
        <MenuAlt2Icon aria-hidden='true' className='w-6 h-6' />
      </button>
      <div className='flex justify-between flex-1 px-4'>
        <div className='flex flex-1'>
          {/* <form className="flex w-full md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                <SearchIcon className="w-5 h-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                placeholder="Search"
                type="search"
                name="search"
              />
            </div>
          </form> */}
        </div>
        <div className='flex items-center ml-4 md:ml-6'>
          {/* <button
            type="button"
            className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            <span className="sr-only">View notifications</span>
            <BellIcon className="w-6 h-6" aria-hidden="true" />
          </button> */}
          {/* <div>{formatName(userProfile!)}</div> */}
          {/* Profile dropdown */}
          <Menu as='div' className='relative ml-3'>
            <div>
              <Menu.Button className='flex items-center max-w-xs px-3 py-2 text-sm bg-white rounded-md focus:outline-none hover:bg-slate-100'>
                {/* <button
                  type="button"
                  x-ref="toggle"
                  className="flex items-center font-medium"
                > */}
                <span className='items-center hidden font-medium sm:flex text-slate-500'>
                  Account
                  <svg
                    aria-hidden='true'
                    className='ml-2.5 text-slate-400'
                    fill='none'
                    height='6'
                    width='8'
                  >
                    <path
                      d='M7 1.5l-3 3-3-3'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                    />
                  </svg>
                </span>
                <span className='flex items-center justify-center w-8 h-8 -my-1 rounded-lg sm:hidden'>
                  <svg
                    aria-hidden='true'
                    className='text-gray-900'
                    fill='none'
                    height='20'
                    width='20'
                  >
                    <path
                      d='M3.75 4.75h12.5M3.75 9.75h12.5M3.75 14.75h12.5'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                    />
                  </svg>
                </span>
                {/* </button> */}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 w-56 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <Menu.Item>
                  {() => {
                    return (
                      <p className='py-3 px-3.5 truncate'>
                        <span className='block mb-0.5 text-xs text-gray-500'>
                          Signed in as
                        </span>
                        {(userProfile?.profile.givenName ||
                          userProfile?.profile.familyName) && (
                          <span className='block font-semibold'>
                            {formatName(userProfile)}
                          </span>
                        )}
                        {userProfile?.email && (
                          <span className='block text-xs font-medium truncate'>
                            {userProfile.email}
                          </span>
                        )}
                      </p>
                    )
                  }}
                </Menu.Item>
                {/* {userNavigation.map(item => {
                  return (
                    <Menu.Item key={item.name}>
                      {({ active }) => {
                        return (
                          <a
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                            href={item.path}
                          >
                            {item.name}
                          </a>
                        )
                      }}
                    </Menu.Item>
                  )
                })} */}
                {userProfile?.isFederated !== true && (
                  <Menu.Item>
                    {({ active }) => {
                      return (
                        <button
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'w-full text-left block px-4 py-2 text-sm text-gray-700 focus:outline-none'
                          )}
                          onClick={openPasswordModal}
                          type='button'
                        >
                          Change Password
                        </button>
                      )
                    }}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => {
                    return (
                      <button
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'w-full text-left block px-4 py-2 text-sm text-gray-700 focus:outline-none'
                        )}
                        onClick={signOut}
                        type='button'
                      >
                        Sign Out
                      </button>
                    )
                  }}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Header)
