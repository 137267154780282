/* eslint-disable import/no-unassigned-import, import/extensions */
import '@bangle.dev/core/style.css'
import '@bangle.dev/react-menu/style.css'
import './editor.css'
import {
  bangleParser,
  banglePlugins,
  bangleSpecRegistry,
} from './bangle-config'
/* eslint-enable import/no-unassigned-import, import/extensions */
import { type BangleEditor as CoreBangleEditor, Plugin } from '@bangle.dev/core'
import { markdownSerializer } from '@bangle.dev/markdown'
import { BangleEditor, useEditorState } from '@bangle.dev/react'
import {
  BlockquoteButton,
  BoldButton,
  BulletListButton,
  HeadingButton,
  ItalicButton,
  Menu,
  MenuGroup,
  OrderedListButton,
  RedoButton,
  StaticMenu,
  UndoButton,
} from '@bangle.dev/react-menu'
import { useState } from 'react'

type MarkdownInputProps = {
  disabled?: boolean
  onBlur?: () => void
  onChange: (value: string) => void
  value: string
}

const MarkdownInput = ({
  onBlur,
  onChange,
  disabled,
  value,
}: MarkdownInputProps) => {
  const [editor, setEditor] = useState<CoreBangleEditor<unknown> | undefined>()

  const serializer = markdownSerializer(bangleSpecRegistry)

  const onChangePlugin = new Plugin({
    props: {
      handleDOMEvents: {
        focusout: () => {
          if (typeof onBlur === 'function') onBlur()
          return true
        },
      },
    },
    view: () => ({
      update: (view, previousState) => {
        if (!view.state.doc.eq(previousState.doc)) {
          onChange(serializer.serialize(view.state.doc))
        }
      },
    }),
  })

  const editorState = useEditorState({
    editorProps: {
      editable: () => true,
    },
    initialValue: bangleParser.parse(value),
    plugins: () => [...banglePlugins, onChangePlugin],
    specRegistry: bangleSpecRegistry,
  })

  return (
    <div
      className={`${
        disabled ? 'opacity-80 !cursor-not-allowed' : ''
      } border rounded-md  border-slate-300`}
    >
      <div className={`${disabled ? 'pointer-events-none' : ''}`}>
        <StaticMenu
          editor={editor}
          renderMenu={() => (
            <Menu className='border-b rounded-none outline-none bg-slate-200 text-slate-900 focus:outline-none border-slate-300'>
              <MenuGroup>
                <UndoButton hint='Undo' />
                <RedoButton hint='Redo' />
              </MenuGroup>
              <MenuGroup>
                <BoldButton hint='Bold' />
                <ItalicButton hint='Italic' />
              </MenuGroup>
              <MenuGroup>
                {/* <ParagraphButton  /> */}
                <BlockquoteButton hint='Wrap in Blockquote' />
                <HeadingButton hint='Heading 2' level={2} />
                <HeadingButton hint='Heading 3' level={3} />
              </MenuGroup>
              <BulletListButton hint='Bullet List' />
              <OrderedListButton hint='Ordered List' />
              {/* <TodoListButton hint='Checkbox List' /> */}
            </Menu>
          )}
        />
        <BangleEditor
          className='bg-slate-50 bangle-editor-box'
          focusOnInit={false}
          onReady={setEditor}
          state={editorState}
        />
      </div>
    </div>
  )
}

export default MarkdownInput
