import { LoginTemplate, NewPasswordTemplate, TenantSelectionTemplate } from '.'
import ForgotPasswordTemplate from './ForgotPasswordTemplate'
import { Button } from '@chakra-ui/react'
import Spinner from '@components/common/Spinner'
import { AuthState, useUser } from '@haesh/react-use-dice'
import ErrorScreen from '@views/ErrorScreen'
import { useCallback } from 'react'

const AuthenticationWrapper = (): JSX.Element => {
  const { tenant, authState, signOut } = useUser()

  const getAuthComponent = useCallback(() => {
    if (!tenant) {
      return <TenantSelectionTemplate />
    }

    switch (authState) {
      case AuthState.SignIn:
        return <LoginTemplate />
      case AuthState.SignedOut:
        return <LoginTemplate />
      case AuthState.ForgotPassword:
        return <ForgotPasswordTemplate />
      case AuthState.CustomConfirmSignIn:
        return <NewPasswordTemplate />
      case AuthState.Loading:
        return <Spinner />
      default:
        return (
          <ErrorScreen
            ActionButton={<Button onClick={signOut}>Sign out</Button>}
            title='Something went wrong'
          />
        )
    }
  }, [authState, signOut, tenant])

  return getAuthComponent() ?? <> </>
}

export default AuthenticationWrapper
