import { sentryCreateBrowserRouter } from '../sentry'
import { NewPasswordTemplate } from '@components/auth'
import { MultiColumnLayout } from '@components/layout'
import Events from '@views/data/events/Events'
import StreamsCreateScheduled from '@views/data/streams/StreamsCreateScheduled'
import React from 'react'
import { createRoutesFromElements, redirect, Route } from 'react-router-dom'

const HomeScreen = React.lazy(async () => {
  return await import('@views/Home')
})
const UsersDashboard = React.lazy(async () => {
  return await import('@views/organization/users/UsersDashboard')
})
const UserDetail = React.lazy(async () => {
  return await import('@views/organization/users/UserDetail')
})
const UserNew = React.lazy(async () => {
  return await import('@views/organization/users/UserNew')
})
const UserEdit = React.lazy(async () => {
  return await import('@views/organization/users/UserEdit')
})
const TopicHome = React.lazy(async () => {
  return await import('@views/data/topics/TopicHome')
})
const TopicDetail = React.lazy(async () => {
  return await import('@views/data/topics/TopicDetail')
})
const TopicNew = React.lazy(async () => {
  return await import('@views/data/topics/TopicNew')
})
const ControlsNewTypeSelection = React.lazy(async () => {
  return await import('@views/data/topics/controls/ControlsNewTypeSelection')
})
const ControlsNewDetective = React.lazy(async () => {
  return await import('@views/data/topics/controls/ControlsNewDetective')
})
const ControlsNewPreventive = React.lazy(async () => {
  return await import('@views/data/topics/controls/ControlsNewPreventive')
})
const ControlsDetail = React.lazy(async () => {
  return await import('@views/data/topics/controls/ControlsDetail')
})
const ControlsEdit = React.lazy(async () => {
  return await import('@views/data/topics/controls/ControlsEdit')
})
const ControlRowDetail = React.lazy(async () => {
  return await import('@views/data/topics/controls/rows/ControlRowDetail')
})
const ControlExecutionDetail = React.lazy(async () => {
  return await import('@views/data/topics/controls/rows/ControlExecutionDetail')
})
const ExecutionDetail = React.lazy(async () => {
  return await import('@views/data/streams/executions/ExecutionsDetail')
})
const GroupingCreate = React.lazy(async () => {
  return await import('@views/data/streams/groupings/GroupingCreate')
})
const GroupingsDetail = React.lazy(async () => {
  return await import('@views/data/streams/groupings/GroupingsDetail')
})
const RowDetail = React.lazy(async () => {
  return await import('@views/data/streams/rows/RowsDetail')
})
const SystemAdminDashboard = React.lazy(async () => {
  return await import('@views/system-admin/SystemAdminDashboard')
})
const TenantDetail = React.lazy(async () => {
  return await import('@views/system-admin/TenantDetail')
})
const NewTenantForm = React.lazy(async () => {
  return await import('@views/system-admin/NewTenantForm')
})
const StreamsDashboard = React.lazy(async () => {
  return await import('@views/data/streams/StreamsDashboard')
})
const StreamsDetail = React.lazy(async () => {
  return await import('@views/data/streams/StreamsDetail')
})
const StreamsEdit = React.lazy(async () => {
  return await import('@views/data/streams/StreamsEdit')
})
const StreamsCreate = React.lazy(async () => {
  return await import('@views/data/streams/StreamsCreate')
})
const StreamsCreateApi = React.lazy(async () => {
  return await import('@views/data/streams/StreamsCreateApi')
})
const StreamsCreateWebhook = React.lazy(async () => {
  return await import('@views/data/streams/StreamsCreateWebhook')
})
const ErrorScreen = React.lazy(async () => {
  return await import('@views/ErrorScreen')
})
const TasksDashboard = React.lazy(async () => {
  return await import('@views/tasks/TasksDashboard')
})

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements([
    <Route element={<NewPasswordTemplate />} key='1' path='/forgot-password' />,
    <Route element={<MultiColumnLayout />} key='2' path='/'>
      <Route element={<HomeScreen />} path='' />
      <Route path='controls'>
        <Route path=':controlId'>
          <Route element={<ControlsDetail />} path='' />
          <Route element={<ControlsEdit />} path='edit' />
          <Route path='rows-b64'>
            <Route path=':base64RowId'>
              <Route element={<ControlRowDetail />} path='' />
              <Route path='timestamps'>
                <Route element={<ControlExecutionDetail />} path=':timestamp' />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path='data'>
        <Route path='streams'>
          <Route element={<StreamsDashboard />} path='' />
          <Route path='new'>
            <Route element={<StreamsCreate />} path='' />
            <Route element={<StreamsCreateApi />} path='api' />
            <Route element={<StreamsCreateWebhook />} path='webhook' />
            <Route element={<StreamsCreateScheduled />} path='scheduled' />
          </Route>
          <Route path=':streamId'>
            <Route element={<StreamsDetail />} path='' />
            <Route element={<StreamsEdit />} path='edit' />
            <Route path='groupings'>
              <Route element={<GroupingCreate />} path='new' />
              <Route path=':groupingId'>
                <Route element={<GroupingsDetail />} path='' />
              </Route>
            </Route>
            <Route path='rows-b64'>
              <Route path=':base64RowId'>
                <Route element={<RowDetail />} path='' />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path='executions'>
          <Route path=':executionId'>
            <Route element={<ExecutionDetail />} path='' />
          </Route>
        </Route>
        <Route path='topics'>
          <Route element={<TopicHome />} path='' />
          <Route element={<TopicNew />} path='new' />
          <Route path=':topicId'>
            <Route element={<TopicDetail />} path='' />
            <Route path='controls'>
              <Route path='new'>
                <Route element={<ControlsNewTypeSelection />} path='' />
                <Route element={<ControlsNewDetective />} path='detective' />
                <Route element={<ControlsNewPreventive />} path='preventive' />
              </Route>
              <Route
                loader={({ params }) => {
                  // redirect for compatibility with old path structure
                  // from /data/topics/:topicId/controls/:controlId
                  // to /controls/:controlId
                  throw redirect(
                    `/controls/${params['controlId']}/${params['*']}`
                  )
                }}
                path=':controlId/*'
              />
            </Route>
          </Route>
        </Route>
        <Route path='events'>
          <Route element={<Events />} path='' />
        </Route>
      </Route>
      <Route path='tasks'>
        <Route element={<TasksDashboard />} path='' />
      </Route>
      <Route path='organization'>
        <Route path='users'>
          <Route element={<UsersDashboard />} path='' />
          <Route element={<UserNew />} path='new' />
          <Route path=':userId'>
            <Route element={<UserDetail />} path='' />
            <Route element={<UserEdit />} path='edit' />
          </Route>
        </Route>
      </Route>
      <Route path='systemadmin'>
        <Route path='tenants'>
          <Route element={<SystemAdminDashboard />} path='' />
          <Route element={<NewTenantForm />} path='new' />
          <Route path=':tenantId'>
            <Route element={<TenantDetail />} path='' />
          </Route>
        </Route>
      </Route>
      <Route
        element={
          <ErrorScreen
            subtext='Check that the address is correct, or contact us if you believe there is a bug.'
            title='Page Not Found'
          />
        }
        path='*'
      />
    </Route>,
  ])
)
