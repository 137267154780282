import EventDetails from './EventDetails'
import EventDetailsConnectedEvents from './EventDetailsConnectedEvents'
import EventIcon from './EventIcon'
import EventTitle from './EventTitle'
import { Tooltip } from '@chakra-ui/react'
import { type DiceApiEvent } from '@haesh/dice-api'
import { InformationCircleIcon, MenuAlt2Icon } from '@heroicons/react/outline'
import { useRelativeTime } from '@hooks/useRelativeTime'
import { useState } from 'react'
import { useIntl } from 'react-intl'

type IEventItem = {
  event: DiceApiEvent
}

const CollapseDetails = ({
  expandConnectedEvents,
  expandDetails,
  setExpandConnectedEvents,
  setExpandDetails,
  invisible,
}: {
  expandConnectedEvents: boolean
  expandDetails: boolean
  invisible?: boolean
  setExpandConnectedEvents: (value: boolean) => void
  setExpandDetails: (value: boolean) => void
}) => (
  <div className='flex space-x-2'>
    <InformationCircleIcon
      className={`${invisible === true ? 'invisible' : ''} ${
        expandDetails === true ? 'text-primary' : 'text-gray-500'
      } select-none w-6 h-6  hover:text-primary border-2 rounded-md`}
      onClick={() => setExpandDetails(!expandDetails)}
    />
    <MenuAlt2Icon
      className={`${invisible === true ? 'invisible' : ''} ${
        expandConnectedEvents === true ? 'text-primary' : 'text-gray-500'
      } select-none w-6 h-6 hover:text-primary border-2 rounded-md`}
      onClick={() => setExpandConnectedEvents(!expandConnectedEvents)}
    />
  </div>
)

export const EventItem = ({ event }: IEventItem) => {
  const eventDate = new Date(event.timestamp)
  const relativeTime = useRelativeTime(eventDate)

  const intl = useIntl()

  const [expandDetails, setExpandDetails] = useState(false)
  const [expandConnectedEvents, setExpandConnectedEvents] = useState(false)

  return (
    <div className='flex w-full py-4'>
      <div className='flex-row mt-2 mr-2'>
        <EventIcon className='w-8 h-8 text-gray-400' event={event} />
      </div>
      <div className='flex flex-col flex-grow'>
        <div className='flex items-center justify-between'>
          <h3 className='font-medium text-md'>
            <EventTitle event={event} />
          </h3>
          <CollapseDetails
            expandConnectedEvents={expandConnectedEvents}
            expandDetails={expandDetails}
            setExpandConnectedEvents={setExpandConnectedEvents}
            setExpandDetails={setExpandDetails}
          />
        </div>
        <div className='text-sm text-gray-500'>
          <Tooltip
            label={intl.formatDate(new Date(event.timestamp), {
              dateStyle: 'long',
              timeStyle: 'medium',
            })}
          >
            {relativeTime}
          </Tooltip>
        </div>
        {expandDetails ||
          (expandConnectedEvents && (
            <div className='relative'>
              <span
                aria-hidden='true'
                className='absolute -ml-6 h-full w-0.5 bg-gray-200'
              />
              {expandDetails && <EventDetails event={event} />}
              {expandConnectedEvents && (
                <EventDetailsConnectedEvents event={event} />
              )}
            </div>
          ))}
      </div>
    </div>
  )
}
