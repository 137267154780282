import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { classNames } from '@utils'
import {
  type FormikErrors,
  type FormikHandlers,
  type FormikTouched,
} from 'formik'
import React from 'react'

type FormikObject = Record<string, boolean | number | string>

type StringKeys<T> = Extract<keyof T, string>

type IInputWithValidationErrorProps<T> = {
  autoComplete?: string
  autocorrections?: boolean
  disabled?: boolean
  errors?: FormikErrors<T>
  handleChange: FormikHandlers['handleChange']
  label: string
  onFocus?: () => void
  placeholder?: string
  required?: boolean
  touched?: FormikTouched<T>
  type: React.HTMLInputTypeAttribute
  valueKey: StringKeys<T>
  values: T
}

/**
 * @deprecated use `FormikInput instead`
 */
const InputWithValidationError = <
  T extends { [field: string]: FormikObject | boolean | number | string }
>({
  type,
  label,
  errors = {},
  touched = {},
  values,
  valueKey,
  placeholder,
  required = true,
  disabled = false,
  handleChange,
  autocorrections = true,
  autoComplete,
  onFocus,
}: IInputWithValidationErrorProps<T>) => {
  return (
    <div>
      <label
        className='block text-sm font-medium text-gray-700'
        htmlFor={valueKey}
      >
        {label}
      </label>
      <div className='relative mt-1'>
        <input
          aria-describedby={
            Boolean(
              Array.isArray(errors[valueKey]) &&
                (errors[valueKey] as T[])?.length
            ) && touched[valueKey]
              ? `${valueKey}-error`
              : undefined
          }
          aria-invalid={Boolean(
            Array.isArray(errors[valueKey]) &&
              (errors[valueKey] as T[]).length > 0 &&
              touched[valueKey]
          )}
          autoCapitalize={autocorrections ? undefined : 'off'}
          autoComplete={autocorrections ? autoComplete : 'off'}
          autoCorrect={autocorrections ? undefined : 'off'}
          className={classNames(
            disabled
              ? 'bg-gray-100 placeholder-gray-500'
              : 'placeholder-gray-400',
            'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm'
          )}
          disabled={disabled}
          id={valueKey}
          name={valueKey}
          onChange={handleChange}
          onFocus={onFocus}
          placeholder={placeholder}
          required={required}
          spellCheck={autocorrections ? undefined : 'false'}
          type={type}
          value={
            values[valueKey] as number | string | readonly string[] | undefined
          }
        />
        {errors[valueKey] && touched[valueKey] && (
          <div
            className={classNames(
              type === 'number' ? 'pr-8' : 'pr-3',
              'absolute inset-y-0 right-0 flex items-center pointer-events-none'
            )}
          >
            {/* eslint-disable-next-line react/forbid-component-props */}
            <ExclamationCircleIcon
              aria-hidden='true'
              className='w-5 h-5 text-red-500'
            />
          </div>
        )}
      </div>
      {errors[valueKey] && touched[valueKey] && (
        <p className='mt-2 text-sm text-red-600' id={`${valueKey}-error`}>
          {errors[valueKey]}
        </p>
      )}
    </div>
  )
}

export default InputWithValidationError
