import { endOfDay, isValid, parseISO, startOfDay, subDays } from 'date-fns'
import { type Dispatch, type SetStateAction, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export type Range = {
  endDate?: Date | undefined
  startDate?: Date | undefined
}

const now = new Date()
const endOfToday = endOfDay(now)

export const defaultRange = {
  endDate: endOfToday,
  // to not fetch too much data by default
  startDate: startOfDay(subDays(endOfToday, 27)),
} satisfies Range

/**
 * Try to parse a date or return undefined
 */
const toDate = (parameter: string | null): Date | undefined => {
  if (typeof parameter === 'string') {
    const date = parseISO(parameter)
    return isValid(date) ? date : undefined
  } else {
    return undefined
  }
}

export const useSearchRange = () => {
  const [searchParameters, setSearchParameters] = useSearchParams()

  const startDateParameter = searchParameters.get('startDate')
  const endDateParameter = searchParameters.get('endDate')

  const range = useMemo<Range>(
    () => ({
      endDate: toDate(endDateParameter) || defaultRange.endDate,
      startDate: toDate(startDateParameter) || defaultRange.startDate,
    }),
    [startDateParameter, endDateParameter]
  )

  const setRange = useCallback<Dispatch<SetStateAction<Range>>>(parameter => {
    const newRange =
      typeof parameter === 'function' ? parameter(range) : parameter

    if (
      newRange.startDate === defaultRange.startDate &&
      newRange.endDate === defaultRange.endDate
    ) {
      // if the range has been reset to the default range,
      // remove the parameters for more compact URLs
      searchParameters.delete('startDate')
      searchParameters.delete('endDate')
      setSearchParameters(searchParameters)
      return
    }

    if (newRange.startDate) {
      searchParameters.set('startDate', newRange.startDate.toISOString())
    } else {
      searchParameters.delete('startDate')
    }

    if (newRange.endDate) {
      searchParameters.set('endDate', newRange.endDate.toISOString())
    } else {
      searchParameters.delete('endDate')
    }

    setSearchParameters(searchParameters)
  }, [])

  return useMemo<[Range, Dispatch<SetStateAction<Range>>]>(
    () => [range, setRange],
    [range, setRange]
  )
}
