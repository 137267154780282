import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { FormikInput } from '@components/forms'
import { ChangePasswordShape } from '@haesh/dice-shapes'
import { useUser } from '@haesh/react-use-dice'
import { KeyIcon } from '@heroicons/react/outline'
import { isSecurePassword } from '@utils'
import { Form, Formik } from 'formik'

const ChangePasswordModal = ({
  opened,
  onClose,
}: {
  onClose: () => void
  opened: boolean
}) => {
  const { changePassword, userAttributes } = useUser()

  const email = userAttributes.email
  const toast = useToast()

  return (
    <Modal closeOnOverlayClick={false} isOpen={opened} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Password</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            newPassword: '',
            oldPassword: '',
            passwordConfirmation: '',
          }}
          onSubmit={async ({ oldPassword, newPassword }, { setErrors }) => {
            const trimmedPassword = newPassword.trim()
            let isSecure = true

            if (trimmedPassword === email) {
              setErrors({
                newPassword: 'Your password must not be your email!',
              })
              return
            }

            try {
              isSecure = await isSecurePassword(trimmedPassword)
            } catch {
              toast({
                duration: 5_000,
                position: 'top-right',
                status: 'error',
                title: 'Failed to check password security',
              })
            }

            if (!isSecure) {
              setErrors({
                newPassword:
                  'The entered password is insecure and was found in a data breach',
              })
              return
            }

            const result = await changePassword(
              oldPassword,
              trimmedPassword,
              true
            )
            if (typeof result === 'boolean') {
              onClose()
              return
            }

            const errorCode = 'code' in result ? result.code : undefined
            const errorMessage =
              'message' in result ? result.message : undefined

            if (errorCode === 'NotAuthorizedException') {
              setErrors({
                oldPassword: 'The entered password is incorrect',
              })
            } else if (errorCode === 'LimitExceededException') {
              toast({
                description: 'Too many attempts. Please try again later.',
                duration: 9_000,
                position: 'top-right',
                status: 'error',
                title: 'Failed to change password',
              })

              void (await new Promise(resolve => {
                setTimeout(() => resolve(true), 2_000)
              }))
            } else {
              toast({
                description: errorMessage,
                duration: 9_000,
                position: 'top-right',
                status: 'error',
                title: 'Failed to change password',
              })
            }
          }}
          validationSchema={ChangePasswordShape}
        >
          {({ isValid, dirty, isSubmitting }) => (
            <Form>
              <ModalBody className='space-y-4'>
                <FormikInput
                  description='Please enter your old password'
                  label='Old Password'
                  name='oldPassword'
                  required
                  type='password'
                />
                <FormikInput
                  description='Your new password must be at least 8 characters long.'
                  label='Neues Passwort'
                  name='newPassword'
                  required
                  type='password'
                />
                <FormikInput
                  description='Please enter your new password again.'
                  label='Confirm New Password'
                  name='passwordConfirmation'
                  required
                  type='password'
                />
              </ModalBody>
              <ModalFooter>
                <div className='flex flex-row justify-between w-full pt-6 space-x-4'>
                  <Button
                    display='block'
                    onClick={() => onClose()}
                    type='button'
                    variant='outline'
                  >
                    Cancel
                  </Button>
                  <Button
                    isDisabled={!isValid || !dirty || isSubmitting}
                    isLoading={isSubmitting}
                    leftIcon={
                      isSubmitting ? undefined : <KeyIcon className='h-5' />
                    }
                    loadingText='Changing Password'
                    type='submit'
                  >
                    Change Password
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default ChangePasswordModal
