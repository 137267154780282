import BlankCard from './BlankCard'
import { SectionHeader } from '@components/typography'
import { type SectionHeaderProps } from '@components/typography/SectionHeader'
import React from 'react'

export type CommonCardProps = {
  children?: React.ReactNode
  className?: string
  dividerHidden?: boolean
  footer?: React.ReactNode
  header?: SectionHeaderProps
  headerAccessory?: React.ReactNode
  padding?: number
}

const CommonCard = ({
  children,
  headerAccessory,
  header,
  className,
  dividerHidden,
  footer,
  padding,
}: CommonCardProps) => {
  return (
    <BlankCard className={className} padding={padding}>
      {header && (
        <div
          className={`transition-all duration-75 flex justify-between px-6 pb-1 -mx-6 border-slate-200 ${
            dividerHidden ? '-mb-4' : 'border-b mb-4'
          }`}
        >
          <SectionHeader {...header} size={header.size ?? 1} />
          {headerAccessory}
        </div>
      )}
      <div className='flex flex-col h-full'>
        <div className='flex-grow'>{children}</div>
        {footer && (
          <div className='px-6 py-2 mt-2 -mx-6 -mb-6 border-t border-slate-200 rounded-b-md bg-slate-100'>
            {footer}
          </div>
        )}
      </div>
    </BlankCard>
  )
}

export default CommonCard
