import { type DiceApiExecutionStatsEntry } from '@haesh/dice-api'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

type ExecutionStatsDoughnutProps = {
  entry: Pick<DiceApiExecutionStatsEntry, 'error' | 'fail' | 'pass'>
}

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
}

export const ExecutionStatsDoughnut = ({
  entry: { pass, error, fail },
}: ExecutionStatsDoughnutProps) => {
  const data = {
    datasets: [
      {
        backgroundColor: [
          'rgb(74, 222, 128)',
          'rgb(248, 113, 113)',
          'rgb(255, 0, 0)',
        ],
        data: [pass, fail, error],
        hoverOffset: 4,
        label: 'Control Execution Results',
        pointBackgroundColor: 'rgba(255,206,86,0.2)',
      },
    ],
    labels: ['Pass', 'Fail', 'Error'],
  }

  return <Doughnut data={data} options={options} />
}
