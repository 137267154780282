import { type CustomGeneratedClaims } from '@haesh/dice-types/auth'

/* eslint-disable @typescript-eslint/naming-convention */
export enum AuthState {
  BiometricRequired = 'biometric',
  ConfirmSignIn = 'confirmSignIn',
  ConfirmSignUp = 'confirmSignUp',
  CustomConfirmSignIn = 'customConfirmSignIn',
  ForgotPassword = 'forgotpassword',
  Loading = 'loading',
  ResetPassword = 'resettingpassword',
  SettingMFA = 'settingMFA',
  // SignOut = 'signout',
  SignIn = 'signin',
  SignUp = 'signup',
  SignedIn = 'signedin',
  SignedOut = 'signedout',
  SigningUp = 'signingup',
  TOTPSetup = 'TOTPSetup',
  VerifyContact = 'verifyContact',
  VerifyingAttributes = 'verifyingattributes',
  confirmingSignInCustomFlow = 'confirmingsignincustomflow',
  confirmingSignUpCustomFlow = 'confirmsignupcustomflow',
}

export type KnownExceptions =
  | 'AliasExistsException'
  | 'CodeMismatchException'
  | 'ExpiredCodeException'
  | 'InvalidPasswordException'
  | 'LimitExceededException'
  | 'NotAuthorizedException'
  | 'PasswordResetRequiredException'
  | 'ResourceNotFoundException'
  | 'TooManyFailedAttemptsException'
  | 'UserLambdaValidationException'
  | 'UsernameExistsException'
  | 'UserNotConfirmedException'
  | 'UserNotFoundException'

export type UserAttributes = Partial<CustomGeneratedClaims> & {
  email?: string
  email_verified?: boolean
  phone_number_verified?: boolean
  sub: string
}

/* eslint-enable @typescript-eslint/naming-convention */
