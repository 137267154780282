import AuthLayout from './AuthLayout'
import { Button } from '@chakra-ui/react'
import { InputWithValidationError } from '@components/forms'
import { useDiceApi, useUser } from '@haesh/react-use-dice'
import { classNames } from '@utils'
import { Formik, type FormikHelpers } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

const TenantSelectionTemplate = () => {
  const [searchParameters] = useSearchParams()

  const { findTenant } = useDiceApi()
  const { configureTenant } = useUser()
  const [loading, setLoading] = useState(Boolean(searchParameters.get('org')))

  useEffect(() => {
    const alias = searchParameters.get('org')
    if (alias) {
      setLoading(true)
      findTenant(alias)
        .then(response => {
          if (typeof response !== 'string') {
            const configError = configureTenant(response)
            if (!configError) {
              localStorage.setItem('tenant', JSON.stringify(response))
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  const handleFindTenant = useCallback(
    (
      values: { tenantId: string },
      helpers: FormikHelpers<{ tenantId: string }>
    ) => {
      setLoading(true)
      findTenant(values.tenantId)
        .then(response => {
          if (typeof response === 'string') {
            helpers.setFieldError('tenantId', response)
          } else {
            const configError = configureTenant(response)
            helpers.setFieldError('tenantId', configError)
            if (!configError) {
              localStorage.setItem('tenant', JSON.stringify(response))
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [configureTenant, findTenant]
  )

  return (
    <AuthLayout loading={false} title="Enter your organization's identifier">
      <Formik
        initialValues={{
          tenantId: searchParameters.get('org') ?? '',
        }}
        onSubmit={handleFindTenant}
        validationSchema={Yup.object().shape({
          tenantId: Yup.string()
            .min(3, '')
            .required('Organization Identifier is required'),
        })}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          submitForm,
        }) => {
          return (
            <form
              className={classNames(loading ? '' : '', 'space-y-6')}
              noValidate
              onSubmit={handleSubmit}
            >
              <InputWithValidationError
                autoComplete=''
                autocorrections={false}
                errors={errors}
                handleChange={handleChange}
                label='Organization Identifier'
                touched={touched}
                type='text'
                valueKey='tenantId'
                values={values}
              />
              <div>
                <Button
                  isDisabled={loading}
                  isLoading={loading}
                  loadingText='Continue...'
                  onClick={() => {
                    void submitForm()
                  }}
                  spinnerPlacement='end'
                >
                  Continue
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </AuthLayout>
  )
}

export default TenantSelectionTemplate
