import { browserLocale } from './locale'
import { useToast } from '@chakra-ui/react'
import { getEnvironment } from '@haesh/constants'
import {
  DiceApiProvider,
  IdentityProvider,
  SystemAdminProvider,
} from '@haesh/react-use-dice'
import { getStageFromWindow } from '@haesh/react-use-dice/lib/utils'
import React, { useCallback, useMemo } from 'react'
import { IntlProvider } from 'react-intl'

type IServiceProviderProps = {
  children: React.ReactNode
}

const ServiceProvider = ({ children }: IServiceProviderProps) => {
  const redirectUrl = useMemo(() => {
    return window.location.hostname === 'localhost'
      ? 'http://localhost:3000'
      : `https://${
          getEnvironment(getStageFromWindow()).diceWeb
            ? `${getEnvironment(getStageFromWindow()).diceWeb}.`
            : ''
        }${getEnvironment(getStageFromWindow()).baseDomain}`
  }, [])

  const toast = useToast()

  const handleNotification = useCallback(
    (type: string, title: string, description?: string | undefined) => {
      toast({
        description,
        duration: 9_000,
        isClosable: true,
        position: 'top-right',
        status: ['info', 'warning', 'success', 'error'].includes(type)
          ? (type as 'error' | 'info' | 'success' | 'warning')
          : 'info',
        title,
      })
    },
    [toast]
  )

  return (
    <IntlProvider defaultLocale='en' locale={browserLocale}>
      <IdentityProvider
        allowRefreshSession={false}
        notify={handleNotification}
        redirectUrl={redirectUrl}
        restoredLoginOptions={JSON.parse(
          localStorage.getItem('tenant') ?? 'null'
        )}
      >
        <DiceApiProvider notify={handleNotification}>
          <SystemAdminProvider notify={handleNotification}>
            {children}
          </SystemAdminProvider>
        </DiceApiProvider>
      </IdentityProvider>
    </IntlProvider>
  )
}

export default ServiceProvider
