import { JsonataInput } from './JsonataInput'
import { Button } from '@chakra-ui/react'
import { FormikInput } from '@components/forms'
import {
  type DiceApiHarNameValue,
  type DiceApiStreamInfoScheduledCreate,
} from '@haesh/dice-api'
import { TrashIcon } from '@heroicons/react/outline'
import { FieldArray, useFormikContext } from 'formik'
import { useState } from 'react'

const initialValuesNewHarNameValue: DiceApiHarNameValue = {
  name: '',
  value: '',
}

type HarEditorProps = {
  disabled?: boolean
  formikKey: string
  isEffect?: boolean
}

const HarEditor = ({
  disabled = false,
  formikKey,
  isEffect = false,
}: HarEditorProps) => {
  const { isSubmitting, setFieldValue, values } =
    useFormikContext<DiceApiStreamInfoScheduledCreate>()
  const [isBodyEditorOpen, setIsBodyEditorOpen] = useState(
    values.harRequest.postData !== undefined
  )
  return (
    <div className='flex flex-col space-y-3'>
      <div className='grid grid-cols-3 mb-3 space-x-3'>
        <FormikInput
          disabled={isSubmitting || disabled}
          label='Method'
          name='harRequest.method'
          options={[
            {
              label: isEffect ? 'POST' : 'GET',
              value: isEffect ? 'POST' : 'GET',
            },
          ]}
          type='select'
        />
        <FormikInput
          containerClasses='col-span-2'
          disabled={isSubmitting || disabled}
          label='URL'
          name={`${formikKey}.url`}
          placeholder='https://example.com'
          type='text'
        />
      </div>
      {disabled &&
        values.harRequest.queryString &&
        values.harRequest.queryString.length > 0 && (
          <div className='text-md'>Query Parameters</div>
        )}
      <FieldArray
        name={`${formikKey}.queryString`}
        render={arrayHelpers => (
          <div>
            {values.harRequest.queryString &&
              values.harRequest.queryString.length > 0 &&
              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
              values.harRequest.queryString.map((parameter, index) => (
                <div
                  className='flex flex-row my-2'
                  key={`queryString${index.toString()}`}
                >
                  <div className='grid flex-1 gap-2 mr-5 md:grid-cols-2'>
                    {index === 0 ? (
                      <>
                        <FormikInput
                          disabled={isSubmitting || disabled}
                          label='Name'
                          name={`${formikKey}.queryString[${index}].name`}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          type='text'
                        />
                        <FormikInput
                          disabled={isSubmitting || disabled}
                          label='Value'
                          name={`${formikKey}.queryString[${index}].value`}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          type='text'
                        />
                      </>
                    ) : (
                      <>
                        <FormikInput
                          disabled={isSubmitting || disabled}
                          name={`${formikKey}.queryString[${index}].name`}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          type='text'
                        />
                        <FormikInput
                          disabled={isSubmitting || disabled}
                          name={`${formikKey}.queryString[${index}].value`}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          type='text'
                        />
                      </>
                    )}
                  </div>
                  {!disabled && (
                    <div
                      className={` ${
                        index === 0 ? 'mt-9' : 'mt-1'
                      } grid w-8 h-10 -ml-2 cursor-pointer select-none mt place-items-center`}
                      onClick={
                        values.harRequest.queryString &&
                        values.harRequest.queryString.length > 1
                          ? () => {
                              arrayHelpers.remove(index)
                            }
                          : () => {
                              setFieldValue(`harRequest.queryString`, undefined)
                            }
                      }
                    >
                      {!isSubmitting && (
                        <TrashIcon className='block w-5 h-5 text-red-600 hover:text-red-700' />
                      )}
                    </div>
                  )}
                </div>
              ))}
            <div className='mt-5 -ml-10 text-sm text-center text-gray-500 whitespace-nowrap'>
              {disabled ? (
                <div className='text-sm text-center text-gray-500 whitespace-nowrap' />
              ) : (
                <Button
                  colorScheme='brand'
                  disabled={isSubmitting || disabled}
                  onClick={() => {
                    arrayHelpers.push(initialValuesNewHarNameValue)
                  }}
                  variant='ghost'
                >
                  add Query Parameter
                </Button>
              )}
            </div>
          </div>
        )}
      />
      {disabled &&
        values.harRequest.queryString &&
        values.harRequest.queryString.length > 0 && (
          <div className='mt-3 text-md'>Headers</div>
        )}
      <FieldArray
        name={`${formikKey}.headers`}
        render={arrayHelpers => (
          <div>
            {values.harRequest.headers &&
              values.harRequest.headers.length > 0 &&
              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
              values.harRequest.headers.map((parameter, index) => (
                <div
                  className='flex flex-row my-2'
                  key={`headers${index.toString()}`}
                >
                  <div className='grid flex-1 gap-2 mr-5 sm:grid-cols-2'>
                    {index === 0 ? (
                      <>
                        <FormikInput
                          disabled={isSubmitting || disabled}
                          label='Name'
                          name={`${formikKey}.headers[${index}].name`}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          type='text'
                        />
                        <FormikInput
                          disabled={isSubmitting || disabled}
                          label='Value'
                          name={`${formikKey}.headers[${index}].value`}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          type='text'
                        />
                      </>
                    ) : (
                      <>
                        <FormikInput
                          disabled={isSubmitting || disabled}
                          name={`${formikKey}.headers[${index}].name`}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          type='text'
                        />
                        <FormikInput
                          disabled={isSubmitting || disabled}
                          name={`${formikKey}.headers[${index}].value`}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          type='text'
                        />
                      </>
                    )}
                  </div>
                  {!disabled && (
                    <div
                      className={` ${
                        index === 0 ? 'mt-9' : 'mt-1'
                      } grid w-8 h-10 -ml-2 cursor-pointer select-none mt place-items-center`}
                      onClick={
                        values.harRequest.headers &&
                        values.harRequest.headers.length > 1
                          ? () => {
                              arrayHelpers.remove(index)
                            }
                          : () => {
                              setFieldValue(`harRequest.headers`, undefined)
                            }
                      }
                    >
                      {!isSubmitting && (
                        <TrashIcon className='block w-5 h-5 text-red-600 hover:text-red-700' />
                      )}
                    </div>
                  )}
                </div>
              ))}
            {!disabled && (
              <div className='mt-5 -ml-10 text-sm text-center text-gray-500 whitespace-nowrap'>
                <Button
                  colorScheme='brand'
                  disabled={isSubmitting || disabled}
                  onClick={() => {
                    arrayHelpers.push(initialValuesNewHarNameValue)
                  }}
                  variant='ghost'
                >
                  add Header
                </Button>
              </div>
            )}
          </div>
        )}
      />
      {isEffect && (
        <>
          {isBodyEditorOpen && (
            <JsonataInput
              disabled={isSubmitting || disabled}
              label='Body'
              name={`${formikKey}.postData.text`}
              size='multiLine'
            />
          )}

          {!disabled && (
            <div className='mt-4 -ml-10 text-center'>
              <Button
                onClick={() => {
                  if (disabled) return
                  if (isBodyEditorOpen) {
                    setFieldValue('harRequest.postData', undefined)
                  } else {
                    setFieldValue(
                      'harRequest.postData.mimeType',
                      'application/json'
                    )
                  }

                  setIsBodyEditorOpen(!isBodyEditorOpen)
                }}
                variant='ghost'
              >
                {isBodyEditorOpen ? 'Delete Body' : 'Add Body'}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default HarEditor
