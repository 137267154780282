/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth, type CognitoUser } from '@aws-amplify/auth'
import { type CurrentUserOpts } from '@aws-amplify/auth/lib-esm/types/Auth'
import { Amplify, Hub } from '@aws-amplify/core'
import { type GetLoginOptionsTenantAlias200 } from '@haesh/dice-api'
import { type DiceIdTokenPayload } from '@haesh/dice-types/auth'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { AuthState, type UserAttributes } from './types'
import { federatedSignIn, mapError } from './utils'

const useProvideUser = (
  notify: (type: string, text1: string, text2?: string) => void,
  redirectUrl: string,
  allowRefreshSession: boolean,
  restoredLoginOptions?: GetLoginOptionsTenantAlias200
) => {
  const [authState, setAuthState] = useState<AuthState>(AuthState.Loading)
  const [loadingAuth, setLoadingAuth] = useState<boolean>(false)
  const [user, setUser] = useState<
    (CognitoUser & { challengeName?: string }) | undefined
  >(undefined)
  const [tenant, setTenant] = useState<
    GetLoginOptionsTenantAlias200 | null | undefined
  >(undefined)

  // @ts-expect-error: this prop exists but not in the type
  const userAttributes: UserAttributes = user?.attributes || {}
  const idTokenPayload = useMemo<DiceIdTokenPayload | undefined>(
    () =>
      user?.getSignInUserSession()?.getIdToken().payload as
        | DiceIdTokenPayload
        | undefined,
    [user]
  )

  /**
   * Configures Amplify with the tenant Configuration
   *
   * @param data the tenant configuration object returned by the public tenant API
   * @returns an error message if something goes wrong
   */
  const configureTenant = useCallback(
    (data: GetLoginOptionsTenantAlias200) => {
      const {
        appClientId,
        identityProviders,
        userPoolId,
        userPoolRegion,
        ssoDomain,
      } = data
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!userPoolRegion || !userPoolId || !appClientId) {
        // eslint-disable-next-line no-console
        console.log(data)
        return 'Failed to find a valid configuration for this identifier'
      }

      setTenant(data)

      Amplify.configure({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Auth: {
          oauth:
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            ssoDomain && identityProviders && redirectUrl
              ? {
                  domain: ssoDomain,
                  redirectSignIn: redirectUrl,
                  redirectSignOut: redirectUrl,
                  responseType: 'code',
                  scope: [
                    'phone',
                    'email',
                    'profile',
                    'openid',
                    'aws.cognito.signin.user.admin',
                  ],
                }
              : undefined,
          region: userPoolRegion,
          userPoolId,
          userPoolWebClientId: allowRefreshSession
            ? appClientId.mobile
            : appClientId.web,
        },
      })
      return undefined
    },
    [allowRefreshSession, redirectUrl]
  )

  /**
   * Retrieve the current user's cognito IdToken, used for authentication to the api
   *
   * @returns idToken in form of a JWT string
   */
  const getIdToken = async (): Promise<string | undefined> => {
    return await Auth.currentSession()
      .then(session => session.getIdToken())
      .then(idToken => idToken.getJwtToken())
      .catch(() => undefined)
  }

  /**
   * Usernames sign in
   *
   * @param username might also be the email or phone_number, depending on the configuration
   * @param password the user's current password
   * @returns success in form of a boolean
   */
  const signIn = useCallback(
    async (username: string, password: string): Promise<boolean> => {
      setLoadingAuth(true)
      return await Auth.signIn(username, password)
        .then(response => {
          if (
            response &&
            'challengeName' in response &&
            response.challengeName === 'NEW_PASSWORD_REQUIRED'
          ) {
            setUser(response)
            setAuthState(AuthState.CustomConfirmSignIn)
          }

          return true
        })
        .catch(() => {
          notify('error', 'Sign in Failed', 'Incorrect email or password')
          return false
        })
        .finally(() => setLoadingAuth(false))
    },
    [notify]
  )

  /**
   * Signs the current user out and removes the session from devicestore
   */
  const signOut = useCallback(async (): Promise<boolean> => {
    setLoadingAuth(true)
    return await Auth.signOut()
      .then(() => {
        notify('success', 'Successful', 'You were signed out successfully')
        return true
      })
      .catch(error => {
        notify('error', 'Failed to Sign Out', mapError(error))
        return false
      })
      .finally(() => setLoadingAuth(false))
  }, [notify])

  /**
   * Confirms an email with the sent confirmation code
   *
   * @param authcode the code sent to the user either via email or sms
   * @returns success in form of a boolean
   */
  const confirmNewEmail = useCallback(
    async (authcode: string): Promise<boolean> => {
      setLoadingAuth(true)
      return await Auth.verifyCurrentUserAttributeSubmit('email', authcode)
        .then(() => {
          notify(
            'success',
            'Successful',
            'Your e-mail address is now confirmed!'
          )
          return true
        })
        .catch(error => {
          notify('error', 'Verification failed', mapError(error))
          return false
        })
        .finally(() => setLoadingAuth(false))
    },
    [notify]
  )

  /**
   * Creates a forgot password request and request a confirmation code
   *
   * @param username might also be the email or phone_number, depending on the configuration
   * @returns success in form of a boolean
   */
  const forgotPassword = useCallback(
    async (username: string): Promise<boolean> => {
      setLoadingAuth(true)

      return await Auth.forgotPassword(username)
        .then(() => {
          // notify(
          //   'success',
          //   'Successful',
          //   "A confirmation code is on it's way either via e-mail or sms"
          // )
          return true
        })
        .catch(error => {
          notify('error', 'Failed to reset password', mapError(error))
          return false
        })
        .finally(() => {
          setLoadingAuth(false)
        })
    },
    [notify]
  )

  /**
   * Confirms the users forgot Password request, requires the confirmation code
   *
   * @param username might also be the email or phone_number, depending on the configuration
   * @param code the code received either via sms or email
   * @param newPassword the new password, must comply with the userpool's password policy
   * @returns success in form of a boolean
   */
  const resetPassword = useCallback(
    async (
      username: string,
      code: string,
      newPassword: string
    ): Promise<boolean> => {
      setLoadingAuth(true)
      return await Auth.forgotPasswordSubmit(username, code, newPassword)
        .then(() => {
          notify('success', 'Successful', 'Your password has been updated')
          return true
        })
        .catch(error => {
          notify('error', 'Failed to Sign Out', mapError(error))
          return false
        })
        .finally(() => {
          setLoadingAuth(false)
        })
    },
    [notify]
  )

  /**
   * Used upon signUp if a user demands the re-send of his confirmation code
   *
   * @param username might also be the email or phone_number, depending on the configuration
   * @returns success in form of a boolean
   */
  const resendSignUp = useCallback(
    async (username: string): Promise<boolean> => {
      setLoadingAuth(true)
      return await Auth.resendSignUp(username)
        .then(() => {
          notify(
            'success',
            'Sent',
            'We have sent a new verification code to you.'
          )
          setLoadingAuth(false)
          return true
        })
        .catch(error => {
          notify(
            'error',
            'Failed',
            mapError(error, "Unfortunatly we couldn't send you a new code")
          )
          setLoadingAuth(false)
          return false
        })
    },
    [notify]
  )

  /**
   * Updates the current users's password. The user has to be signed in.
   *
   * @param oldPassword the user's old password
   * @param newPassword the new password to be set, must comply with the user pool's password policy
   * @returns success in form of a boolean
   */
  const changePassword = useCallback(
    async (
      oldPassword: string,
      newPassword: string,
      dontCatch?: boolean
    ): Promise<Error | boolean> => {
      return await Auth.changePassword(
        await Auth.currentAuthenticatedUser(),
        oldPassword,
        newPassword
      )
        .then(() => {
          notify(
            'success',
            'Erfolgreich',
            'Wir haben Ihr Passwort erfolgreich geändert.'
          )
          return true
        })
        .catch(error => {
          if (dontCatch === true) {
            return error
          }

          notify(
            'error',
            'Failed',
            mapError(error, 'Failed to update your password')
          )
          return false
        })
    },
    [notify]
  )

  /**
   * Tries to load an existing session from devicestore/localstorage and sets the user Object
   */
  const loadUser = useCallback(
    async (parameters?: CurrentUserOpts): Promise<boolean> => {
      setLoadingAuth(true)
      try {
        const userObject = await Auth.currentAuthenticatedUser(parameters)
        setUser(userObject)
        return true
      } catch {
        setUser(undefined)
        return false
      } finally {
        setLoadingAuth(false)
      }
    },
    []
  )

  /**
   * Upon first sign in, the user might be asked to set a new password.
   * This function is used to submit the new password to Cognito
   *
   * @param password the new password to be set, must comply with the pool's password policy
   * @returns success in form of a boolean
   */
  const completeNewPassword = useCallback(
    async (password: string): Promise<boolean> => {
      setLoadingAuth(true)

      return await Auth.completeNewPassword(user, password)
        .then(async () => {
          await loadUser()
          setAuthState(AuthState.SignedIn)
          return true
        })
        .catch(error => {
          notify(
            'error',
            'Failed to confirm the password',
            mapError(error, 'Failed to confirm the password')
          )
          return false
        })
        .finally(() => {
          setLoadingAuth(false)
        })
    },
    [notify, user, loadUser]
  )

  /*
   * Auth useEffect, starts a Hub that listens for auth events such as
   * - configured: A tenant has been set, and userpools etc. have been set
   * - signIn: A user has successfully signed in
   * - signOut: A user has signed out
   */
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'configured':
          void loadUser().then(sessionFound => {
            // restoring a session requires the phone's biometrics
            if (sessionFound) {
              setAuthState(
                allowRefreshSession
                  ? AuthState.BiometricRequired
                  : AuthState.SignedIn
              )
            } else setAuthState(AuthState.SignedOut)
          })
          break
        case 'signIn':
          if (
            data &&
            'challengeName' in data &&
            data.challengeName === 'NEW_PASSWORD_REQUIRED'
          ) {
            setAuthState(AuthState.CustomConfirmSignIn)
          } else {
            setAuthState(AuthState.SignedIn)
          }

          setUser(data)
          break
        case 'signOut':
          setUser(undefined)
          setAuthState(AuthState.SignedOut)
          break
        default:
          break
      }
    })
  }, [allowRefreshSession, loadUser])

  // load the tenant configuration that comes e.g. from the device store or local storage
  useEffect(() => {
    if (restoredLoginOptions) {
      configureTenant(restoredLoginOptions)
    } else {
      setAuthState(AuthState.SignedOut)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restoredLoginOptions])

  return {
    authState,
    changePassword,
    completeNewPassword,
    configureTenant,
    confirmNewEmail,
    federatedSignIn,
    forgotPassword,
    getIdToken,
    idTokenPayload,
    loadingAuth,
    loadUser,
    resendSignUp,
    resetPassword,
    setAuthState,
    setTenant,
    signIn,
    signOut,
    tenant,
    user,
    userAttributes,
  }
}

export const IdentityContext = createContext({})

export const IdentityProvider = (props: {
  allowRefreshSession: boolean
  children: React.ReactNode
  notify: (type: string, text1: string, text2?: string) => void
  redirectUrl: string
  restoredLoginOptions?: GetLoginOptionsTenantAlias200
}): JSX.Element => {
  const value = useProvideUser(
    props.notify,
    props.redirectUrl,
    props.allowRefreshSession,
    props.restoredLoginOptions
  )

  return (
    <IdentityContext.Provider value={value}>
      {props.children}
    </IdentityContext.Provider>
  )
}

export const useUser = (): ReturnType<typeof useProvideUser> =>
  useContext(IdentityContext) as ReturnType<typeof useProvideUser>
