import { usePermission } from '@haesh/react-use-dice'

export class PermissionError extends Error {
  public constructor(message?: string) {
    super(message)
    this.name = 'PermissionError'
  }
}

/**
 * Protects a component from rendering if the user does not have the specified permission.
 * Throws a PermissionError, that can be caught by an ErrorBoundary.
 *
 * @example
 * ```tsx
 * const MyProtectedComponent = () => {
 *   useRequirePermission('my-permission')
 *   return <p>My protected component</p>
 * }
 * ```
 * @param permission The permission to check for.
 */
export const useRequirePermission = (permission: string): void => {
  const hasPermission = usePermission(permission)

  if (hasPermission === false) {
    throw new PermissionError(`Missing permission ${permission}`)
  }
}
