import { ComboBox } from '../common/ComboBox'
import { type FilterProps } from '.'
import { Button } from '@chakra-ui/react'
import {
  EventCollections,
  EventsOptions,
} from '@components/data/events/eventTypeCollection'
import { PlusIcon } from '@heroicons/react/outline'
import React, { useCallback, useState } from 'react'

const MAX_FILTER = 5

export const TypeFilter: React.FC<FilterProps> = ({ onChangeFilter }) => {
  const [selectedFilter, setSelectedFilter] = useState<string[]>([''])
  const possibleEvents = EventCollections.map(collection => ({
    label: collection.label,
    renderDescription: collection.renderDescription,
    value: collection.value,
  }))

  const handleSubmitFilter = useCallback(
    (filter: string[]) => {
      const selectedCollectionsToEvents = EventCollections.filter(collection =>
        filter.includes(collection.value)
      ).flatMap(collection => collection.includedEvents)

      if (selectedCollectionsToEvents.length > 0) {
        onChangeFilter({
          bool: {
            should: EventsOptions.filter(event =>
              selectedCollectionsToEvents.includes(event.value)
            ).map(event => event.openSearch),
          },
        })
      } else {
        onChangeFilter(null)
      }
    },
    [onChangeFilter]
  )

  const handleChangeFilter = useCallback(
    (changeValue: string, changeIndex: number) => {
      if (changeIndex > selectedFilter.length) {
        return
      }

      if (
        changeValue === '' &&
        selectedFilter.length === 1 &&
        changeIndex === 0
      ) {
        setSelectedFilter([])
        handleSubmitFilter([])
        return
      }

      const newFilter = selectedFilter.map(
        (changeFilterValue, changeFilterIndex) => {
          if (changeIndex === changeFilterIndex) {
            return changeValue
          } else return changeFilterValue
        }
      )

      setSelectedFilter(newFilter)
      handleSubmitFilter(newFilter)
    },
    [handleSubmitFilter, selectedFilter]
  )

  const handleDeleteFilter = useCallback(
    (value: number) => {
      if (selectedFilter.length === 1) {
        handleChangeFilter('', 0)
      } else {
        const newFilter = selectedFilter.filter((_, index_) => index_ !== value)
        setSelectedFilter(newFilter)
        handleSubmitFilter(newFilter)
      }
    },
    [handleChangeFilter, handleSubmitFilter, selectedFilter]
  )

  const handleAddFilter = useCallback(() => {
    if (selectedFilter.length <= MAX_FILTER) {
      const newFilter = [...selectedFilter, '']
      setSelectedFilter(newFilter)
      handleSubmitFilter(newFilter)
    }
  }, [handleSubmitFilter, selectedFilter])

  return (
    <div className='flex flex-row flex-wrap items-center flex-1 gap-2 p-1 mr-2 bg-gray-100 rounded-md shadow-sm'>
      {selectedFilter.map((filter, filterIndex) => (
        <ComboBox
          className='flex bg-white rounded-md h-[30px]'
          comboBoxKey={filter}
          // eslint-disable-next-line react/no-array-index-key
          key={filterIndex}
          onChange={(changeValue: string, changeIndex: number) =>
            handleChangeFilter(changeValue, changeIndex)
          }
          onDelete={handleDeleteFilter}
          optionKey={filterIndex}
          options={possibleEvents}
          preSelectedOption={filter}
          type='filter'
        />
      ))}
      {selectedFilter.at(-1) !== '' && (
        <Button
          colorScheme={selectedFilter.length >= MAX_FILTER ? 'slate' : 'brand'}
          isDisabled={selectedFilter.length >= MAX_FILTER}
          onClick={handleAddFilter}
          size='sm'
          variant='ghost'
        >
          <PlusIcon className='w-4 h-4' />
        </Button>
      )}
    </div>
  )
}
