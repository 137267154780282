import { type AxiosRequestConfig } from 'axios'

type AxiosPatch = {
  apply: (config: AxiosRequestConfig) => AxiosRequestConfig
  match: (config: Readonly<AxiosRequestConfig>) => boolean
}

/**
 * Define here the patches you want to apply to the axios config
 */
const axiosPatches: AxiosPatch[] = [
  {
    // create control needs a longer timeout
    apply: config => ({ ...config, timeout: 20_000 }),
    match: config =>
      config.method === 'post' &&
      /^\/controls\/[A-Z0-9]+\/rules$/u.test(config.url ?? ''),
  },
]

/**
 * Override the axios config for specific requests
 */
export const patchAxiosConfig = (
  config: Readonly<AxiosRequestConfig>
): AxiosRequestConfig => {
  const patches = axiosPatches.filter(patch => patch.match(config))

  let patchedConfig = { ...config }
  for (const patch of patches) {
    patchedConfig = patch.apply(patchedConfig)
  }

  return patchedConfig
}
