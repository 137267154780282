import { ErrorBoundary } from '../../sentry/ErrorBoundary'
import Header from './multiColumnLayoutParts/Header'
import Sidebar from './multiColumnLayoutParts/Sidebar'
import { Spinner } from '@chakra-ui/react'
import { withAuthenticator } from '@hooks/withAuthWrapper'
import { Suspense, useState } from 'react'
import { Outlet } from 'react-router-dom'

const MultiColumnLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div className='flex h-screen overflow-hidden bg-gray-100'>
      <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
      <div className='flex flex-col flex-1 overflow-hidden'>
        <Header setSidebarOpen={setSidebarOpen} />
        <ErrorBoundary showDialog={false}>
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default withAuthenticator(<MultiColumnLayout />)
