// eslint-disable-next-line import/no-unassigned-import
import './index.css'
import { SentryWrapper } from './sentry'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader'
import { ChakraProvider, Spinner } from '@chakra-ui/react'
import { router } from '@router/routes'
import { ChakraTheme, ServiceProvider } from '@services/index'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

void applyPolyfills().then(() => {
  void defineCustomElements(window)
})

const container = document.querySelector('#root')

if (container === null) {
  throw new Error('Could not find root container.')
}

const root = createRoot(container)
root.render(
  <React.StrictMode>
    <SentryWrapper>
      <ChakraProvider theme={ChakraTheme}>
        <Suspense fallback={<Spinner />}>
          <ServiceProvider>
            <RouterProvider router={router} />
          </ServiceProvider>
        </Suspense>
      </ChakraProvider>
    </SentryWrapper>
  </React.StrictMode>
)
