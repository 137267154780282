export const PLAYGROUND_ACCOUNT = '533319734269'
export const CI_ACCOUNT = '692098975274'
export const PROD_ACCOUNT = '954797035496'

export const DEFAULT_REGION = 'eu-central-1'

export type Stage = string

export type BaseStage = 'dev' | 'prod' | 'staging'

export const isBaseStage = (stage: string): stage is BaseStage =>
  ['dev', 'prod', 'staging'].includes(stage)

export const isCi = (stage: string) => stage.startsWith('ci')

export type DeploymentEnvironment = {
  accountId: string
  apiSubdomain: string
  assets: string
  baseDomain: string
  branch: string
  diceWeb: string
  haeshCognito?: { custom: string } | { prefix: string }
  opensearchDomain: string
  publicCognito?: { custom: string } | { prefix: string }
}

const environmentConfig: Record<BaseStage, DeploymentEnvironment> = {
  dev: {
    accountId: PLAYGROUND_ACCOUNT,
    apiSubdomain: 'api.dice.dev',
    assets: 'static.dev',
    baseDomain: 'playground.hae.sh',
    branch: 'main',
    diceWeb: 'dice.dev',
    haeshCognito: { prefix: 'dev-dice-haesh' },
    opensearchDomain: 'search.api.dice.dev.playground.hae.sh',
  },
  prod: {
    accountId: PROD_ACCOUNT,
    apiSubdomain: 'api',
    assets: 'static',
    baseDomain: 'dice.hae.sh',
    branch: 'main',
    diceWeb: '',
    haeshCognito: { prefix: 'prod-dice-haesh' },
    opensearchDomain: 'search.api.dice.hae.sh',
  },
  staging: {
    accountId: PLAYGROUND_ACCOUNT,
    apiSubdomain: 'api.dice',
    assets: 'static.dice',
    baseDomain: 'staging.hae.sh',
    branch: 'main',
    diceWeb: 'dice',
    haeshCognito: { prefix: 'staging-dice-haesh' },
    opensearchDomain: 'search.api.dice.dev.playground.hae.sh',
  },
}

export const getEnvironment = (
  stage: Stage | string
): DeploymentEnvironment => {
  if (isBaseStage(stage)) {
    // eslint-disable-next-line security/detect-object-injection
    return environmentConfig[stage]
  }

  const customEnvironment: DeploymentEnvironment = {
    accountId: PLAYGROUND_ACCOUNT,
    apiSubdomain: `api.dice.${stage}`,
    assets: `static.${stage}`,
    baseDomain: 'playground.hae.sh',
    branch: `main`,
    diceWeb: `dice.${stage}`,
    opensearchDomain: 'search.api.dice.dev.playground.hae.sh',
  }

  const ciEnvironment: DeploymentEnvironment = {
    accountId: CI_ACCOUNT,
    apiSubdomain: `api-${stage}`,
    assets: `static-${stage}`,
    baseDomain: 'ci.hae.sh',
    branch: `main`,
    diceWeb: `dice-${stage}`,
    opensearchDomain: 'search.api.dice.dev.playground.hae.sh',
  }

  return isCi(stage) ? ciEnvironment : customEnvironment
}

export const resolveFqdn = (environment: DeploymentEnvironment) => {
  const {
    accountId,
    apiSubdomain,
    assets,
    baseDomain,
    branch,
    diceWeb,
    opensearchDomain,
  } = environment
  const result: DeploymentEnvironment = {
    accountId,
    apiSubdomain: apiSubdomain ? `${apiSubdomain}.${baseDomain}` : baseDomain,
    assets: assets ? `${assets}.${baseDomain}` : baseDomain,
    baseDomain,
    branch,
    diceWeb: diceWeb ? `${diceWeb}.${baseDomain}` : baseDomain,
    haeshCognito: environment.haeshCognito,
    opensearchDomain,
    publicCognito: environment.publicCognito,
  }
  return result
}

export const TYPEDOC_DOMAIN = `docs.${getEnvironment('dev').baseDomain}`
