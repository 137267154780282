import { PersistForm } from '@components/common/PersistForm'
import { FormikInput } from '@components/forms'
import { FormikSubmitButton } from '@components/forms/buttons'
import { StreamSchemaInput } from '@components/forms/inputs'
import CronEditor from '@components/forms/inputs/CronEditor'
import FormikUserSelect from '@components/forms/inputs/FormikUserSelect'
import HarEditor from '@components/forms/inputs/HarEditor'
import { BaseContainer } from '@components/layout'
import { SectionHeader } from '@components/typography'
import {
  type DiceApiStreamInfoScheduledCreate,
  DiceApiStreamType,
  usePostStreams,
} from '@haesh/dice-api'
import { CreateScheduledStreamShape } from '@haesh/dice-shapes'
import { useRequirePermission } from '@hooks/useRequirePermission'
import { sleep } from '@utils'
import { type FormikHelpers } from 'formik'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const initialValues = {
  cronExpression: '',
  harRequest: {
    method: 'GET',
    url: '',
  },
  isActive: true,
  streamDescription: '',
  streamTitle: '',
  streamType: DiceApiStreamType.scheduled,
} satisfies DiceApiStreamInfoScheduledCreate

const StreamsCreateScheduled = () => {
  useRequirePermission('write:streams')

  const { mutateAsync } = usePostStreams()

  const navigate = useNavigate()

  const handleCreate = useCallback(
    async (
      values: DiceApiStreamInfoScheduledCreate,
      helpers: FormikHelpers<DiceApiStreamInfoScheduledCreate>
    ) => {
      const result = await mutateAsync({ data: values })
      helpers.resetForm()
      await sleep(1_000)
      navigate(`/data/streams/${result.streamId}`)
    },
    [mutateAsync, navigate]
  )

  return (
    <BaseContainer
      hasGoBack={{
        goBackLocation: '/data/streams/new',
        label: 'Back to selection',
      }}
    >
      <SectionHeader className='mb-4' title='Add Scheduled Source' />
      <Formik
        initialValues={initialValues}
        onSubmit={handleCreate}
        validationSchema={CreateScheduledStreamShape}
      >
        <Form className='space-y-4'>
          <div className='grid grid-cols-2 gap-6'>
            <FormikInput
              label='Title'
              name='streamTitle'
              required
              type='text'
            />
            <FormikInput
              containerClasses='col-span-2'
              label='Description'
              name='streamDescription'
              type='text'
            />
            <div className='col-span-2'>
              <FormikUserSelect label='Owner' name='owner.userId' />
            </div>
            <FormikInput
              label='Primary Key'
              name='primaryKeyName'
              placeholder='/id'
              tooltip='Select a column which is unique for each record. This can be on the root level like `/primaryKey` or on a sublevel `/attributes/.../primaryKey`. (default: timestamp of a new input)'
              type='text'
            />
            <FormikInput
              label='Title Key'
              name='titleKeyName'
              placeholder='/attributes/title'
              tooltip='Select a column which includes human readable information which will be used to highlight rows within tables. (default: primary key)'
              type='text'
            />
          </div>
          <HarEditor formikKey='harRequest' />
          <CronEditor formikKey='cronExpression' />
          <StreamSchemaInput label='Schema' name='schema' />
          <FormikSubmitButton>Create Stream</FormikSubmitButton>
          <PersistForm />
        </Form>
      </Formik>
    </BaseContainer>
  )
}

export default StreamsCreateScheduled
