import AuthLayout from './AuthLayout'
import { Button } from '@chakra-ui/react'
import { FormikInput } from '@components/forms'
import { useUser } from '@haesh/react-use-dice'
import { MailOpenIcon } from '@heroicons/react/outline'
import { Formik } from 'formik'
import { useCallback, useState } from 'react'
import * as yup from 'yup'

const ForgotPasswordTemplate = () => {
  const { forgotPassword } = useUser()

  const [emailSent, setEmailSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleResetPassword = useCallback(
    ({ username }) => {
      setIsLoading(true)
      void forgotPassword(username)
        .then(success => {
          if (success) setEmailSent(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [forgotPassword]
  )

  if (emailSent) {
    return (
      <AuthLayout title='Reset Password'>
        <div className='space-y-4'>
          <div className='flex mt-8 mb-8'>
            <div className='p-4 mx-auto rounded-lg brightness-125 aspect-square bg-primary-100'>
              <MailOpenIcon className='w-12 h-12 text-primary-400 brightness-110' />
            </div>
          </div>
          <div className='text-xl font-bold text-center'>Check your mail</div>
          <div className='text-center text-slate-700'>
            We have sent you an email with instructions on how to reset your
            password.
          </div>
        </div>

        <div className='mt-32 text-sm text-slate-500'>
          Did not receive the email? Check your spam folder, or{' '}
          <span
            className='cursor-pointer text-primary-400 hover:text-primary-500'
            onClick={() => setEmailSent(false)}
          >
            try another email address
          </span>
        </div>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout title='Reset Password'>
      <div className='mb-8 text-md text-slate-700'>
        Enter the email associated with your account and we&apos; send an email
        with instructions to reset your password.
      </div>
      <Formik
        initialValues={{ username: '' }}
        onSubmit={handleResetPassword}
        validationSchema={yup.object({
          username: yup
            .string()
            .email('Must be a valid E-Mail')
            .required('E-Mail is required'),
        })}
      >
        {({ handleSubmit, isValid, dirty, submitForm }) => {
          return (
            <form className='space-y-6' noValidate onSubmit={handleSubmit}>
              <FormikInput label='E-Mail' name='username' type='text' />
              <div>
                <Button
                  isDisabled={!isValid || !dirty || isLoading || emailSent}
                  isLoading={isLoading}
                  onClick={() => {
                    void submitForm()
                  }}
                  spinnerPlacement='end'
                  width='full'
                >
                  Send instructions
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </AuthLayout>
  )
}

export default ForgotPasswordTemplate
