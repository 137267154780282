import { DiceApiAccessRole } from '@haesh/dice-api'
import { type RawIAuthorizerContext } from '@haesh/dice-types/auth'
import * as yup from 'yup'

/**
 * Shape of the context variable that is received by a lambda function from the authorizer
 */
export const TenantContextShape: yup.ObjectSchema<RawIAuthorizerContext> = yup
  .object({
    accesskey: yup.string().required(),
    accessRole: yup
      .string()
      .oneOf<DiceApiAccessRole>(Object.values(DiceApiAccessRole))
      .required(),
    businessRoles: yup.string(),
    isFederated: yup.string().equals(['true', undefined]),
    isWaiting: yup.string().equals(['true', undefined]),
    permissions: yup.string(),
    principalId: yup.string().required(),
    secretkey: yup.string().required(),
    sessiontoken: yup.string().required(),
    systemAdmin: yup.string().equals(['true', undefined]),
    tenantId: yup.string().required(),
    userId: yup.string().required(),
    userPoolId: yup.string().required(),
    userPoolRegion: yup.string().required(),
  })
  // .noUnknown(true)
  .strict()
  .defined()

const common = {
  password: yup.string().min(8, 'Must be at least 8 characters long'),
} as const

export const ChangePasswordShape = yup.object({
  newPassword: common.password
    .required('New password is a required field')
    .test((value, context) => {
      if (value === context.parent.oldPassword) {
        return context.createError({
          message: 'New password must be different from old password',
        })
      }

      return true
    }),
  oldPassword: common.password.required('Old password is a required field'),
  passwordConfirmation: yup
    .string()
    .required('Password confirmation is a required field')
    .test((value, context) => {
      if (value !== context.parent.newPassword) {
        return context.createError({
          message: 'Passwords do not match',
        })
      }

      return true
    }),
})
