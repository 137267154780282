import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormLabel,
} from '@chakra-ui/react'
import TooltipPreview from '@components/common/TooltipPreview'
import { useField } from 'formik'
import React, { useCallback, useState } from 'react'

const Editor = React.lazy(async () => await import('@monaco-editor/react'))

const exampleSchema = {
  metadata: {
    description: 'An example schema to get you started.',
    help: 'https://jsontypedef.com/docs/jtd-in-5-minutes/',
  },
  properties: {
    id: { type: 'string' },
    title: { type: 'string' },
    value: { type: 'float64' },
  },
} as const

type StreamSchemaInputProps = {
  disabled?: boolean
  label?: string
  name: string
}

export const StreamSchemaInput = ({
  label,
  disabled,
  name,
}: StreamSchemaInputProps) => {
  const [fieldInputProps, fieldMetaProps, fieldHelperProps] =
    useField<unknown>(name)

  const [value, setValue] = useState<string | undefined>(
    // the form field value is an object, but the editor expects a string
    JSON.stringify(fieldInputProps.value, undefined, 2)
  )

  const [isEditorOpen, setIsEditorOpen] = useState(value !== undefined)

  const handleEditorOpen = useCallback(() => {
    if (isEditorOpen) {
      // closing editor, deleting schema
      setValue(undefined)
      // if there was a schema initially, we need to set it to null
      fieldHelperProps.setValue(
        fieldMetaProps.initialValue === undefined ? undefined : null
      )
    } else {
      // opening editor
      fieldHelperProps.setTouched(true)
      if (fieldMetaProps.initialValue === undefined) {
        // if there was no schema initially, we set the example schema
        setValue(JSON.stringify(exampleSchema, undefined, 2))
        fieldHelperProps.setValue(exampleSchema)
      } else {
        // if there was a schema initially, we restore it
        setValue(JSON.stringify(fieldMetaProps.initialValue, undefined, 2))
        fieldHelperProps.setValue(fieldMetaProps.initialValue)
      }
    }

    setIsEditorOpen(!isEditorOpen)
  }, [isEditorOpen, fieldHelperProps, fieldMetaProps.initialValue])

  const handleChange = useCallback(
    (newValue: string | undefined) => {
      setValue(newValue)
      fieldHelperProps.setTouched(true)

      if (newValue === undefined) {
        // this doesn't really happen, but just in case
        fieldHelperProps.setValue(undefined)
        return
      }

      // the editor send us a string, but the form expects a JSON object
      try {
        const parsed = JSON.parse(newValue)
        fieldHelperProps.setValue(parsed)
      } catch {
        // we set this to a string 'invalid json' to make sure validation fails
        fieldHelperProps.setValue('invalid json')
      }
    },
    [fieldHelperProps]
  )

  return (
    <div>
      {label !== undefined && isEditorOpen && (
        <div className='flex flex-row'>
          <FormLabel>{label}</FormLabel>
          <TooltipPreview>
            Describe your data by writing a{' '}
            <a
              className='text-orange-500 underline'
              href='https://jsontypedef.com/docs/jtd-in-5-minutes/'
              rel='noreferrer'
              target='_blank'
            >
              JSON Type Definition
            </a>{' '}
            schema.
          </TooltipPreview>
        </div>
      )}
      {isEditorOpen && (
        <Editor
          className='overflow-hidden border border-gray-300 rounded-md'
          defaultLanguage='json'
          height='30vh'
          onChange={handleChange}
          options={{
            disabled,
            lineNumbers: 'off',
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            tabSize: 2,
          }}
          value={value}
        />
      )}
      {isEditorOpen && fieldMetaProps.initialValue !== undefined && (
        <Alert status='warning'>
          <AlertIcon />
          <AlertDescription>
            Modifying the schema does not automatically correct dependent
            controls or groupings. You may have to manually edit them.
          </AlertDescription>
        </Alert>
      )}
      {fieldMetaProps.error !== undefined && (
        <p className='mt-2 text-sm text-red-600'>{fieldMetaProps.error}</p>
      )}
      <div className='flex flex-row justify-end mt-4'>
        <Button onClick={handleEditorOpen} variant='ghost'>
          {isEditorOpen ? 'Delete Schema' : 'Add Schema'}
        </Button>
      </div>
    </div>
  )
}
