import { Box } from '@chakra-ui/react'
import { CommonCard, type CommonCardProps } from '@components/cards'
import EventListFiltered from '@components/data/events/EventListFiltered'
import { rangeToFilter } from '@components/filters'
import { TypeFilter } from '@components/filters/TypeFilter'
import { useCombinedFilter } from '@hooks/useCombinedFilter'
import { useSearchRange } from '@hooks/useSearchRange'
import { useEffect } from 'react'

export type EventsCardProps = CommonCardProps & {
  baseFilter?: unknown
  showEventGroupFilter?: boolean
}

export const EventsCard = ({
  baseFilter,
  showEventGroupFilter = true,
  ...commonCardProps
}: EventsCardProps) => {
  const [combinedFilter, handleChangeFilter] = useCombinedFilter({ baseFilter })

  /**
   * When the base filter changes, update the combined filter.
   */
  useEffect(() => {
    handleChangeFilter('baseFilter', baseFilter)
  }, [baseFilter, handleChangeFilter])

  const [range] = useSearchRange()

  /**
   * When the range changes, update the combined filter.
   */
  useEffect(() => {
    handleChangeFilter('timeRange', rangeToFilter(range))
  }, [range, handleChangeFilter])

  return (
    <CommonCard {...commonCardProps}>
      <Box className='space-y-4'>
        <Box className='flex flex-row justify-between'>
          {showEventGroupFilter === true && (
            <TypeFilter
              onChangeFilter={newFilter =>
                handleChangeFilter('type', newFilter)
              }
            />
          )}
        </Box>
        <EventListFiltered filter={combinedFilter} />
      </Box>
    </CommonCard>
  )
}
