import { type KnownExceptions } from './types'
import { Auth } from '@aws-amplify/auth'
import { type Stage } from '@haesh/constants'
import { type AxiosResponse } from 'axios'

// @ts-expect-error: exists in browser but not in node
const getWindow = () => window as { location: { hostname: string } } | undefined

export const federatedSignIn = async (provider?: string) => {
  if (!provider) {
    // open hosted UI
    return await Auth.federatedSignIn()
  }

  return await Auth.federatedSignIn({
    // can be the name of a SAML or OIDC provider
    customProvider: provider,
  })
}

export const mapError = (
  exception: { message: string; name: KnownExceptions },
  alternativeMessage?: string,
  lang: 'de' | 'en' = 'en'
): string => {
  const unknownText = { de: 'Da ging etwas schief', en: 'Something went wrong' }
  const errorCodes = {
    de: {
      AliasExistsException: 'Die angegebene E-Mail wird bereits verwendet.',
      CodeDeliveryFailureException:
        'Fehler beim versenden des Bestätigungscodes',
      CodeMismatchException: 'Der angegebene Code ist nicht valide.',
      ExpiredCodeException: 'Bitte loggen Sie sich erneut ein.',
      InvalidPasswordException: 'Das angegebene Passwort ist nicht gültig.',
      LimitExceededException:
        'Bitte warten Sie einige Minuten bevor Sie diese Resource anfordern.',
      NotAuthorizedException: 'Falscher Benutzername oder falsches Passwort.',
      PasswordResetRequiredException: 'Sie müssen Ihr Password zurücksetzten.',
      ResourceNotFoundException:
        'Die angeforderte Resource konnte nicht gefunden werden.',
      TooManyFailedAttemptsException:
        'Zu viele Fehlgeschlagene versuche, bitte versuchen Sie es später erneut.',
      UserLambdaValidationException:
        'Benutzer konnte nicht verifiziert werden.',
      UsernameExistsException: 'Benutzer mit dieser E-Mail existiert bereits.',
      UserNotConfirmedException: 'Benutzerkonto wurde noch nicht verifiziert.',
      UserNotFoundException: 'Benutzer konnte nicht gefunden werden.',
    },
    en: {
      AliasExistsException: 'The specified email is already in use.',
      CodeDeliveryFailureException: 'Failed to send account confirmation',
      CodeMismatchException: 'The specified code is not valid.',
      ExpiredCodeException: 'Please log in again',
      InvalidPasswordException: 'The specified password is not valid.',
      LimitExceededException:
        'Please wait a few minutes before requesting this resource',
      NotAuthorizedException: 'Wrong username or password.',
      PasswordResetRequiredException: 'You need to reset your password.',
      ResourceNotFoundException: 'The requested resource could not be found.',
      TooManyFailedAttemptsException:
        'Too many failed attempts, please try again later',
      UserLambdaValidationException: 'User could not be verified.',
      UsernameExistsException: 'User with this email already exists.',
      UserNotConfirmedException: 'User account has not been verified yet.',
      UserNotFoundException: 'User with this email was not found.',
    },
  }
  return (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    errorCodes[lang][exception.name] ??
    alternativeMessage ??
    `${unknownText[lang]}! (${exception.message})`
  )
}

const BACKEND_ENV =
  typeof process === 'undefined' ? undefined : process.env['BACKEND_ENV']

export const getStageFromWindow = (): Stage => {
  if (BACKEND_ENV) {
    return BACKEND_ENV
  }

  const hostname = getWindow()?.location?.hostname

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (hostname === undefined) {
    // we are in the mobile ID App
    return 'prod'
  }

  // we are in a web app and can access `window`

  if (hostname.includes('app.hae.sh')) {
    // we are in the production browser ID App
    return 'prod'
  }

  // dice.hae.sh is the prod env
  if (hostname.replace('www.', '').includes('dice.hae.sh')) {
    return 'prod'
  }

  if (hostname.includes('localhost')) {
    // we are in some local development environment
    return 'staging'
  }

  if (hostname.includes('.dev.hae.sh')) {
    if (hostname.startsWith('ci-')) {
      const environmentPart = hostname.split('-')[1]

      if (
        environmentPart &&
        ['dev', 'staging', 'prod'].includes(environmentPart)
      )
        return environmentPart

      return `ci-${environmentPart}`
    }

    // might be old dice-commit-{commit-hash}.dev.hae.sh
    return 'staging'
  }

  const possibleStage = getWindow()
    ?.location.hostname.replace('www.', '')
    .replace('dice.', '')
    .split('.')[0]

  return possibleStage === undefined ? 'prod' : possibleStage
}

/**
 * Checks if the given axios response has a status code that is considered an error
 * and a message in the response body.
 *
 * @param response The axios response to check
 * @returns True if the response is an error response, false otherwise
 */
export const isErrorResponse = <T, D>(
  response?: AxiosResponse<T, D>
): response is AxiosResponse<T & { message: string }, D> => {
  if (response === undefined || response === null) return false
  if (typeof response.data !== 'object' || response.data === null) return false

  return (
    response.status >= 400 &&
    'message' in response.data &&
    typeof response.data.message === 'string'
  )
}
