import FormikInput from './FormikInput'
import { Skeleton } from '@chakra-ui/react'
import UserSelectListItem from '@components/organization/users/UserSelectListItem'
import { useGetUsers } from '@haesh/dice-api'
import React from 'react'

type FormikUserSelectProps = {
  displayDisabledUsers?: boolean
  inputProps?: Partial<React.ComponentProps<typeof FormikInput>>
  label: string
  name: string
  onDeleteOwner?: () => void
  tooltip?: string
}

const FormikUserSelect = ({
  label,
  name,
  tooltip,
  displayDisabledUsers,
  inputProps,
  onDeleteOwner,
}: FormikUserSelectProps) => {
  const { data: users } = useGetUsers()

  return (
    <>
      {users === undefined ? (
        <div className='space-y-4'>
          <Skeleton h='5' w={40} />
          <Skeleton h='10' w='full' />
        </div>
      ) : (
        <FormikInput
          label={label}
          name={name}
          options={users
            .filter(
              user =>
                displayDisabledUsers === true ||
                user.cognitoUserInfo?.userEnabled === true
            )
            .map(user => ({
              label: [user.profile.givenName, user.profile.familyName]
                .filter(Boolean)
                .join(' '),
              renderDescription: () => <UserSelectListItem user={user} />,
              value: user.userId,
            }))}
          tooltip={tooltip}
          type='combo'
          {...inputProps}
          onDeleteOwner={onDeleteOwner}
        />
      )}
    </>
  )
}

export default FormikUserSelect
