export const handleNestedYupError = (
  error: Record<string, string> | string | undefined,
  showFirstErrorOnly?: boolean
): JSX.Element | string | undefined => {
  if (typeof error === 'string' && error.length === 1) return undefined
  if (!error || typeof error === 'string') return error
  return (
    <>
      {Object.values(error)
        .map((errorMessage, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={index}>{errorMessage}</p>
        ))
        .slice(0, showFirstErrorOnly ? 1 : undefined)}
    </>
  )
}
