import { useUser } from '../AuthProvider'

/**
 * Custom hook for checking if the current user has a specific permission.
 *
 * @param requiredPermission The permission to check for.
 * @returns `true` if the current user has the specified permission, `false` otherwise.
 */
export const usePermission = (requiredPermission: string): boolean => {
  const { idTokenPayload } = useUser()
  const permissions = idTokenPayload?.permissions ?? ''

  return permissions.split(',').filter(Boolean).includes(requiredPermission)
}
