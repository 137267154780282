import { type DiceApiEvent } from '@haesh/dice-api'
import ReactJson, { type ReactJsonViewProps } from 'react-json-view'

const ReactJsonProps: Partial<ReactJsonViewProps> = {
  collapsed: 0,
  displayDataTypes: false,
  displayObjectSize: false,
  enableClipboard: true,
  name: 'Event',
  quotesOnKeys: false,
}

const ControlEventsDetail = ({ event }: { event: DiceApiEvent }) => {
  return (
    <div className='my-2 text-sm'>
      {'controlInfo' in event.details && (
        <div>
          <ReactJson
            {...ReactJsonProps}
            src={event.details.controlInfo ?? []}
            theme='bright:inverted'
          />
        </div>
      )}
      {'oldControlInfo' in event.details && (
        <div>
          <strong>Old Data</strong>
          <ReactJson
            {...ReactJsonProps}
            src={event.details.oldControlInfo ?? []}
            theme='grayscale:inverted'
          />
        </div>
      )}
      {'newControlInfo' in event.details && (
        <div className='mt-2'>
          <strong>New Data</strong>
          <ReactJson
            {...ReactJsonProps}
            src={event.details.newControlInfo ?? []}
            theme='bright:inverted'
          />
        </div>
      )}
    </div>
  )
}

export default ControlEventsDetail
