import { ErrorBoundary } from './ErrorBoundary'
import { RootFallbackPage } from './RootFallbackPage'
import { getStageFromWindow } from '@haesh/react-use-dice/lib/utils'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect } from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

const environment = getStageFromWindow()
const release = import.meta.env.VITE_SENTRY_RELEASE

Sentry.init({
  debug: environment !== 'prod',
  dsn: 'https://5de4ee8f0e434e2094f1e5b54aca3922@o983116.ingest.sentry.io/6446604',
  enabled: window.location.hostname !== 'localhost',
  environment,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      blockAllMedia: environment === 'prod',
      maskAllInputs: environment === 'prod',
      maskAllText: environment === 'prod',
    }),
  ],
  release,
  tracesSampleRate: 1,
})

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export { ErrorBoundary } from './ErrorBoundary'

export const SentryWrapper = ({ children }: { children: React.ReactNode }) => {
  return <ErrorBoundary fallback={RootFallbackPage}>{children}</ErrorBoundary>
}
