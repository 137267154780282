import { GoBackHome } from '@components/common'
import React from 'react'

type IBaseContainerProps = {
  children?: React.ReactNode
  hasGoBack?: {
    goBackLocation?: string
    label?: string
    position?: 'left' | 'right'
  }
}

const BaseContainer = ({ children, hasGoBack }: IBaseContainerProps) => {
  return (
    <div className='w-full h-full overflow-y-auto'>
      <div className='px-4 mx-auto mt-8 mb-16 max-w-7xl sm:px-6 lg:px-8'>
        {hasGoBack && (
          <div className='mb-4'>
            <GoBackHome
              position={hasGoBack.position ?? 'left'}
              text={hasGoBack.label}
              to={hasGoBack.goBackLocation}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default BaseContainer
