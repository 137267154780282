import { type DiceApiEvent } from '@haesh/dice-api'
import ReactJson, { type ReactJsonViewProps } from 'react-json-view'

const ReactJsonProps: Partial<ReactJsonViewProps> = {
  collapsed: 0,
  displayDataTypes: false,
  displayObjectSize: false,
  enableClipboard: true,
  name: 'Event',
  quotesOnKeys: false,
}

const StreamEventsDetail = ({ event }: { event: DiceApiEvent }) => {
  return (
    <div className='my-2 text-sm'>
      {'before' in event.details && (
        <div>
          {'after' in event.details && <strong>Old Data</strong>}
          <ReactJson
            {...ReactJsonProps}
            src={event.details.before?.payload ?? []}
            theme='grayscale:inverted'
          />
        </div>
      )}
      {'after' in event.details && (
        <div className='mt-2'>
          {'before' in event.details && <strong>New Data</strong>}
          <ReactJson
            {...ReactJsonProps}
            src={event.details.after?.payload ?? []}
            theme='bright:inverted'
          />
        </div>
      )}
    </div>
  )
}

export default StreamEventsDetail
