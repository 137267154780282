// @ts-nocheck
/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * hae.sh DICE
 * This API allows ….
 * OpenAPI spec version: 1.0.0
 */
export type GetWorkflowsBlocks200 = {
  customBlocks?: DiceApiDiceLibraryBlock[]
  publicBlocks?: DiceApiDiceLibraryBlock[]
}

export type PostUsersUserIdResetPassword412Message =
  (typeof PostUsersUserIdResetPassword412Message)[keyof typeof PostUsersUserIdResetPassword412Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostUsersUserIdResetPassword412Message = {
  The_tenant_does_not_allow_sign_in_via_username_and_password:
    'The tenant does not allow sign in via username and password',
  Cannot_resend_signup_for_federated_user:
    'Cannot resend signup for federated user',
  User_is_not_in_a_state_that_allows_to_reset_the_password:
    'User is not in a state that allows to reset the password',
} as const

export type PostUsersUserIdResetPassword412 = {
  message: PostUsersUserIdResetPassword412Message
}

export type PostUsersUserIdResendSignup412Message =
  (typeof PostUsersUserIdResendSignup412Message)[keyof typeof PostUsersUserIdResendSignup412Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostUsersUserIdResendSignup412Message = {
  The_tenant_does_not_allow_sign_in_via_username_and_password:
    'The tenant does not allow sign in via username and password',
  Cannot_resend_signup_for_federated_user:
    'Cannot resend signup for federated user',
  User_is_not_in_a_state_that_allows_to_resend_the_sign_up_email:
    'User is not in a state that allows to resend the sign up email',
} as const

export type PostUsersUserIdResendSignup412 = {
  message: PostUsersUserIdResendSignup412Message
}

export type PostUsersUserIdEnable412Message =
  (typeof PostUsersUserIdEnable412Message)[keyof typeof PostUsersUserIdEnable412Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostUsersUserIdEnable412Message = {
  USER_ALREADY_ENABLED: 'User is already enabled',
} as const

export type PostUsersUserIdEnable412 = {
  message: PostUsersUserIdEnable412Message
}

export type GetUsersUserId200AllOf = {
  cognitoUserInfo?: DiceApiCognitoUserInfo
}

export type GetUsersUserId200 = DiceApiUserInfo & GetUsersUserId200AllOf

export type PostUsers412Message =
  (typeof PostUsers412Message)[keyof typeof PostUsers412Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostUsers412Message = {
  TENANT_NOT_ALLOWING_USERNAME_PASSWORD:
    'The tenant does not allow sign in via username and password',
  EMAIL_ALREADY_IN_USE: 'The email address is already in use by another user',
} as const

export type PostUsers412 = {
  message: PostUsers412Message
}

export type PostUsers200 = {
  userId: string
}

export type GetUsers200ItemAllOf = {
  cognitoUserInfo?: DiceApiCognitoUserInfo
}

export type GetUsers200Item = DiceApiUserInfo & GetUsers200ItemAllOf

export type GetTasks200AllOfTasksItem = DiceApiTask &
  DiceApiEntityInfo &
  DiceApiCallbackTokenInfo

export type GetTasks200AllOf = {
  tasks: GetTasks200AllOfTasksItem[]
}

export type GetTasks200 = GetTasks200AllOf & DiceApiPaginationInfo

export type GetTasksParams = {
  /**
   * A token to continue where the last query ended
   */
  nextToken?: PaginatedQueryNextTokenParameter
  /**
   * How many entries should be queried at once
   */
  limit?: PaginatedQueryLimitParameter
  reverse?: PaginatedQueryReverseParameter
  /**
   * The status of the task to filter by
   */
  status?: FilterTaskStatusParameter
}

export type GetStreamsStreamIdRowsRowIdHistory200Rows = {
  [key: string]: DiceApiRowInfo
}

export type GetStreamsStreamIdRowsRowIdHistory200 = {
  nextToken?: string
  rows: GetStreamsStreamIdRowsRowIdHistory200Rows
}

export type GetStreamsStreamIdRowsRowIdHistoryParams = {
  /**
   * A token to continue where the last query ended
   */
  nextToken?: PaginatedQueryNextTokenParameter
  /**
   * How many entries should be queried at once
   */
  limit?: PaginatedQueryLimitParameter
  reverse?: PaginatedQueryReverseParameter
}

export type GetStreamsStreamIdRowsRowId200Rows = {
  [key: string]: DiceApiRowInfo
}

export type GetStreamsStreamIdRowsRowId200 = {
  rows: GetStreamsStreamIdRowsRowId200Rows
}

export type GetStreamsStreamIdRowsRowIdParams = {
  /**
   * The timestamp of the entry
   */
  timestamp?: TimestampParameter
}

export type GetStreamsStreamIdRows400Message =
  (typeof GetStreamsStreamIdRows400Message)[keyof typeof GetStreamsStreamIdRows400Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetStreamsStreamIdRows400Message = {
  Timestamp_is_only_supported_on_single_rows:
    'Timestamp is only supported on single rows.',
} as const

export type GetStreamsStreamIdRows400 = {
  message: GetStreamsStreamIdRows400Message
}

export type GetStreamsStreamIdRows200Rows = {
  [key: string]: DiceApiRowInfo & DiceApiEntityInfo
}

export type GetStreamsStreamIdRows200 = {
  nextToken?: string
  rows: GetStreamsStreamIdRows200Rows
}

export type GetStreamsStreamIdRowsParams = {
  /**
   * A token to continue where the last query ended
   */
  nextToken?: PaginatedQueryNextTokenParameter
  /**
   * How many entries should be queried at once
   */
  limit?: PaginatedQueryLimitParameter
  reverse?: PaginatedQueryReverseParameter
  /**
   * The timestamp of the entry
   */
  timestamp?: TimestampParameter
}

export type PostStreamsStreamIdRemediationsRowId409Message =
  (typeof PostStreamsStreamIdRemediationsRowId409Message)[keyof typeof PostStreamsStreamIdRemediationsRowId409Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostStreamsStreamIdRemediationsRowId409Message = {
  A_remediation_for_this_item_already_exists:
    'A remediation for this item already exists',
} as const

export type PostStreamsStreamIdRemediationsRowId409 = {
  message: PostStreamsStreamIdRemediationsRowId409Message
}

export type ManualInvokeScheduledStream412Message =
  (typeof ManualInvokeScheduledStream412Message)[keyof typeof ManualInvokeScheduledStream412Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualInvokeScheduledStream412Message = {
  STREAM_NOT_MANUALLY_INVOKABLE:
    'This stream does not allow manual invocation.',
} as const

export type ManualInvokeScheduledStream412 = {
  message?: ManualInvokeScheduledStream412Message
}

export type PostStreamsStreamIdGroupings200 = {
  groupingId: string
}

export type CreateStreamAuthorizationHeader412Message =
  (typeof CreateStreamAuthorizationHeader412Message)[keyof typeof CreateStreamAuthorizationHeader412Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateStreamAuthorizationHeader412Message = {
  HEADER_AUTH_UNAVAILABLE: 'This stream does not allow header authorization.',
} as const

export type CreateStreamAuthorizationHeader412 = {
  message?: CreateStreamAuthorizationHeader412Message
}

export type PutStreamsStreamIdBodyLatest = { [key: string]: any }

export type PutStreamsStreamIdBody = {
  latest: PutStreamsStreamIdBodyLatest
}

export type PostStreams200AuthorizationHeadersItem =
  DiceApiHeaderAuthInfoCreate & DiceApiHeaderAuthInfoRead

export type PostStreams200Authorization = {
  /** If set, one of these HTTP Headers must be present for authentication. The secret values will not be shown again. */
  headers?: PostStreams200AuthorizationHeadersItem[]
}

export type PostStreams200 = {
  authorization: PostStreams200Authorization
  endpointUri: string
  streamId: string
  tenantId: string
}

export type GetStats400Message =
  (typeof GetStats400Message)[keyof typeof GetStats400Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetStats400Message = {
  MALFORMED_SEARCH_QUERY: 'Malformed search query',
} as const

export type GetStats400 = {
  message: GetStats400Message
}

/**
 * Execution stats grouped by day. The key is the start of the day in ISO format.
 */
export type GetStats200ByDay = { [key: string]: DiceApiExecutionStatsEntry }

export type GetStats200 = {
  /** Execution stats grouped by day. The key is the start of the day in ISO format. */
  byDay: GetStats200ByDay
  total: DiceApiExecutionStatsEntry
}

export type GetStatsParams = {
  query: SearchQueryParameter
}

export type PostSignatures412Message =
  (typeof PostSignatures412Message)[keyof typeof PostSignatures412Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostSignatures412Message = {
  NOT_ALLOWED_TO_SIGN_TASK: 'Not allowed to sign this task.',
} as const

export type PostSignatures412 = {
  message: PostSignatures412Message
}

export type PostSignatures403Message =
  (typeof PostSignatures403Message)[keyof typeof PostSignatures403Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostSignatures403Message = {
  Key_is_disabled: 'Key is disabled.',
} as const

export type PostSignatures403 = {
  message: PostSignatures403Message
}

export type PostSignatures201 = {
  data: DiceApiSignatureInfo
}

export type PostSignaturesBodyType =
  (typeof PostSignaturesBodyType)[keyof typeof PostSignaturesBodyType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostSignaturesBodyType = {
  signatures: 'signatures',
} as const

/**
 * The JSON payload to be signed together with the action.
 */
export type PostSignaturesBodyDataPayload = { [key: string]: any }

export type PostSignaturesBodyData = {
  /** The action intended by the signature */
  action: string
  /** The token to use for the task callback */
  callbackToken?: string
  /** The JSON payload to be signed together with the action. */
  payload: PostSignaturesBodyDataPayload
}

export type PostSignaturesBody = {
  data: PostSignaturesBodyData
  type: PostSignaturesBodyType
}

export type GetLoginOptionsTenantAlias200AllOf = {
  displayName: string
}

export type GetLoginOptionsTenantAlias200 = GetLoginOptionsTenantAlias200AllOf &
  DiceApiLoginOptions

export type GetFilesFileKey200 = {
  /** The url to download the file without authentication */
  downloadUrl: string
}

export type GetFilesFileKeyParams = {
  /**
   * The file name to be set for the download
   */
  filename: string
  /**
   * The name of the bucket where the file is located
   */
  bucketName: string
}

export type PostFiles200 = {
  /** The name of the bucket where the file is located */
  bucketName?: string
  /** The upload key for the file */
  uploadKey: string
  /** The upload URL for the file */
  uploadUrl: string
}

export type GetExecutionsCount200 = {
  count: number
  orMore?: boolean
}

export type GetExecutionsCountParams = {
  /**
   * The status of the execution to filter by
   */
  status?: FilterExecutionStatusParameter
  /**
   * The id of the control to filter by
   */
  controlId?: FilterControlIdParameter
}

export type GetExecutionsExecutionIdTasksTaskId200 = DiceApiTask &
  DiceApiEntityInfo &
  DiceApiCallbackTokenInfo

export type GetExecutionsExecutionIdTasks200TasksItem = DiceApiTask &
  DiceApiEntityInfo &
  DiceApiCallbackTokenInfo

export type GetExecutionsExecutionIdTasks200 = {
  nextToken?: string
  tasks: GetExecutionsExecutionIdTasks200TasksItem[]
}

export type GetExecutionsExecutionIdInputFileId200Input = { [key: string]: any }

export type GetExecutionsExecutionIdInputFileId200 = {
  input: GetExecutionsExecutionIdInputFileId200Input
}

export type GetExecutionsExecutionId200 = {
  input: DiceApiExecutionInput
  metadata: DiceApiExecutionMetadata
  results?: DiceApiExecutionResult[]
  tasks: DiceApiTask[]
}

export type GetExecutions200AllOfExecutionsItem = {
  metadata: DiceApiExecutionMetadata
}

export type GetExecutions200AllOf = {
  executions: GetExecutions200AllOfExecutionsItem[]
}

export type GetExecutions200 = GetExecutions200AllOf & DiceApiPaginationInfo

export type GetExecutionsParams = {
  /**
   * A token to continue where the last query ended
   */
  nextToken?: PaginatedQueryNextTokenParameter
  /**
   * How many entries should be queried at once
   */
  limit?: PaginatedQueryLimitParameter
  reverse?: PaginatedQueryReverseParameter
  /**
   * The status of the execution to filter by
   */
  status?: FilterExecutionStatusParameter
  /**
   * The id of the control to filter by
   */
  controlId?: FilterControlIdParameter
}

export type GetEventsEventId200 = {
  event: DiceApiEvent
}

export type GetEvents200 = {
  events: DiceApiEvent[]
  nextToken?: string
}

export type GetEventsParams = {
  query: SearchQueryParameter
  /**
   * How many entries should be queried at once
   */
  limit?: PaginatedQueryLimitParameter
  /**
   * A token to continue where the last query ended
   */
  nextToken?: PaginatedQueryNextTokenParameter
  reverse?: PaginatedQueryReverseParameter
}

export type GetControlsControlIdRulesRuleIdResults200 = {
  nextToken?: string
  remediations?: DiceApiRemediation[]
  results: DiceApiRowInfo[]
}

export type GetControlsControlIdRulesRuleIdResultsParams = {
  /**
   * A token to continue where the last query ended
   */
  nextToken?: PaginatedQueryNextTokenParameter
  /**
   * How many entries should be queried at once
   */
  limit?: PaginatedQueryLimitParameter
  reverse?: PaginatedQueryReverseParameter
  /**
   * The start date as a truncated ISO8601 string
   */
  start?: IsoDateStartParameter
  /**
   * The end date as a truncated ISO8601 string
   */
  end?: IsoDateEndParameter
  /**
   * Whether the result should include remediations
   */
  includeRemediations?: ResultsIncludeRemediationsParameter
}

export type GetControlsControlIdRulesRuleIdRemediations200AllOf = {
  remediations: DiceApiRemediation[]
}

export type GetControlsControlIdRulesRuleIdRemediations200 =
  GetControlsControlIdRulesRuleIdRemediations200AllOf & DiceApiPaginationInfo

export type GetControlsControlIdRulesRuleIdRemediationsParams = {
  /**
   * A token to continue where the last query ended
   */
  nextToken?: PaginatedQueryNextTokenParameter
  /**
   * How many entries should be queried at once
   */
  limit?: PaginatedQueryLimitParameter
  reverse?: PaginatedQueryReverseParameter
  /**
   * The start date as a truncated ISO8601 string
   */
  start?: IsoDateStartParameter
  /**
   * The end date as a truncated ISO8601 string
   */
  end?: IsoDateEndParameter
}

export type GetControlsControlIdRulesRuleId200AllOfThreeResultStreamsItem =
  DiceApiStreamInfoResultRead & DiceApiEntityInfo

export type GetControlsControlIdRulesRuleId200AllOfThree = {
  resultStreams?: GetControlsControlIdRulesRuleId200AllOfThreeResultStreamsItem[]
}

export type GetControlsControlIdRulesRuleId200 =
  GetControlsControlIdRulesRuleId200AllOf &
    GetControlsControlIdRulesRuleId200AllOfThree

export type GetControlsControlIdRulesRuleId200AllOfRule = DiceApiControlInfo &
  DiceApiEntityInfo

export type GetControlsControlIdRulesRuleId200AllOf = {
  rule: GetControlsControlIdRulesRuleId200AllOfRule
}

export type GetControlsControlIdRulesRuleIdParams = {
  /**
   * Whether the result should include the fully resolved resultstream's StreamInfo
   */
  resolveResultStreams?: boolean
}

export type PostControlsControlIdRules200AllOf = {
  controlId: string
  executionTarget: DiceApiRuleExecutionTarget
  resultStreams: string[]
  ruleId: string
  tenantId: string
}

export type PostControlsControlIdRules200 = DiceApiCreateControl &
  PostControlsControlIdRules200AllOf

export type GetControlsControlIdResults200 = {
  nextToken?: string
  remediations?: DiceApiRemediation[]
  results: DiceApiRowInfo[]
}

export type GetControlsControlIdResultsParams = {
  /**
   * A token to continue where the last query ended
   */
  nextToken?: PaginatedQueryNextTokenParameter
  /**
   * How many entries should be queried at once
   */
  limit?: PaginatedQueryLimitParameter
  reverse?: PaginatedQueryReverseParameter
  /**
   * The start date as a truncated ISO8601 string
   */
  start?: IsoDateStartParameter
  /**
   * The end date as a truncated ISO8601 string
   */
  end?: IsoDateEndParameter
  /**
   * Whether the result should include remediations
   */
  includeRemediations?: ResultsIncludeRemediationsParameter
}

export type GetControlsControlIdRemediations200AllOf = {
  remediations: DiceApiRemediation[]
}

export type GetControlsControlIdRemediations200 =
  GetControlsControlIdRemediations200AllOf & DiceApiPaginationInfo

export type GetControlsControlIdRemediationsParams = {
  /**
   * A token to continue where the last query ended
   */
  nextToken?: PaginatedQueryNextTokenParameter
  /**
   * How many entries should be queried at once
   */
  limit?: PaginatedQueryLimitParameter
  reverse?: PaginatedQueryReverseParameter
  /**
   * The start date as a truncated ISO8601 string
   */
  start?: IsoDateStartParameter
  /**
   * The end date as a truncated ISO8601 string
   */
  end?: IsoDateEndParameter
}

export type PatchControlsControlId500OriginalError = { [key: string]: any }

export type PatchControlsControlId500Message =
  (typeof PatchControlsControlId500Message)[keyof typeof PatchControlsControlId500Message]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchControlsControlId500Message = {
  Failed_to_update_topic: 'Failed to update topic',
} as const

export type PatchControlsControlId500 = {
  message: PatchControlsControlId500Message
  originalError?: PatchControlsControlId500OriginalError
}

export type PatchControlsControlIdBody = {
  description?: string
  displayName?: string
}

export type GetControlsControlId200 = {
  control: DiceApiTopicInfo
  resultStreams: DiceApiStreamInfoResultRead[]
  rules: DiceApiControlInfo[]
}

export type PostControls200 = {
  controlId: string
  description?: string
  displayName?: string
  tenantId: string
}

/**
 * The timestamp of the entry
 */
export type TimestampParameter = string

export type SearchQueryParameter = string

/**
 * Whether the result should include remediations
 */
export type ResultsIncludeRemediationsParameter = boolean

export type PaginatedQueryReverseParameter = boolean

/**
 * A token to continue where the last query ended
 */
export type PaginatedQueryNextTokenParameter = string

/**
 * How many entries should be queried at once
 */
export type PaginatedQueryLimitParameter = number

/**
 * The start date as a truncated ISO8601 string
 */
export type IsoDateStartParameter = string

/**
 * The end date as a truncated ISO8601 string
 */
export type IsoDateEndParameter = string

export type FilterTaskStatusParameter =
  (typeof FilterTaskStatusParameter)[keyof typeof FilterTaskStatusParameter]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterTaskStatusParameter = {
  open: 'open',
  closed: 'closed',
} as const

/**
 * The status of an execution
 */
export type FilterExecutionStatusParameter =
  (typeof FilterExecutionStatusParameter)[keyof typeof FilterExecutionStatusParameter]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterExecutionStatusParameter = {
  canceled: 'canceled',
  finished: 'finished',
  expired: 'expired',
  failed: 'failed',
  running: 'running',
  pending: 'pending',
} as const

/**
 * The id of the control to filter by
 */
export type FilterControlIdParameter = string

export type ValidationFailedResponseMessage =
  (typeof ValidationFailedResponseMessage)[keyof typeof ValidationFailedResponseMessage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ValidationFailedResponseMessage = {
  CONDITIONAL_CHECK_FAILED:
    'A supplied resource that is depended on does not exist',
  NOT_OBJECT: 'Payload is not type of object',
  NO_PAYLOAD: 'No payload was found',
  VALIDATION_FAILED: 'Validation failed',
  NO_EFFECT: 'The request has no effect',
  QUERY_STRINGS_MISSING: 'Required query string params are missing',
} as const

export type ValidationFailedResponseErrorsItem = {
  message?: string
  path?: string[]
}

export type ValidationFailedResponse = {
  errors?: ValidationFailedResponseErrorsItem[]
  message: ValidationFailedResponseMessage
}

export type UnauthorizedResponseMessage =
  (typeof UnauthorizedResponseMessage)[keyof typeof UnauthorizedResponseMessage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnauthorizedResponseMessage = {
  UNAUTHORIZED: 'Unauthorized',
} as const

export type UnauthorizedResponse = {
  message: UnauthorizedResponseMessage
}

export type ServerErrorResponseMessage =
  (typeof ServerErrorResponseMessage)[keyof typeof ServerErrorResponseMessage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServerErrorResponseMessage = {
  INTERNAL_ERROR: 'An unknown internal error occured',
  EXTERNAL_ERROR: 'Failed to communicate with external resource',
  DB_ITEMS_UNDEFINED: 'Failed to load from database',
  DB_MUTATION_FAILED: 'Failed to mutate database',
} as const

export type ServerErrorResponse = {
  message: ServerErrorResponseMessage
  originalError?: unknown
}

export type NotFoundResponseMessage =
  (typeof NotFoundResponseMessage)[keyof typeof NotFoundResponseMessage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotFoundResponseMessage = {
  RESOURCE_NOT_FOUND: 'The requested resource was not found',
  USER_NOT_FOUND: 'The user was not found',
  TENANT_NOT_FOUND: 'The tenant was not found',
} as const

export type NotFoundResponse = {
  message: NotFoundResponseMessage
}

export type MalformedSearchQueryResponseMessage =
  (typeof MalformedSearchQueryResponseMessage)[keyof typeof MalformedSearchQueryResponseMessage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MalformedSearchQueryResponseMessage = {
  MALFORMED_SEARCH_QUERY: 'Malformed search query',
} as const

export type MalformedSearchQueryResponse = {
  message: MalformedSearchQueryResponseMessage
}

export type ForbiddenResponseMessage =
  (typeof ForbiddenResponseMessage)[keyof typeof ForbiddenResponseMessage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ForbiddenResponseMessage = {
  USER_NOT_ALLOWED: 'User is not allowed to access this resource',
  ROUTE_NOT_FOUND: 'The requested endpoint does not exist',
  QUOTA_REACHED: 'The maximum number of entities has been reached',
} as const

export type ForbiddenResponse = {
  message: ForbiddenResponseMessage
}

export type DiceApiWorkflowDefinitionDefinitionParameters = {
  [key: string]: any
}

export type DiceApiWorkflowDefinitionDefinition = {
  [key: string]: {
    blockId: string
    parameters: DiceApiWorkflowDefinitionDefinitionParameters
    uniqueName: string
    [key: string]: any
  }
}

export interface DiceApiWorkflowDefinition {
  definition: DiceApiWorkflowDefinitionDefinition
  start: string
}

/**
 * Selection of OpenID standard claims in camelCase
 */
export interface DiceApiUserProfile {
  familyName?: string
  givenName?: string
}

export type DiceApiUserInfoAllOfEntity =
  (typeof DiceApiUserInfoAllOfEntity)[keyof typeof DiceApiUserInfoAllOfEntity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiUserInfoAllOfEntity = {
  User: 'User',
} as const

export type DiceApiUserInfoAllOf = {
  cognitoUsername: string
  entity?: DiceApiUserInfoAllOfEntity
  isFederated: boolean
  isWaiting?: boolean
  tenantId: string
  userId: string
}

export type DiceApiUserInfo = DiceApiUserInfoAllOf &
  DiceApiCommonUserInfo &
  DiceApiEntityInfo

export type DiceApiTopicInfoAllOf = {
  controlId: string
  description?: string
  displayName?: string
  tenantId: string
}

export type DiceApiTopicInfo = DiceApiTopicInfoAllOf & DiceApiEntityInfo

/**
 * JTD and Ion timestamp types for legacy compatibility. This should only be used for checking existing data. New data should use the `JtdBaseType` enum.
 */
export type DiceApiTimestampType =
  (typeof DiceApiTimestampType)[keyof typeof DiceApiTimestampType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiTimestampType = {
  timestamp: 'timestamp',
} as const

export type DiceApiTenantInfoAllOf = {
  alias: string
  billingInfo: DiceApiBillingInfo
  displayName: string
  isActive: boolean
  loginOptions: DiceApiLoginOptions
  subscriptionTier: DiceApiSubscriptionTier
  tenantId: string
}

export type DiceApiTenantInfo = DiceApiTenantInfoAllOf & DiceApiEntityInfo

export type DiceApiTaskStatus =
  (typeof DiceApiTaskStatus)[keyof typeof DiceApiTaskStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiTaskStatus = {
  open: 'open',
  closed: 'closed',
} as const

export interface DiceApiTaskSigner {
  action: string
  signatureId: string
  timestamp: string
  userId: string
}

export type DiceApiTaskSettingsType =
  (typeof DiceApiTaskSettingsType)[keyof typeof DiceApiTaskSettingsType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiTaskSettingsType = {
  TIMED_VOTE: 'TIMED_VOTE',
  X_APPROVALS_NO_REJECTS: 'X_APPROVALS_NO_REJECTS',
  X_APPROVALS: 'X_APPROVALS',
} as const

export interface DiceApiTaskSettings {
  actions: DiceApiTaskAction[]
  expiration?: string
  groupIds?: string[]
  requiredApprovals?: number
  type: DiceApiTaskSettingsType
  userIds?: string[]
  [key: string]: any
}

export type DiceApiTaskDisplayDataItemOptions =
  | DiceApiNumberFormatOptions
  | DiceApiDateTimeFormatOptions

export type DiceApiTaskDisplayDataItem = {
  displayName: string
  options?: DiceApiTaskDisplayDataItemOptions
  type: DiceApiBaseType
  value: string
}

export type DiceApiTaskDisplayData = DiceApiTaskDisplayDataItem[]

export type DiceApiTaskAction =
  (typeof DiceApiTaskAction)[keyof typeof DiceApiTaskAction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiTaskAction = {
  approve: 'approve',
  reject: 'reject',
} as const

export type DiceApiTaskType =
  (typeof DiceApiTaskType)[keyof typeof DiceApiTaskType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiTaskType = {
  SIGNATURE_REQUIRED: 'SIGNATURE_REQUIRED',
} as const

export interface DiceApiTask {
  callbackToken?: string
  controlId: string
  displayData: DiceApiTaskDisplayData
  executionId: string
  groupIds?: string[]
  /** the CID of the payload to sign */
  signaturePayload: string
  signers: DiceApiTaskSigner[]
  status?: DiceApiTaskStatus
  subtitle?: string
  taskId: string
  taskSettings: DiceApiTaskSettings
  taskToken?: string
  tenantId: string
  title: string
  type: DiceApiTaskType
  userIds?: string[]
}

export type DiceApiSubscriptionTier =
  (typeof DiceApiSubscriptionTier)[keyof typeof DiceApiSubscriptionTier]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSubscriptionTier = {
  demo: 'demo',
  poc: 'poc',
} as const

/**
 * JTD and Ion string types for legacy compatibility. This should only be used for checking existing data. New data should use the `JtdBaseType` enum.
 */
export type DiceApiStringType =
  (typeof DiceApiStringType)[keyof typeof DiceApiStringType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStringType = {
  string: 'string',
  symbol: 'symbol',
} as const

export type DiceApiStreamType =
  (typeof DiceApiStreamType)[keyof typeof DiceApiStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamType = {
  api: 'api',
  result: 'result',
  internal: 'internal',
  idtoken: 'idtoken',
  webhook: 'webhook',
  scheduled: 'scheduled',
} as const

export interface DiceApiStreamTransaction {
  streamId: string
  tenantId: string
  timestamp: string
  transactionId: string
}

/**
 * Additional information about a stream
 */
export interface DiceApiStreamMetadata {
  /** ISO date when the entity has been created */
  createdAt: string
  /** The id of the user who created the entity */
  createdBy?: string
  /** The id of the stream that creates the rows */
  sourceStreamId?: string
}

export type DiceApiStreamInfoWebhookReadStreamType =
  (typeof DiceApiStreamInfoWebhookReadStreamType)[keyof typeof DiceApiStreamInfoWebhookReadStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamInfoWebhookReadStreamType = {
  webhook: 'webhook',
} as const

/**
 * The supported authorization methods for this stream
 */
export type DiceApiStreamInfoWebhookReadAuthorization = {
  /** Information about all active HTTP Header authentication keys */
  headers?: DiceApiHeaderAuthInfoRead[]
}

export type DiceApiStreamInfoWebhookRead = DiceApiStreamInfoAllRead & {
  /** The supported authorization methods for this stream */
  authorization?: DiceApiStreamInfoWebhookReadAuthorization
  /** The JSON Pointer to a column containing unique primary keys, if those exist. Otherwise a corresponding timestamp is used. */
  primaryKeyName?: string
  streamType: DiceApiStreamInfoWebhookReadStreamType
}

export type DiceApiStreamInfoWebhookPatch = DiceApiStreamInfoAllPatch

export type DiceApiStreamInfoWebhookCreateStreamType =
  (typeof DiceApiStreamInfoWebhookCreateStreamType)[keyof typeof DiceApiStreamInfoWebhookCreateStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamInfoWebhookCreateStreamType = {
  webhook: 'webhook',
} as const

/**
 * The supported authorization methods for this stream
 */
export type DiceApiStreamInfoWebhookCreateAuthorization = {
  /** Information about all active HTTP Header authentication keys */
  headers?: DiceApiHeaderAuthInfoCreate[]
}

export type DiceApiStreamInfoWebhookCreate = DiceApiStreamInfoAllCreate & {
  /** The supported authorization methods for this stream */
  authorization?: DiceApiStreamInfoWebhookCreateAuthorization
  /** The JSON Pointer to a column containing unique primary keys, if those exist. Otherwise a corresponding timestamp is used. */
  primaryKeyName?: string
  streamType: DiceApiStreamInfoWebhookCreateStreamType
}

export type DiceApiStreamInfoScheduledReadStreamType =
  (typeof DiceApiStreamInfoScheduledReadStreamType)[keyof typeof DiceApiStreamInfoScheduledReadStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamInfoScheduledReadStreamType = {
  scheduled: 'scheduled',
} as const

export type DiceApiStreamInfoScheduledRead = DiceApiStreamInfoAllRead & {
  /** The cron expression to fetch data at a specified interval. */
  cronExpression: string
  /** The HAR request to be executed at the specified cron expression. */
  harRequest: DiceApiHarRequest
  /** Whether the cron schedule to fetch data is active or not. */
  isActive: boolean
  /** The JSON Pointer to a column containing unique primary keys, if those exist. Otherwise a corresponding timestamp is used. */
  primaryKeyName?: string
  streamType: DiceApiStreamInfoScheduledReadStreamType
}

export type DiceApiStreamInfoScheduledPatch = DiceApiStreamInfoAllPatch & {
  /** The cron expression to fetch data at a specified interval. */
  cronExpression?: string
  /** The HAR request to be executed at the specified cron expression. */
  harRequest?: DiceApiHarRequest
  /** Whether the cron schedule to fetch data is active or not. */
  isActive?: boolean
}

export type DiceApiStreamInfoScheduledCreateStreamType =
  (typeof DiceApiStreamInfoScheduledCreateStreamType)[keyof typeof DiceApiStreamInfoScheduledCreateStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamInfoScheduledCreateStreamType = {
  scheduled: 'scheduled',
} as const

export type DiceApiStreamInfoScheduledCreate = DiceApiStreamInfoAllCreate & {
  /** The cron expression to fetch data at a specified interval. */
  cronExpression: string
  /** The HAR request to be executed at the specified cron expression. */
  harRequest: DiceApiHarRequest
  /** Whether the cron schedule to fetch data is active or not. */
  isActive: boolean
  /** The JSON Pointer to a column containing unique primary keys, if those exist. Otherwise a corresponding timestamp is used. */
  primaryKeyName?: string
  streamType: DiceApiStreamInfoScheduledCreateStreamType
}

export type DiceApiStreamInfoResultReadStreamType =
  (typeof DiceApiStreamInfoResultReadStreamType)[keyof typeof DiceApiStreamInfoResultReadStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamInfoResultReadStreamType = {
  result: 'result',
} as const

export type DiceApiStreamInfoResultRead = DiceApiStreamInfoAllRead & {
  controlId: string
  ruleId: string
  streamType: DiceApiStreamInfoResultReadStreamType
}

export type DiceApiStreamInfoPatch =
  | DiceApiStreamInfoApiPatch
  | DiceApiStreamInfoScheduledPatch
  | DiceApiStreamInfoWebhookPatch

export type DiceApiStreamInfoInternalReadStreamType =
  (typeof DiceApiStreamInfoInternalReadStreamType)[keyof typeof DiceApiStreamInfoInternalReadStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamInfoInternalReadStreamType = {
  internal: 'internal',
} as const

export type DiceApiStreamInfoInternalRead = DiceApiStreamInfoAllRead & {
  streamType: DiceApiStreamInfoInternalReadStreamType
}

export type DiceApiStreamInfoRead =
  | DiceApiStreamInfoApiRead
  | DiceApiStreamInfoIdtokenRead
  | DiceApiStreamInfoInternalRead
  | DiceApiStreamInfoResultRead
  | DiceApiStreamInfoScheduledRead
  | DiceApiStreamInfoWebhookRead

export type DiceApiStreamInfoIdtokenReadStreamType =
  (typeof DiceApiStreamInfoIdtokenReadStreamType)[keyof typeof DiceApiStreamInfoIdtokenReadStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamInfoIdtokenReadStreamType = {
  idtoken: 'idtoken',
} as const

/**
 * The supported authorization methods for this stream
 */
export type DiceApiStreamInfoIdtokenReadAuthorization = {
  businessRoles?: string[]
  userIds?: string[]
}

export type DiceApiStreamInfoIdtokenRead = DiceApiStreamInfoAllRead & {
  /** The supported authorization methods for this stream */
  authorization?: DiceApiStreamInfoIdtokenReadAuthorization
  streamType: DiceApiStreamInfoIdtokenReadStreamType
}

export type DiceApiStreamInfoCreate =
  | DiceApiStreamInfoApiCreate
  | DiceApiStreamInfoScheduledCreate
  | DiceApiStreamInfoWebhookCreate

export type DiceApiStreamInfoApiReadStreamType =
  (typeof DiceApiStreamInfoApiReadStreamType)[keyof typeof DiceApiStreamInfoApiReadStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamInfoApiReadStreamType = {
  api: 'api',
} as const

/**
 * The supported authorization methods for this stream
 */
export type DiceApiStreamInfoApiReadAuthorization = {
  /** Information about all active API keys */
  apiKeys?: DiceApiHeaderAuthInfoRead[]
}

export type DiceApiStreamInfoApiRead = DiceApiStreamInfoAllRead & {
  /** The supported authorization methods for this stream */
  authorization?: DiceApiStreamInfoApiReadAuthorization
  streamType: DiceApiStreamInfoApiReadStreamType
}

export type DiceApiStreamInfoApiPatch = DiceApiStreamInfoAllPatch

export type DiceApiStreamInfoApiCreateStreamType =
  (typeof DiceApiStreamInfoApiCreateStreamType)[keyof typeof DiceApiStreamInfoApiCreateStreamType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiStreamInfoApiCreateStreamType = {
  api: 'api',
} as const

export type DiceApiStreamInfoApiCreate = DiceApiStreamInfoAllCreate & {
  streamType: DiceApiStreamInfoApiCreateStreamType
}

export type DiceApiStreamInfoAllReadTriggerWorkflowsItem = {
  controlId: string
  stateMachineArn: string
  topicId?: string
}

export type DiceApiStreamInfoAllReadTriggerRulesItem = {
  controlId: string
  resultStreamId?: string
  topicId?: string
}

export type DiceApiStreamInfoAllPatchSchema = DiceApiJsonTypeDefinition | null

export type DiceApiStreamInfoAllPatchOwner = DiceApiSimpleRecipient | null

export interface DiceApiStreamInfoAllPatch {
  owner?: DiceApiStreamInfoAllPatchOwner
  schema?: DiceApiStreamInfoAllPatchSchema
  /** The description of the stream */
  streamDescription?: string | null
  /** The title of the stream */
  streamTitle?: string
}

export interface DiceApiStreamInfoAllCreate {
  owner?: DiceApiSimpleRecipient
  /** The JSON Type Definition of the stream's rows */
  schema?: DiceApiJsonTypeDefinition
  /** The description of the stream */
  streamDescription?: string
  /** The title of the stream */
  streamTitle: string
  streamType: DiceApiStreamType
  /** The JSON Pointer to a column containing human-readable title keys. If not set, the primary key is used. */
  titleKeyName?: string
}

export type DiceApiStreamInfo = DiceApiStreamInfoRead

export type DiceApiStateMachineStatesDefinition = { [key: string]: any }

export type DiceApiStateMachineDiceDefinition = { [key: string]: any }

export interface DiceApiStateMachine {
  authorId: string
  diceDefinition: DiceApiStateMachineDiceDefinition
  stateMachineArn: string
  statesDefinition: DiceApiStateMachineStatesDefinition
  tenantId: string
}

export interface DiceApiSimpleRecipient {
  userId: string
}

export type DiceApiStreamInfoAllRead = DiceApiEntityInfo &
  DiceApiGroupedStreamInfo & {
    isActive: boolean
    metadata?: DiceApiStreamMetadata
    owner?: DiceApiSimpleRecipient
    /** The JSON Type Definition of the stream's rows */
    schema?: DiceApiJsonTypeDefinition
    /** The description of the stream */
    streamDescription?: string
    streamId: string
    /** The title of the stream */
    streamTitle: string
    streamType: DiceApiStreamType
    tenantId: string
    /** The JSON Pointer to a column containing human-readable title keys. If not set, the primary key is used. */
    titleKeyName?: string
    triggerRules?: DiceApiStreamInfoAllReadTriggerRulesItem[]
    triggerWorkflows?: DiceApiStreamInfoAllReadTriggerWorkflowsItem[]
  }

/**
 * The operator selected in simple mode
 */
export type DiceApiSimpleModeOperator =
  (typeof DiceApiSimpleModeOperator)[keyof typeof DiceApiSimpleModeOperator]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSimpleModeOperator = {
  EQUALS: '=',
  GREATER_THAN: '>',
  GREATER_THAN_EQUAL: '>=',
  LESS_THAN: '<',
  LESS_THAN_EQUAL: '<=',
  NOT_EQUAL: '!=',
} as const

/**
 * The attribute selected in simple mode
 */
export interface DiceApiSimpleModeAttribute {
  name: string
  type: DiceApiBaseType
}

export type DiceApiSignatureInfoType =
  (typeof DiceApiSignatureInfoType)[keyof typeof DiceApiSignatureInfoType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSignatureInfoType = {
  signatures: 'signatures',
} as const

/**
 * Representation of a single signature by a user
 */
export interface DiceApiSignatureInfo {
  attributes: DiceApiSignatureInfoAttributes
  id: string
  relationships: DiceApiSignatureInfoRelationships
  type: DiceApiSignatureInfoType
}

export type DiceApiSignatureInfoRelationshipsKeyDataType =
  (typeof DiceApiSignatureInfoRelationshipsKeyDataType)[keyof typeof DiceApiSignatureInfoRelationshipsKeyDataType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSignatureInfoRelationshipsKeyDataType = {
  keys: 'keys',
} as const

export type DiceApiSignatureInfoRelationshipsKeyData = {
  id: string
  type: DiceApiSignatureInfoRelationshipsKeyDataType
}

export type DiceApiSignatureInfoRelationshipsKey = {
  data: DiceApiSignatureInfoRelationshipsKeyData
}

export type DiceApiSignatureInfoRelationshipsDocument = {
  data: DiceApiSignatureInfoRelationshipsDocumentData
}

export type DiceApiSignatureInfoRelationships = {
  document: DiceApiSignatureInfoRelationshipsDocument
  key: DiceApiSignatureInfoRelationshipsKey
}

export type DiceApiSignatureInfoRelationshipsDocumentDataType =
  (typeof DiceApiSignatureInfoRelationshipsDocumentDataType)[keyof typeof DiceApiSignatureInfoRelationshipsDocumentDataType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSignatureInfoRelationshipsDocumentDataType = {
  documents: 'documents',
} as const

export type DiceApiSignatureInfoRelationshipsDocumentData = {
  id: string
  type: DiceApiSignatureInfoRelationshipsDocumentDataType
}

/**
 * The JSON payload to be signed together with the action.
 */
export type DiceApiSignatureInfoAttributesPayload = { [key: string]: any }

export type DiceApiSignatureInfoAttributes = {
  /** The action intended by the signature */
  action: string
  callbackToken?: string
  jws?: DiceApiJsonWebSignature
  /** The JSON payload to be signed together with the action. */
  payload: DiceApiSignatureInfoAttributesPayload
}

export type DiceApiSignatureDocumentType =
  (typeof DiceApiSignatureDocumentType)[keyof typeof DiceApiSignatureDocumentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSignatureDocumentType = {
  documents: 'documents',
} as const

/**
 * Representation of data to be signed with an action
 */
export interface DiceApiSignatureDocument {
  attributes: DiceApiSignatureDocumentAttributes
  id: string
  relationships: DiceApiSignatureDocumentRelationships
  type: DiceApiSignatureDocumentType
}

export type DiceApiSignatureDocumentRelationshipsSignaturesDataItemType =
  (typeof DiceApiSignatureDocumentRelationshipsSignaturesDataItemType)[keyof typeof DiceApiSignatureDocumentRelationshipsSignaturesDataItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSignatureDocumentRelationshipsSignaturesDataItemType = {
  signatures: 'signatures',
} as const

export type DiceApiSignatureDocumentRelationshipsSignaturesDataItem = {
  id: string
  type: DiceApiSignatureDocumentRelationshipsSignaturesDataItemType
}

export type DiceApiSignatureDocumentRelationshipsSignatures = {
  data: DiceApiSignatureDocumentRelationshipsSignaturesDataItem[]
}

export type DiceApiSignatureDocumentRelationships = {
  signatures: DiceApiSignatureDocumentRelationshipsSignatures
}

/**
 * The JSON payload to be signed together with the action.
 */
export type DiceApiSignatureDocumentAttributesPayload = { [key: string]: any }

export type DiceApiSignatureDocumentAttributes = {
  /** The action intended by the signature */
  action: string
  /** The byte representation of payload and action, used for signing */
  bytes: string
  /** The JSON payload to be signed together with the action. */
  payload: DiceApiSignatureDocumentAttributesPayload
}

export type DiceApiSignatureBlockOutputOutcome =
  (typeof DiceApiSignatureBlockOutputOutcome)[keyof typeof DiceApiSignatureBlockOutputOutcome]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSignatureBlockOutputOutcome = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
} as const

export interface DiceApiSignatureBlockOutput {
  approvers: DiceApiTaskSigner[]
  outcome: DiceApiSignatureBlockOutputOutcome
  rejectors: DiceApiTaskSigner[]
}

export type DiceApiSignatureBlockInputType =
  (typeof DiceApiSignatureBlockInputType)[keyof typeof DiceApiSignatureBlockInputType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSignatureBlockInputType = {
  X_APPROVALS: 'X_APPROVALS',
  X_APPROVALS_NO_REJECTS: 'X_APPROVALS_NO_REJECTS',
  TIMED_VOTE: 'TIMED_VOTE',
} as const

export interface DiceApiSignatureBlockInput {
  actions: DiceApiTaskAction[]
  displayData: DiceApiTaskDisplayData
  expiration?: unknown
  groupIds: string[]
  requiredApprovals: number
  subtitle?: string
  title: string
  type: DiceApiSignatureBlockInputType
  userIds: string[]
}

export type DiceApiSignatureBlockAllOfParameters = {
  inputs: DiceApiSignatureBlockInput
  outputFormat?: DiceApiSignatureBlockOutput
}

export type DiceApiSignatureBlockAllOfBlockId =
  (typeof DiceApiSignatureBlockAllOfBlockId)[keyof typeof DiceApiSignatureBlockAllOfBlockId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSignatureBlockAllOfBlockId = {
  SIGNATURE_REQUIRED: 'SIGNATURE_REQUIRED',
} as const

export type DiceApiSignatureBlockAllOf = {
  blockId: DiceApiSignatureBlockAllOfBlockId
  parameters: DiceApiSignatureBlockAllOfParameters
}

/**
 * Shape of a Signature Block
 */
export type DiceApiSignatureBlock = DiceApiBlockImplementation &
  DiceApiSignatureBlockAllOf

/**
 * The operator used to chain condition
 */
export type DiceApiSeverity =
  (typeof DiceApiSeverity)[keyof typeof DiceApiSeverity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiSeverity = {
  none: 'none',
  low: 'low',
  medium: 'medium',
  high: 'high',
  critical: 'critical',
} as const

export type DiceApiRuleExecutionTargetType =
  (typeof DiceApiRuleExecutionTargetType)[keyof typeof DiceApiRuleExecutionTargetType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiRuleExecutionTargetType = {
  lambda: 'lambda',
  stepfunction: 'stepfunction',
} as const

export interface DiceApiRuleExecutionTarget {
  arn: string
  type: DiceApiRuleExecutionTargetType
}

export type DiceApiRowInfoTriggerWorkflowsItem = {
  controlId: string
  stateMachineArn: string
  topicId?: string
}

export type DiceApiRowInfoTriggerRulesItem = {
  controlId: string
  resultStreamId?: string
  topicId?: string
}

export interface DiceApiRowInfo {
  bytes?: string
  cid: string
  commitId?: string
  payload?: unknown
  resultMetadata?: DiceApiResultMetadata
  rowId: string
  streamId: string
  tenantId: string
  timestamp: string
  transactionId?: string
  triggerRules?: DiceApiRowInfoTriggerRulesItem[]
  triggerWorkflows?: DiceApiRowInfoTriggerWorkflowsItem[]
  type?: string
}

export type DiceApiRiskCategory =
  (typeof DiceApiRiskCategory)[keyof typeof DiceApiRiskCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiRiskCategory = {
  low: 'low',
  medium: 'medium',
  high: 'high',
} as const

export type DiceApiResultMetadataInputRowIdsItem = {
  rowId: string
  streamId: string
  timestamp?: string
}

export type DiceApiResultMetadataError = { [key: string]: any }

export interface DiceApiResultMetadata {
  controlId: string
  error?: DiceApiResultMetadataError
  inputRowIds: DiceApiResultMetadataInputRowIdsItem[]
  pass: boolean
  reason?: string
  ruleId: string
  severity?: DiceApiSeverity
}

export type DiceApiRemediationAllOfEntity =
  (typeof DiceApiRemediationAllOfEntity)[keyof typeof DiceApiRemediationAllOfEntity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiRemediationAllOfEntity = {
  Remediation: 'Remediation',
} as const

export type DiceApiRemediationAllOf = {
  authorId: string
  entity?: DiceApiRemediationAllOfEntity
  rowId: string
  streamId: string
  tenantId: string
  timestamp: string
}

export type DiceApiRemediation = DiceApiRemediationAllOf &
  DiceApiCommonRemediation &
  DiceApiEntityInfo

export type DiceApiReindexJobErrorsItem = { [key: string]: any }

export interface DiceApiReindexJob {
  createdAt: string
  errors?: DiceApiReindexJobErrorsItem[]
  groupingId: string
  jobId: string
}

export interface DiceApiPostalAddress {
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  contactName?: string
  countryCode: string
  locality: string
  organizationName: string
  postalCode: string
  region: string
}

export interface DiceApiPaymentMethod {
  id: string
}

export type DiceApiPatchUserInfoOneOfTwo = {
  businessRoles?: string[]
  profile?: DiceApiUserProfile
}

export type DiceApiPatchUserInfoOneOf = {
  accessRole?: DiceApiAccessRole
}

export type DiceApiPatchUserInfo =
  | DiceApiPatchUserInfoOneOf
  | DiceApiPatchUserInfoOneOfTwo

export interface DiceApiPatchControlMetadata {
  addressedRisk?: string | null
  controlEntity?: string | null
  controlFrequency?: DiceApiControlFrequency
  controlKeyControls?: boolean
  controlObjective?: string | null
  description?: string | null
  displayName?: string
  owner?: DiceApiSimpleRecipient
  processCycle?: string | null
  riskCategory?: DiceApiRiskCategory
  shortDescription?: string
}

export type DiceApiPatchControlLogicControlDefinitionOneOfTwo = {
  workflowDefinition: DiceApiWorkflowDefinition
}

export type DiceApiPatchControlLogicControlDefinitionOneOf = {
  simpleDefinition: DiceApiBaseJsonataCondition[]
}

export type DiceApiPatchControlLogicControlDefinition =
  | DiceApiPatchControlLogicControlDefinitionOneOf
  | DiceApiPatchControlLogicControlDefinitionOneOfTwo

export interface DiceApiPatchControlLogic {
  controlDefinition?: DiceApiPatchControlLogicControlDefinition
}

export type DiceApiPatchControlInputTrigger = {
  streamId: string
}

export interface DiceApiPatchControlInput {
  joins?: DiceApiJoinCondition[] | null
  trigger?: DiceApiPatchControlInputTrigger
}

export type DiceApiPatchControl = DiceApiPatchControlMetadata &
  DiceApiPatchControlInput &
  DiceApiPatchControlLogic

export interface DiceApiPaginationInfo {
  nextToken?: string
}

/**
 * JTD and Ion number types for legacy compatibility. This should only be used for checking existing data. New data should use the `JtdBaseType` enum.
 */
export type DiceApiNumberType =
  (typeof DiceApiNumberType)[keyof typeof DiceApiNumberType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiNumberType = {
  float32: 'float32',
  float64: 'float64',
  int8: 'int8',
  int16: 'int16',
  int32: 'int32',
  uint8: 'uint8',
  uint16: 'uint16',
  uint32: 'uint32',
  int: 'int',
  number: 'number',
  float: 'float',
  decimal: 'decimal',
} as const

/**
 * Formatting options as [`Intl.NumberFormat`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat) options
 */
export interface DiceApiNumberFormatOptions {
  [key: string]: any
}

export type DiceApiLoginOptionsAppClientId = {
  mobile: string
  web: string
}

export interface DiceApiLoginOptions {
  appClientId: DiceApiLoginOptionsAppClientId
  identityProviders: DiceApiIdentityProvider[]
  ssoDomain?: string
  usernamePasswordIsEnabled: boolean
  userPoolId: string
  userPoolRegion: string
}

export type DiceApiKeyType =
  (typeof DiceApiKeyType)[keyof typeof DiceApiKeyType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiKeyType = {
  keys: 'keys',
} as const

/**
 * A key or method used to sign data
 */
export interface DiceApiKey {
  attributes: DiceApiKeyAttributes
  id: string
  tenantId?: string
  type: DiceApiKeyType
  userId?: string
}

/**
 * The basic scalar types supported by DICE. Customers may use the various number types, but they will be internally converted to `float64`.
 */
export type DiceApiJtdBaseType =
  (typeof DiceApiJtdBaseType)[keyof typeof DiceApiJtdBaseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiJtdBaseType = {
  boolean: 'boolean',
  string: 'string',
  timestamp: 'timestamp',
  float32: 'float32',
  float64: 'float64',
  int8: 'int8',
  int16: 'int16',
  int32: 'int32',
  uint8: 'uint8',
  uint16: 'uint16',
  uint32: 'uint32',
} as const

/**
 * JSON Web Signature (JWS), see RFC 7515
 */
export interface DiceApiJsonWebSignature {
  [key: string]: any
}

/**
 * JSON Web Key (JWK), see RFC 7517
 */
export interface DiceApiJsonWebKey {
  [key: string]: any
}

export type DiceApiKeyAttributes = {
  /** A description of the key */
  description?: string
  /** Whether the key is disabled */
  disabled: boolean
  jwk: DiceApiJsonWebKey
}

/**
 * JSON Type Definition (JTD), see RFC 8927
 */
export interface DiceApiJsonTypeDefinition {
  [key: string]: any
}

export interface DiceApiJsonataConditionBlockOutput {
  default: boolean
  outcome: string
}

export interface DiceApiJsonataConditionBlockInput {
  conditions: DiceApiBaseJsonataCondition[]
  default?: string
}

export type DiceApiJsonataConditionBlockAllOfParameters = {
  inputs: DiceApiJsonataConditionBlockInput
  outputFormat?: DiceApiJsonataConditionBlockOutput
}

export type DiceApiJsonataConditionBlockAllOfBlockId =
  (typeof DiceApiJsonataConditionBlockAllOfBlockId)[keyof typeof DiceApiJsonataConditionBlockAllOfBlockId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiJsonataConditionBlockAllOfBlockId = {
  JSONATA_CONDITION: 'JSONATA_CONDITION',
} as const

export type DiceApiJsonataConditionBlockAllOf = {
  blockId: DiceApiJsonataConditionBlockAllOfBlockId
  parameters: DiceApiJsonataConditionBlockAllOfParameters
}

/**
 * Shape of a Condition Block
 */
export type DiceApiJsonataConditionBlock = DiceApiBlockImplementation &
  DiceApiJsonataConditionBlockAllOf

export type DiceApiJoinConditionSimpleClause = {
  attribute: DiceApiSimpleModeAttribute
}

export interface DiceApiJoinCondition {
  expression: string
  isCustomExpression?: boolean
  simpleClause?: DiceApiJoinConditionSimpleClause
  streamId: string
}

/**
 * The basic scalar types supported by DICE. [deprecated, port to JtdBaseType]
 * @deprecated
 */
export type DiceApiIonBaseType =
  (typeof DiceApiIonBaseType)[keyof typeof DiceApiIonBaseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiIonBaseType = {
  decimal: 'decimal',
  string: 'string',
  bool: 'bool',
  timestamp: 'timestamp',
} as const

export interface DiceApiIdentityProvider {
  displayName: string
  isEnabled: boolean
  providerName: string
}

export type DiceApiHeaderAuthInfoReadAllOf = {
  /** The id of the auth method */
  authId: string
  /** Creation date of the auth method */
  createdAt: string
  /** The id of the user who created the auth method */
  createdBy: string
  /** Description of the auth method */
  description?: string
  /** Expiration date of the auth method */
  expiresAt?: string
  /** HTTP Header Field Name */
  name: string
}

export type DiceApiHeaderAuthInfoRead = DiceApiHeaderAuthInfoReadAllOf

export interface DiceApiHeaderAuthInfoPatch {
  /** Description of the auth method */
  description?: string | null
  /** Expiration date of the auth method */
  expiresAt?: string | null
  /** HTTP Header Field Name */
  name?: string
  /** HTTP Header Field Value */
  value?: string
}

export interface DiceApiHeaderAuthInfoCreate {
  /** Description of the auth method */
  description?: string
  /** Expiration date of the auth method */
  expiresAt?: string
  /** HTTP Header Field Name */
  name: string
  /** HTTP Header Field Value */
  value: string
}

export interface DiceApiHarPostData {
  comment?: string
  /** The MIME type of the request body. */
  mimeType: string
  /** The text of the request body. */
  text: string
}

export interface DiceApiHarNameValue {
  comment?: string
  name: string
  value: string
}

/**
 * A minimal HAR request object. Cookies are not supported. Other properties are automatically added.
 */
export interface DiceApiHarRequest {
  comment?: string
  headers?: DiceApiHarNameValue[]
  method: string
  postData?: DiceApiHarPostData
  queryString?: DiceApiHarNameValue[]
  url: string
}

export type DiceApiGroupingInfoAllOfStatus =
  (typeof DiceApiGroupingInfoAllOfStatus)[keyof typeof DiceApiGroupingInfoAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiGroupingInfoAllOfStatus = {
  active: 'active',
  inactive: 'inactive',
  indexing: 'indexing',
} as const

export type DiceApiGroupingInfoAllOf = {
  groupingId: string
  metadata: DiceApiStreamMetadata
  /** The JSON Type Definition of the grouping's rows */
  schema: DiceApiJsonTypeDefinition
  status: DiceApiGroupingInfoAllOfStatus
}

export interface DiceApiGroupedStreamInfo {
  groupings?: DiceApiGroupingInfo[]
  reindexJobs?: DiceApiReindexJob[]
}

export type DiceApiGroupBySimpleClause = {
  attributes: DiceApiSimpleModeAttribute[]
}

export interface DiceApiGroupBy {
  expression: string
  isCustomExpression?: boolean
  simpleClause?: DiceApiGroupBySimpleClause
}

export interface DiceApiGroupingDefinition {
  aggregations: DiceApiAggregation[]
  description?: string
  groupBy: DiceApiGroupBy
  title: string
}

export type DiceApiGroupingInfo = DiceApiGroupingDefinition &
  DiceApiGroupingInfoAllOf

export interface DiceApiFileUpload {
  /** The content type of the file to be uploaded */
  contentType: string
  /** The filename of the file to be uploaded */
  filename: string
  /** An optional subdirectory to be stored in */
  prefix?: string
}

/**
 * The status of an execution
 */
export type DiceApiExecutionStatus =
  (typeof DiceApiExecutionStatus)[keyof typeof DiceApiExecutionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiExecutionStatus = {
  canceled: 'canceled',
  finished: 'finished',
  expired: 'expired',
  failed: 'failed',
  running: 'running',
  pending: 'pending',
} as const

export interface DiceApiExecutionStatsEntry {
  /** The number of executions that resulted in an error. */
  error: number
  /** The number of executions that resulted in a fail. */
  fail: number
  /** The ISO timestamp of the first execution. */
  firstExecutionAt?: string
  /** The ISO timestamp of the last execution. */
  lastExecutionAt?: string
  /** The number of executions that resulted in a pass. */
  pass: number
}

export interface DiceApiExecutionResult {
  controlId: string
  executionId: string
  resultId: string
  resultMetadata: DiceApiResultMetadata
  tenantId: string
}

export interface DiceApiExecutionMetadata {
  /** The id of the control this execution belongs to */
  controlId: string
  /** The time the execution ended */
  endTime?: string
  /** The id of the execution */
  executionId: string
  /** The time the execution started */
  startTime?: string
  status: DiceApiExecutionStatus
  tenantId: string
}

export type DiceApiExecutionInputItem = { [key: string]: any }

export type DiceApiExecutionInput = DiceApiExecutionInputItem[]

export type DiceApiEventDetailsTaskCreatedType =
  (typeof DiceApiEventDetailsTaskCreatedType)[keyof typeof DiceApiEventDetailsTaskCreatedType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsTaskCreatedType = {
  'task-created': 'task-created',
} as const

export interface DiceApiEventDetailsTaskCreated {
  controlId: string
  executionId: string
  groupIds: string[]
  rowId: string
  rowTimestamp: string
  subtitle?: string
  taskId: string
  taskType: string
  title: string
  type: DiceApiEventDetailsTaskCreatedType
  userIds: string[]
}

export type DiceApiEventDetailsStreamRowUpdatedType =
  (typeof DiceApiEventDetailsStreamRowUpdatedType)[keyof typeof DiceApiEventDetailsStreamRowUpdatedType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsStreamRowUpdatedType = {
  'stream-row-updated': 'stream-row-updated',
} as const

/**
 * The row payload before the update
 */
export type DiceApiEventDetailsStreamRowUpdatedBefore = {
  payload?: unknown
  title?: string
}

/**
 * The row payload after the update
 */
export type DiceApiEventDetailsStreamRowUpdatedAfter = {
  payload?: unknown
  title?: string
}

export interface DiceApiEventDetailsStreamRowUpdated {
  /** The row payload after the update */
  after?: DiceApiEventDetailsStreamRowUpdatedAfter
  /** The row payload before the update */
  before?: DiceApiEventDetailsStreamRowUpdatedBefore
  rowId: string
  streamId: string
  type: DiceApiEventDetailsStreamRowUpdatedType
}

export type DiceApiEventDetailsStreamRowDeletedType =
  (typeof DiceApiEventDetailsStreamRowDeletedType)[keyof typeof DiceApiEventDetailsStreamRowDeletedType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsStreamRowDeletedType = {
  'stream-row-deleted': 'stream-row-deleted',
} as const

/**
 * The row payload before the deletion
 */
export type DiceApiEventDetailsStreamRowDeletedBefore = {
  payload?: unknown
  title?: string
}

export interface DiceApiEventDetailsStreamRowDeleted {
  /** The row payload before the deletion */
  before?: DiceApiEventDetailsStreamRowDeletedBefore
  rowId: string
  streamId: string
  type: DiceApiEventDetailsStreamRowDeletedType
}

export type DiceApiEventDetailsStreamRowCreatedType =
  (typeof DiceApiEventDetailsStreamRowCreatedType)[keyof typeof DiceApiEventDetailsStreamRowCreatedType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsStreamRowCreatedType = {
  'stream-row-created': 'stream-row-created',
} as const

/**
 * The the created row's payload
 */
export type DiceApiEventDetailsStreamRowCreatedAfter = {
  payload?: unknown
  title?: string
}

export interface DiceApiEventDetailsStreamRowCreated {
  /** The the created row's payload */
  after?: DiceApiEventDetailsStreamRowCreatedAfter
  rowId: string
  streamId: string
  type: DiceApiEventDetailsStreamRowCreatedType
}

export type DiceApiEventDetailsScheduledStreamFetchSuccessType =
  (typeof DiceApiEventDetailsScheduledStreamFetchSuccessType)[keyof typeof DiceApiEventDetailsScheduledStreamFetchSuccessType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsScheduledStreamFetchSuccessType = {
  'scheduled-stream-fetch-success': 'scheduled-stream-fetch-success',
} as const

export interface DiceApiEventDetailsScheduledStreamFetchSuccess {
  /** The request that was sent to the server. */
  serverRequest: unknown
  /** The response from the server, if there was one. */
  serverResponse: unknown
  /** The HTTP status code returned by the server, if there was one. */
  statusCode: number
  streamId: string
  type: DiceApiEventDetailsScheduledStreamFetchSuccessType
}

export type DiceApiEventDetailsScheduledStreamErrorType =
  (typeof DiceApiEventDetailsScheduledStreamErrorType)[keyof typeof DiceApiEventDetailsScheduledStreamErrorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsScheduledStreamErrorType = {
  'scheduled-stream-error': 'scheduled-stream-error',
} as const

export type DiceApiEventDetailsScheduledStreamErrorErrorType =
  (typeof DiceApiEventDetailsScheduledStreamErrorErrorType)[keyof typeof DiceApiEventDetailsScheduledStreamErrorErrorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsScheduledStreamErrorErrorType = {
  '4xx-server-response': '4xx-server-response',
  '5xx-server-response': '5xx-server-response',
  'parse-error': 'parse-error',
  'unknown-error': 'unknown-error',
  'request-timeout': 'request-timeout',
  'dns-resolution-error': 'dns-resolution-error',
  'stream-write-failure': 'stream-write-failure',
} as const

export interface DiceApiEventDetailsScheduledStreamError {
  errorType: DiceApiEventDetailsScheduledStreamErrorErrorType
  /** The request that was sent to the server. */
  serverRequest?: unknown
  /** The response from the server, if there was one. */
  serverResponse?: unknown
  /** The HTTP status code returned by the server, if there was one. */
  statusCode?: number
  streamId: string
  type: DiceApiEventDetailsScheduledStreamErrorType
}

export type DiceApiEventDetailsEffectWebhookType =
  (typeof DiceApiEventDetailsEffectWebhookType)[keyof typeof DiceApiEventDetailsEffectWebhookType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsEffectWebhookType = {
  'effect-webhook': 'effect-webhook',
} as const

export interface DiceApiEventDetailsEffectWebhook {
  /** The number of times this webhook has been retried. */
  retryCount: number
  /** The request that was sent to the server. */
  serverRequest: unknown
  /** The response from the server, if there was one. */
  serverResponse: unknown
  /** The HTTP status code returned by the server, if there was one. */
  statusCode: number
  type: DiceApiEventDetailsEffectWebhookType
}

export type DiceApiEventDetailsEffectEmailType =
  (typeof DiceApiEventDetailsEffectEmailType)[keyof typeof DiceApiEventDetailsEffectEmailType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsEffectEmailType = {
  'effect-email': 'effect-email',
} as const

/**
 * The resolved emails of the recipients of this email. In the format used by AWS SES.
 */
export type DiceApiEventDetailsEffectEmailResolvedRecipientEmails = {
  BccAddresses: string[]
  CcAddresses: string[]
  ToAddresses: string[]
}

export interface DiceApiEventDetailsEffectEmail {
  /** The resolved emails of the recipients of this email. In the format used by AWS SES. */
  resolvedRecipientEmails: DiceApiEventDetailsEffectEmailResolvedRecipientEmails
  specifiedRecipients: DiceApiEmailRecipientList
  type: DiceApiEventDetailsEffectEmailType
}

export type DiceApiEventDetailsDetectiveResultErrorType =
  (typeof DiceApiEventDetailsDetectiveResultErrorType)[keyof typeof DiceApiEventDetailsDetectiveResultErrorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsDetectiveResultErrorType = {
  'detective-result-error': 'detective-result-error',
} as const

export interface DiceApiEventDetailsDetectiveResultError {
  /** This is `DiceApiControlInfo.controlId` aka Topic ID and will hopefully be removed in the future. */
  controlId: string
  /** A human-readable explanation specific to this occurrence of the problem. */
  detail?: string
  /** Useful for filtering out all errors of any type. */
  isError: boolean
  rowId: string
  /** This is `DiceApiControlInfo.ruleId`. It is the ID of the control rule that failed. */
  ruleId: string
  /** A short, human-readable summary of the problem that should not change from occurrence to occurrence of the problem. */
  title: string
  type: DiceApiEventDetailsDetectiveResultErrorType
}

export type DiceApiEventDetailsDetectiveResultType =
  (typeof DiceApiEventDetailsDetectiveResultType)[keyof typeof DiceApiEventDetailsDetectiveResultType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsDetectiveResultType = {
  'detective-result': 'detective-result',
} as const

export interface DiceApiEventDetailsDetectiveResult {
  /** This is `DiceApiControlInfo.controlId` aka Topic ID and will hopefully be removed in the future. */
  controlId: string
  /** True if the control rule passed, false otherwise. */
  pass: boolean
  /** The reason why the control rule failed, specified by the user in DICE. */
  reason?: string
  resultStreamId: string
  rowId: string
  /** This is `DiceApiControlInfo.ruleId`. It is the ID of the control rule that was executed. */
  ruleId: string
  type: DiceApiEventDetailsDetectiveResultType
}

export type DiceApiEventDetailsControlUpdatedType =
  (typeof DiceApiEventDetailsControlUpdatedType)[keyof typeof DiceApiEventDetailsControlUpdatedType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsControlUpdatedType = {
  'control-updated': 'control-updated',
} as const

export interface DiceApiEventDetailsControlUpdated {
  /** This is `DiceApiControlInfo.controlId` aka Topic ID and will hopefully be removed in the future. */
  controlId: string
  newControlInfo: DiceApiControlInfo
  oldControlInfo: DiceApiControlInfo
  /** This is `DiceApiControlInfo.ruleId`. It is the ID of the control rule that was executed. */
  ruleId: string
  type: DiceApiEventDetailsControlUpdatedType
}

export type DiceApiEventDetailsControlCreatedType =
  (typeof DiceApiEventDetailsControlCreatedType)[keyof typeof DiceApiEventDetailsControlCreatedType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiEventDetailsControlCreatedType = {
  'control-created': 'control-created',
} as const

export interface DiceApiEventDetailsControlCreated {
  /** This is `DiceApiControlInfo.controlId` aka Topic ID and will hopefully be removed in the future. */
  controlId: string
  controlInfo: DiceApiControlInfo
  /** This is `DiceApiControlInfo.ruleId`. It is the ID of the control rule that was executed. */
  ruleId: string
  type: DiceApiEventDetailsControlCreatedType
}

export type DiceApiEventDetails =
  | DiceApiEventDetailsStreamRowCreated
  | DiceApiEventDetailsStreamRowUpdated
  | DiceApiEventDetailsStreamRowDeleted
  | DiceApiEventDetailsDetectiveResult
  | DiceApiEventDetailsDetectiveResultError
  | DiceApiEventDetailsScheduledStreamError
  | DiceApiEventDetailsScheduledStreamFetchSuccess
  | DiceApiEventDetailsTaskCreated
  | DiceApiEventDetailsEffectEmail
  | DiceApiEventDetailsEffectWebhook
  | DiceApiEventDetailsControlCreated
  | DiceApiEventDetailsControlUpdated

export interface DiceApiEvent {
  details: DiceApiEventDetails
  id: string
  tenantId: string
  timestamp: string
  /** An id that all events in a single request share. It tracks the path of a request through DICE. This may be generated at the stream data endpoint or via user interaction. */
  traceId: string
}

/**
 * Error objects provide additional information about problems encountered while performing an operation.
 */
export interface DiceApiErrorObjects {
  errors: DiceApiErrorObject[]
}

/**
 * The specific source of the error
 */
export type DiceApiErrorObjectSource = {
  /** A string indicating which query parameter caused the error */
  parameter?: string
  /** A JSON Pointer [RFC6901] to the associated entity in the request document */
  pointer?: string
}

/**
 * A set of additional metadata about the error
 */
export type DiceApiErrorObjectMeta = { [key: string]: any }

export interface DiceApiErrorObject {
  /** An application-specific error code */
  code?: string
  /** A human-readable explanation specific to this occurrence of the problem */
  detail?: string
  /** A unique identifier for this particular occurrence of the problem */
  id?: string
  /** A set of additional metadata about the error */
  meta?: DiceApiErrorObjectMeta
  /** The specific source of the error */
  source?: DiceApiErrorObjectSource
  /** The HTTP status code applicable to this problem, expressed as a string value */
  status?: string
  /** A short, human-readable summary of the problem that SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization */
  title?: string
}

/**
 * Basic information about a database entity
 */
export interface DiceApiEntityInfo {
  /** ISO date when the entity has been created */
  created: string
  entity: string
  /** ISO date when the entity has been last modified */
  modified: string
}

export type DiceApiEmailRecipientOneOfTwo = {
  userId: string
}

export type DiceApiEmailRecipientOneOf = {
  email: string
}

export type DiceApiEmailRecipient =
  | DiceApiEmailRecipientOneOf
  | DiceApiEmailRecipientOneOfTwo

export interface DiceApiEmailRecipientList {
  bccRecipients: DiceApiEmailRecipient[]
  ccRecipients: DiceApiEmailRecipient[]
  toRecipients: DiceApiEmailRecipient[]
}

/**
 * Condition when to trigger the effect
 */
export interface DiceApiEffectTriggerCondition {
  /** Trigger when the result is an errror */
  error: boolean
  /** Trigger when the result is a failure */
  fail: boolean
  /** Trigger when the result is a pass */
  pass: boolean
}

/**
 * The resources for the amazon states-language
 */
export type DiceApiDiceLibraryBlockStatesDefinition = { [key: string]: any }

/**
 * The schema to validate the block
 */
export type DiceApiDiceLibraryBlockCustomBlockValidationAjvSchema = {
  [key: string]: any
}

export type DiceApiDiceLibraryBlockCustomBlockValidation = {
  /** The schema to validate the block */
  ajvSchema?: DiceApiDiceLibraryBlockCustomBlockValidationAjvSchema
}

export interface DiceApiDiceLibraryBlock {
  /** The description of the block */
  blockDescription?: string
  /** The name of the block */
  blockDisplayName?: string
  blockId?: DiceApiBlockId
  /** The blocks that can be used after this block */
  compatibleNextBlocks?: string[]
  customBlockValidation?: DiceApiDiceLibraryBlockCustomBlockValidation
  /** The url of the icon for the block */
  iconUrl?: string
  /** The resources for the amazon states-language */
  statesDefinition?: DiceApiDiceLibraryBlockStatesDefinition
}

/**
 * Formatting options as [`Intl.DateTimeFormat`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat) options
 */
export interface DiceApiDateTimeFormatOptions {
  [key: string]: any
}

export interface DiceApiCustomUser {
  email: string
  name: string
}

export type DiceApiCreateWorkflowInfoDefinition = { [key: string]: any }

export interface DiceApiCreateWorkflowInfo {
  definition?: DiceApiCreateWorkflowInfoDefinition
}

export type DiceApiCreateUserInfoAllOf = {
  password?: string
  permanent?: boolean
}

export type DiceApiCreateUserInfo = DiceApiCreateUserInfoAllOf &
  DiceApiCommonUserInfo

export type DiceApiCreateTopicAllOf = {
  description: string
  displayName: string
}

export type DiceApiCreateTopic = DiceApiCreateTopicAllOf

export type DiceApiCreateRemediation = DiceApiCommonRemediation

export interface DiceApiCreateControlMetadata {
  addressedRisk?: string
  controlEntity?: string
  controlFrequency: DiceApiControlFrequency
  controlKeyControls: boolean
  controlObjective?: string
  description?: string
  displayName: string
  owner: DiceApiSimpleRecipient
  processCycle?: string
  riskCategory: DiceApiRiskCategory
  shortDescription: string
}

export type DiceApiCreateControlAllOfTrigger = {
  streamId: string
}

export type DiceApiCreateControlAllOfStatus =
  (typeof DiceApiCreateControlAllOfStatus)[keyof typeof DiceApiCreateControlAllOfStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiCreateControlAllOfStatus = {
  active: 'active',
  inactive: 'inactive',
} as const

export type DiceApiCreateControlAllOf = {
  controlAutomated: boolean
  controlCategoryType: DiceApiControlCategoryType
  controlDefinition: DiceApiCreateControlAllOfControlDefinition
  controlLogicType: DiceApiControlLogicType
  joins?: DiceApiJoinCondition[]
  status?: DiceApiCreateControlAllOfStatus
  trigger: DiceApiCreateControlAllOfTrigger
}

export type DiceApiCreateControl = DiceApiCreateControlMetadata &
  DiceApiCreateControlAllOf

export type DiceApiCreateControlAllOfControlDefinitionOneOfTwo = {
  workflowDefinition: DiceApiWorkflowDefinition
}

export type DiceApiCreateControlAllOfControlDefinitionOneOf = {
  simpleDefinition: DiceApiBaseJsonataCondition[]
}

export type DiceApiCreateControlAllOfControlDefinition =
  | DiceApiCreateControlAllOfControlDefinitionOneOf
  | DiceApiCreateControlAllOfControlDefinitionOneOfTwo

export interface DiceApiControlMetadata {
  addressedRisk?: string
  controlEntity?: string
  controlFrequency?: DiceApiControlFrequency
  controlKeyControls?: boolean
  controlObjective?: string
  description?: string
  displayName?: string
  owner?: DiceApiSimpleRecipient
  processCycle?: string
  riskCategory?: DiceApiRiskCategory
  shortDescription?: string
}

export type DiceApiControlLogicType =
  (typeof DiceApiControlLogicType)[keyof typeof DiceApiControlLogicType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiControlLogicType = {
  detective: 'detective',
  preventive: 'preventive',
} as const

export type DiceApiControlInfoAllOf = {
  controlId: string
  effects?: DiceApiControlEffects
  executionTarget: DiceApiRuleExecutionTarget
  resultStreams: string[]
  ruleId: string
  tenantId: string
}

export type DiceApiControlInfo = DiceApiCreateControl &
  DiceApiControlInfoAllOf &
  DiceApiEntityInfo

export type DiceApiControlFrequency =
  (typeof DiceApiControlFrequency)[keyof typeof DiceApiControlFrequency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiControlFrequency = {
  each_transaction: 'each transaction',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  annually: 'annually',
} as const

export interface DiceApiControlEffectWebhookPatch {
  /** The HAR request to be executed. */
  harRequest?: DiceApiHarRequest
  when?: DiceApiEffectTriggerCondition
}

export type DiceApiControlEffectWebhookCreateEffectType =
  (typeof DiceApiControlEffectWebhookCreateEffectType)[keyof typeof DiceApiControlEffectWebhookCreateEffectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiControlEffectWebhookCreateEffectType = {
  webhook: 'webhook',
} as const

export interface DiceApiControlEffectWebhookCreate {
  effectType: DiceApiControlEffectWebhookCreateEffectType
  /** The HAR request to be executed. */
  harRequest: DiceApiHarRequest
  when: DiceApiEffectTriggerCondition
}

export type DiceApiControlEffectWebhookEffectType =
  (typeof DiceApiControlEffectWebhookEffectType)[keyof typeof DiceApiControlEffectWebhookEffectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiControlEffectWebhookEffectType = {
  webhook: 'webhook',
} as const

export interface DiceApiControlEffectWebhook {
  effectId: string
  effectType: DiceApiControlEffectWebhookEffectType
  /** The HAR request to be executed at the specified cron expression. */
  harRequest: DiceApiHarRequest
  when: DiceApiEffectTriggerCondition
}

/**
 * Information about all active effects for a control
 */
export type DiceApiControlEffects = DiceApiControlEffect[]

export interface DiceApiControlEffectEmailPatch {
  recipients?: DiceApiEmailRecipientList
  when?: DiceApiEffectTriggerCondition
}

export type DiceApiControlEffectPatch =
  | DiceApiControlEffectEmailPatch
  | DiceApiControlEffectWebhookPatch

export type DiceApiControlEffectEmailCreateEffectType =
  (typeof DiceApiControlEffectEmailCreateEffectType)[keyof typeof DiceApiControlEffectEmailCreateEffectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiControlEffectEmailCreateEffectType = {
  email: 'email',
} as const

export interface DiceApiControlEffectEmailCreate {
  effectType: DiceApiControlEffectEmailCreateEffectType
  recipients: DiceApiEmailRecipientList
  when: DiceApiEffectTriggerCondition
}

export type DiceApiControlEffectEmailEffectType =
  (typeof DiceApiControlEffectEmailEffectType)[keyof typeof DiceApiControlEffectEmailEffectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiControlEffectEmailEffectType = {
  email: 'email',
} as const

export interface DiceApiControlEffectEmail {
  effectId: string
  effectType: DiceApiControlEffectEmailEffectType
  recipients: DiceApiEmailRecipientList
  when: DiceApiEffectTriggerCondition
}

export type DiceApiControlEffectCreate =
  | DiceApiControlEffectEmailCreate
  | DiceApiControlEffectWebhookCreate

export type DiceApiControlEffect =
  | DiceApiControlEffectEmail
  | DiceApiControlEffectWebhook

/**
 * The type of the control
 */
export type DiceApiControlCategoryType =
  (typeof DiceApiControlCategoryType)[keyof typeof DiceApiControlCategoryType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiControlCategoryType = {
  simple: 'simple',
  workflow: 'workflow',
} as const

export interface DiceApiCommonUserInfo {
  accessRole: DiceApiAccessRole
  businessRoles: string[]
  email: string
  profile: DiceApiUserProfile
}

export interface DiceApiCommonRemediation {
  attachments?: DiceApiAttachment[]
  controlId: string
  message: string
  ruleId: string
}

export type DiceApiCognitoUserInfoUserStatus =
  (typeof DiceApiCognitoUserInfoUserStatus)[keyof typeof DiceApiCognitoUserInfoUserStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiCognitoUserInfoUserStatus = {
  UNCONFIRMED: 'UNCONFIRMED',
  CONFIRMED: 'CONFIRMED',
  ARCHIVED: 'ARCHIVED',
  COMPROMISED: 'COMPROMISED',
  UNKNOWN: 'UNKNOWN',
  RESET_REQUIRED: 'RESET_REQUIRED',
  FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD',
} as const

export type DiceApiCognitoUserInfoFederatedIdentity = { [key: string]: any }

export interface DiceApiCognitoUserInfo {
  federatedIdentity?: DiceApiCognitoUserInfoFederatedIdentity
  userCreateDate: string
  userEnabled: boolean
  userLastModifiedDate: string
  userStatus: DiceApiCognitoUserInfoUserStatus
}

/**
 * The operator used to chain condition
 */
export type DiceApiChainingOperator =
  (typeof DiceApiChainingOperator)[keyof typeof DiceApiChainingOperator]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiChainingOperator = {
  AND: '&&',
  OR: '||',
} as const

/**
 * Additional information used to link tasks with signatures and documents
 */
export interface DiceApiCallbackTokenInfo {
  /** The token to use for the task callback */
  callbackToken?: string
}

/**
 * JTD and Ion boolean types for legacy compatibility. This should only be used for checking existing data. New data should use the `JtdBaseType` enum.
 */
export type DiceApiBooleanType =
  (typeof DiceApiBooleanType)[keyof typeof DiceApiBooleanType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiBooleanType = {
  bool: 'bool',
  boolean: 'boolean',
} as const

export type DiceApiBlockImplementationParameters = { [key: string]: any }

export type DiceApiBlockImplementationNext = string | string[]

/**
 * Base shape of a block implementation
 */
export interface DiceApiBlockImplementation {
  /** The ID of the block */
  blockId: string
  next?: DiceApiBlockImplementationNext
  parameters?: DiceApiBlockImplementationParameters
  /** The unique name of the block */
  uniqueName: string
}

/**
 * The operator used to chain condition
 */
export type DiceApiBlockId =
  (typeof DiceApiBlockId)[keyof typeof DiceApiBlockId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiBlockId = {
  JSONATA_CONDITION: 'JSONATA_CONDITION',
  SIGNATURE_REQUIRED: 'SIGNATURE_REQUIRED',
  SEND_TEMPLATED_EMAIL: 'SEND_TEMPLATED_EMAIL',
} as const

export interface DiceApiBillingInfo {
  address: DiceApiPostalAddress
  email: string
  paymentMethod?: DiceApiPaymentMethod
  vatId?: string
}

export type DiceApiBaseType =
  (typeof DiceApiBaseType)[keyof typeof DiceApiBaseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiBaseType = {
  ...DiceApiIonBaseType,
  ...DiceApiJtdBaseType,
} as const

export type DiceApiBaseJsonataConditionClausesItemSimpleClause = {
  attribute: DiceApiSimpleModeAttribute
  comparisonValue: unknown
  operator: DiceApiSimpleModeOperator
}

export type DiceApiBaseJsonataConditionClausesItem = {
  expression: string
  isCustomExpression?: boolean
  simpleClause?: DiceApiBaseJsonataConditionClausesItemSimpleClause
}

export interface DiceApiBaseJsonataCondition {
  chainingOperator: DiceApiChainingOperator
  clauses: DiceApiBaseJsonataConditionClausesItem[]
  outcome: string
}

export interface DiceApiAttachment {
  bucketName: string
  filename: string
  objectKey: string
}

export type DiceApiAggregationFunctionWithoutArgument =
  (typeof DiceApiAggregationFunctionWithoutArgument)[keyof typeof DiceApiAggregationFunctionWithoutArgument]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiAggregationFunctionWithoutArgument = {
  COUNT: 'COUNT',
  UPDATED_AT: 'UPDATED_AT',
} as const

export type DiceApiAggregationFunctionWithArgument =
  (typeof DiceApiAggregationFunctionWithArgument)[keyof typeof DiceApiAggregationFunctionWithArgument]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiAggregationFunctionWithArgument = {
  SUM: 'SUM',
  AVG: 'AVG',
  CONSTANT: 'CONSTANT',
  DISTINCT: 'DISTINCT',
} as const

export type DiceApiAggregationFunction =
  (typeof DiceApiAggregationFunction)[keyof typeof DiceApiAggregationFunction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiAggregationFunction = {
  ...DiceApiAggregationFunctionWithArgument,
  ...DiceApiAggregationFunctionWithoutArgument,
} as const

export type DiceApiAggregationSimpleClause = {
  attribute: DiceApiSimpleModeAttribute
}

export type DiceApiAggregationOneOfTwo = {
  /**
   * For compatibility reasons, this field exists but is ignored.
   * @deprecated
   */
  expression?: string
  function: DiceApiAggregationFunctionWithoutArgument
}

export type DiceApiAggregationOneOf = {
  expression: string
  function: DiceApiAggregationFunctionWithArgument
}

export type DiceApiAggregation =
  | (DiceApiAggregationOneOf & {
      isCustomExpression?: boolean
      name: string
      simpleClause?: DiceApiAggregationSimpleClause
    })
  | (DiceApiAggregationOneOfTwo & {
      isCustomExpression?: boolean
      name: string
      simpleClause?: DiceApiAggregationSimpleClause
    })

/**
 * Predefined Access Role that grants specific permission combinations.
 */
export type DiceApiAccessRole =
  (typeof DiceApiAccessRole)[keyof typeof DiceApiAccessRole]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiceApiAccessRole = {
  Admin: 'Admin',
  'Tech Admin': 'Tech Admin',
  'Control Admin': 'Control Admin',
  'Control Owner': 'Control Owner',
  Viewer: 'Viewer',
  'No Access': 'No Access',
} as const
