import { type SelectOption } from '@components/forms/inputs/FormikInput'
import { Combobox, Transition } from '@headlessui/react'
import {
  CheckIcon,
  SelectorIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/outline'
import { classNames } from '@utils'
import canonicalize from 'canonicalize'
import { Fragment, useRef, useState } from 'react'

type ComboBoxProps = {
  className?: string
  comboBoxKey?: string
  disabled?: boolean
  error?: string
  onChange: (changeValue: string, changeIndex: number) => void
  onDelete?: (value: number) => void
  onDeleteOwner?: () => void
  optionKey?: number
  options?: SelectOption[]
  preSelectedOption?: string
  touched?: boolean
  type: 'filter' | 'form'
}

export const ComboBox = ({
  comboBoxKey,
  disabled,
  error,
  touched,
  options,
  onDelete,
  onDeleteOwner,
  onChange,
  preSelectedOption,
  optionKey,
  className,
  type,
}: ComboBoxProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    preSelectedOption ?? ''
  )
  const [query, setQuery] = useState('')

  const openCloseCombobox = useRef<HTMLButtonElement>(null)

  const filteredOptions =
    query === ''
      ? options
      : options?.filter(option =>
          option.label
            .toLowerCase()
            .replace(/\s+/u, '')
            .includes(query.toLowerCase().replace(/\s+/u, ''))
        )
  return (
    <div
      className={`${className} ${
        type === 'form' && 'flex flex-grow space-x-4'
      }`}
      key={comboBoxKey}
    >
      <Combobox
        disabled={type === 'filter' ? false : disabled}
        onChange={value => {
          if (type === 'filter' && optionKey !== undefined) {
            onChange(value, optionKey)
          } else if (type === 'form') {
            onChange(value, 0)
          }

          setSelectedOption(value)
        }}
        value={selectedOption}
      >
        <div
          className={` ${type === 'form' && 'relative w-full mt-1'}`}
          onClick={() => {
            openCloseCombobox.current?.click()
          }}
        >
          <div className='relative col-span-2'>
            <Combobox.Input
              className={`${
                type === 'filter'
                  ? 'border-0 h-[30px] select-none overflow-hidden transition-all text-left cursor-default focus:outline-none rounded-md'
                  : `
              ${disabled === true ? 'bg-gray-100 text-gray-400' : 'bg-white'} ${
                      !(error === undefined) && touched === true
                        ? 'chakra-error-border !border-[1px] focus:ring-primary focus:border-primary'
                        : 'border border-slate-300 focus:ring-1 focus:ring-primary focus:border-primary'
                    } relative w-full h-[40px] select-none overflow-hidden transition-all text-left rounded-md shadow-sm cursor-default
              focus:outline-none sm:text-sm`
              }`}
              displayValue={(value: string) => {
                if (options === undefined) return value
                if (value === '') return ''
                return (
                  options.find(option => option.value === selectedOption)
                    ?.label ?? ''
                )
              }}
              onChange={event => {
                setQuery(event.target.value)
              }}
              placeholder='Select an option'
            />

            <Combobox.Button
              className={`${
                type === 'filter'
                  ? 'hidden'
                  : 'absolute inset-y-0 right-0 flex items-center pr-2'
              }`}
              ref={openCloseCombobox}
            >
              {(disabled === undefined || disabled === false) && (
                <SelectorIcon
                  aria-hidden='true'
                  className='w-5 h-5 text-slate-400'
                />
              )}
            </Combobox.Button>
            <Transition
              afterLeave={() => setQuery('')}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Combobox.Options
                className={`${
                  type === 'form' && 'min-w-full'
                } absolute z-10 w-auto py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg outline-none max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
              >
                {filteredOptions?.length === 0 ? (
                  <div className='relative px-3 py-2 text-gray-900 cursor-default select-none'>
                    No options found
                  </div>
                ) : (
                  filteredOptions?.map((item, index) => {
                    return (
                      <Combobox.Option
                        className={({ active }) => {
                          return classNames(
                            active
                              ? 'text-white bg-orange-600'
                              : 'text-slate-900',
                            ' cursor-default select-none relative py-2 pl-3 pr-9 group'
                          )
                        }}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${item.label}-${index}`}
                        value={item.value}
                      >
                        {({ active }) => {
                          return (
                            <div className='space-y-1'>
                              <span
                                className={classNames(
                                  item.value === selectedOption
                                    ? 'font-semibold'
                                    : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {item.label}
                              </span>
                              {canonicalize(item.value) ===
                              canonicalize(selectedOption) ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-orange-600',
                                    ' absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    aria-hidden='true'
                                    className='w-5 h-5'
                                  />
                                </span>
                              ) : undefined}
                              {item.renderDescription !== undefined && (
                                <div>{item.renderDescription()}</div>
                              )}
                            </div>
                          )
                        }}
                      </Combobox.Option>
                    )
                  })
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </div>
      </Combobox>
      {type === 'filter' &&
        onDelete !== undefined &&
        optionKey !== undefined && (
          <div
            className='my-auto mr-1 cursor-pointer select-none'
            onClick={() => {
              onDelete(optionKey)
            }}
          >
            <XIcon className='block w-5 h-5 text-gray-400 hover:text-red-500' />
          </div>
        )}
      {type !== 'filter' && onDeleteOwner && (
        <div className='relative'>
          <div
            className='grid w-8 h-10 mt-1 select-none cursor-pointe place-items-center'
            onClick={() => {
              onDeleteOwner()
              setSelectedOption('')
            }}
          >
            <TrashIcon className='block w-5 h-5 text-red-600 hover:text-red-700' />
          </div>
        </div>
      )}
    </div>
  )
}
