// @ts-nocheck
/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * hae.sh DICE
 * This API allows ….
 * OpenAPI spec version: 1.0.0
 */
import { useQuery, useInfiniteQuery, useMutation } from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  UseInfiniteQueryResult,
  QueryKey,
} from '@tanstack/react-query'
import type {
  DiceApiTopicInfo,
  PostControls200,
  DiceApiCreateTopic,
  GetControlsControlId200,
  ValidationFailedResponse,
  NotFoundResponse,
  PatchControlsControlId500,
  PatchControlsControlIdBody,
  GetControlsControlIdRemediations200,
  GetControlsControlIdRemediationsParams,
  GetControlsControlIdResults200,
  GetControlsControlIdResultsParams,
  PostControlsControlIdRules200,
  DiceApiCreateControl,
  GetControlsControlIdRulesRuleId200,
  GetControlsControlIdRulesRuleIdParams,
  ForbiddenResponse,
  ServerErrorResponse,
  DiceApiPatchControl,
  DiceApiControlEffect,
  DiceApiControlEffectCreate,
  DiceApiControlEffectPatch,
  GetControlsControlIdRulesRuleIdRemediations200,
  GetControlsControlIdRulesRuleIdRemediationsParams,
  GetControlsControlIdRulesRuleIdResults200,
  GetControlsControlIdRulesRuleIdResultsParams,
  GetEvents200,
  MalformedSearchQueryResponse,
  GetEventsParams,
  GetEventsEventId200,
  GetExecutions200,
  GetExecutionsParams,
  GetExecutionsExecutionId200,
  GetExecutionsExecutionIdInputFileId200,
  GetExecutionsExecutionIdTasks200,
  GetExecutionsExecutionIdTasksTaskId200,
  GetExecutionsCount200,
  GetExecutionsCountParams,
  PostFiles200,
  DiceApiFileUpload,
  GetFilesFileKey200,
  GetFilesFileKeyParams,
  GetLoginOptionsTenantAlias200,
  PostSignatures201,
  PostSignatures403,
  PostSignatures412,
  PostSignaturesBody,
  GetStats200,
  GetStats400,
  GetStatsParams,
  DiceApiStreamInfoRead,
  PostStreams200,
  DiceApiStreamInfoCreate,
  DiceApiStreamInfoPatch,
  DiceApiStreamTransaction,
  PutStreamsStreamIdBody,
  DiceApiHeaderAuthInfoRead,
  CreateStreamAuthorizationHeader412,
  DiceApiHeaderAuthInfoCreate,
  DiceApiHeaderAuthInfoPatch,
  PostStreamsStreamIdGroupings200,
  DiceApiGroupingDefinition,
  ManualInvokeScheduledStream412,
  DiceApiRemediation,
  PostStreamsStreamIdRemediationsRowId409,
  DiceApiCreateRemediation,
  GetStreamsStreamIdRows200,
  GetStreamsStreamIdRows400,
  GetStreamsStreamIdRowsParams,
  GetStreamsStreamIdRowsRowId200,
  GetStreamsStreamIdRowsRowIdParams,
  GetStreamsStreamIdRowsRowIdHistory200,
  GetStreamsStreamIdRowsRowIdHistoryParams,
  GetTasks200,
  GetTasksParams,
  DiceApiTenantInfo,
  GetUsers200Item,
  PostUsers200,
  PostUsers412,
  DiceApiCreateUserInfo,
  GetUsersUserId200,
  DiceApiPatchUserInfo,
  PostUsersUserIdEnable412,
  PostUsersUserIdResendSignup412,
  PostUsersUserIdResetPassword412,
  GetWorkflowsBlocks200,
} from './diceApi.schemas'
import { axiosMutator } from '../utils/axiosMutator'
import type { ErrorType } from '../utils/axiosMutator'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

/**
 * Retrieve information about all topics of the tenant of the currently logged in user.
 * @summary Get all control infos
 */
export const getControls = (signal?: AbortSignal) => {
  return axiosMutator<DiceApiTopicInfo[]>({
    url: `/controls`,
    method: 'get',
    signal,
  })
}

export const getGetControlsQueryKey = () => [`/controls`]

export type GetControlsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControls>>
>
export type GetControlsInfiniteQueryError = ErrorType<unknown>

export const useGetControlsInfinite = <
  TData = Awaited<ReturnType<typeof getControls>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getControls>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetControlsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getControls>>> = ({
    signal,
  }) => getControls(signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getControls>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetControlsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControls>>
>
export type GetControlsQueryError = ErrorType<unknown>

export const useGetControls = <
  TData = Awaited<ReturnType<typeof getControls>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getControls>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetControlsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getControls>>> = ({
    signal,
  }) => getControls(signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getControls>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Create a new Top
 */
export const postControls = (diceApiCreateTopic: DiceApiCreateTopic) => {
  return axiosMutator<PostControls200>({
    url: `/controls`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiCreateTopic,
  })
}

export type PostControlsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postControls>>
>
export type PostControlsMutationBody = DiceApiCreateTopic
export type PostControlsMutationError = ErrorType<unknown>

export const usePostControls = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postControls>>,
    TError,
    { data: DiceApiCreateTopic },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postControls>>,
    { data: DiceApiCreateTopic }
  > = (props) => {
    const { data } = props ?? {}

    return postControls(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postControls>>,
    TError,
    { data: DiceApiCreateTopic },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * @summary Get a control's info
 */
export const getControlsControlId = (
  controlId: string,
  signal?: AbortSignal
) => {
  return axiosMutator<GetControlsControlId200>({
    url: `/controls/${controlId}`,
    method: 'get',
    signal,
  })
}

export const getGetControlsControlIdQueryKey = (controlId: string) => [
  `/controls/${controlId}`,
]

export type GetControlsControlIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControlsControlId>>
>
export type GetControlsControlIdInfiniteQueryError = ErrorType<unknown>

export const useGetControlsControlIdInfinite = <
  TData = Awaited<ReturnType<typeof getControlsControlId>>,
  TError = ErrorType<unknown>
>(
  controlId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getControlsControlId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetControlsControlIdQueryKey(controlId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlId>>
  > = ({ signal }) => getControlsControlId(controlId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getControlsControlId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!controlId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetControlsControlIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControlsControlId>>
>
export type GetControlsControlIdQueryError = ErrorType<unknown>

export const useGetControlsControlId = <
  TData = Awaited<ReturnType<typeof getControlsControlId>>,
  TError = ErrorType<unknown>
>(
  controlId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getControlsControlId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetControlsControlIdQueryKey(controlId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlId>>
  > = ({ signal }) => getControlsControlId(controlId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getControlsControlId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!controlId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Update the metadata of a Topic
 */
export const patchControlsControlId = (
  controlId: string,
  patchControlsControlIdBody: PatchControlsControlIdBody
) => {
  return axiosMutator<DiceApiTopicInfo>({
    url: `/controls/${controlId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: patchControlsControlIdBody,
  })
}

export type PatchControlsControlIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchControlsControlId>>
>
export type PatchControlsControlIdMutationBody = PatchControlsControlIdBody
export type PatchControlsControlIdMutationError = ErrorType<
  ValidationFailedResponse | NotFoundResponse | PatchControlsControlId500
>

export const usePatchControlsControlId = <
  TError = ErrorType<
    ValidationFailedResponse | NotFoundResponse | PatchControlsControlId500
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchControlsControlId>>,
    TError,
    { controlId: string; data: PatchControlsControlIdBody },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchControlsControlId>>,
    { controlId: string; data: PatchControlsControlIdBody }
  > = (props) => {
    const { controlId, data } = props ?? {}

    return patchControlsControlId(controlId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchControlsControlId>>,
    TError,
    { controlId: string; data: PatchControlsControlIdBody },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * @summary Get a control's remediations
 */
export const getControlsControlIdRemediations = (
  controlId: string,
  params?: GetControlsControlIdRemediationsParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetControlsControlIdRemediations200>({
    url: `/controls/${controlId}/remediations`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetControlsControlIdRemediationsQueryKey = (
  controlId: string,
  params?: GetControlsControlIdRemediationsParams
) => [`/controls/${controlId}/remediations`, ...(params ? [params] : [])]

export type GetControlsControlIdRemediationsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControlsControlIdRemediations>>
>
export type GetControlsControlIdRemediationsInfiniteQueryError =
  ErrorType<unknown>

export const useGetControlsControlIdRemediationsInfinite = <
  TData = Awaited<ReturnType<typeof getControlsControlIdRemediations>>,
  TError = ErrorType<unknown>
>(
  controlId: string,
  params?: GetControlsControlIdRemediationsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdRemediations>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdRemediationsQueryKey(controlId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdRemediations>>
  > = ({ signal, pageParam }) =>
    getControlsControlIdRemediations(
      controlId,
      { nextToken: pageParam, ...params },
      signal
    )

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getControlsControlIdRemediations>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!controlId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetControlsControlIdRemediationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControlsControlIdRemediations>>
>
export type GetControlsControlIdRemediationsQueryError = ErrorType<unknown>

export const useGetControlsControlIdRemediations = <
  TData = Awaited<ReturnType<typeof getControlsControlIdRemediations>>,
  TError = ErrorType<unknown>
>(
  controlId: string,
  params?: GetControlsControlIdRemediationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdRemediations>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdRemediationsQueryKey(controlId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdRemediations>>
  > = ({ signal }) =>
    getControlsControlIdRemediations(controlId, params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getControlsControlIdRemediations>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!controlId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Get a control's results
 */
export const getControlsControlIdResults = (
  controlId: string,
  params?: GetControlsControlIdResultsParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetControlsControlIdResults200>({
    url: `/controls/${controlId}/results`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetControlsControlIdResultsQueryKey = (
  controlId: string,
  params?: GetControlsControlIdResultsParams
) => [`/controls/${controlId}/results`, ...(params ? [params] : [])]

export type GetControlsControlIdResultsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControlsControlIdResults>>
>
export type GetControlsControlIdResultsInfiniteQueryError = ErrorType<unknown>

export const useGetControlsControlIdResultsInfinite = <
  TData = Awaited<ReturnType<typeof getControlsControlIdResults>>,
  TError = ErrorType<unknown>
>(
  controlId: string,
  params?: GetControlsControlIdResultsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdResults>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdResultsQueryKey(controlId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdResults>>
  > = ({ signal, pageParam }) =>
    getControlsControlIdResults(
      controlId,
      { nextToken: pageParam, ...params },
      signal
    )

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getControlsControlIdResults>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!controlId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetControlsControlIdResultsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControlsControlIdResults>>
>
export type GetControlsControlIdResultsQueryError = ErrorType<unknown>

export const useGetControlsControlIdResults = <
  TData = Awaited<ReturnType<typeof getControlsControlIdResults>>,
  TError = ErrorType<unknown>
>(
  controlId: string,
  params?: GetControlsControlIdResultsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdResults>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdResultsQueryKey(controlId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdResults>>
  > = ({ signal }) => getControlsControlIdResults(controlId, params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getControlsControlIdResults>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!controlId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a new rule.
 * @summary Creates a new control
 */
export const postControlsControlIdRules = (
  controlId: string,
  diceApiCreateControl: DiceApiCreateControl
) => {
  return axiosMutator<PostControlsControlIdRules200>({
    url: `/controls/${controlId}/rules`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiCreateControl,
  })
}

export type PostControlsControlIdRulesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postControlsControlIdRules>>
>
export type PostControlsControlIdRulesMutationBody = DiceApiCreateControl
export type PostControlsControlIdRulesMutationError = ErrorType<unknown>

export const usePostControlsControlIdRules = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postControlsControlIdRules>>,
    TError,
    { controlId: string; data: DiceApiCreateControl },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postControlsControlIdRules>>,
    { controlId: string; data: DiceApiCreateControl }
  > = (props) => {
    const { controlId, data } = props ?? {}

    return postControlsControlIdRules(controlId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postControlsControlIdRules>>,
    TError,
    { controlId: string; data: DiceApiCreateControl },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get a Control by id
 * @summary Creates a new control
 */
export const getControlsControlIdRulesRuleId = (
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetControlsControlIdRulesRuleId200>({
    url: `/controls/${controlId}/rules/${ruleId}`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetControlsControlIdRulesRuleIdQueryKey = (
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdParams
) => [`/controls/${controlId}/rules/${ruleId}`, ...(params ? [params] : [])]

export type GetControlsControlIdRulesRuleIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>
>
export type GetControlsControlIdRulesRuleIdInfiniteQueryError =
  ErrorType<NotFoundResponse>

export const useGetControlsControlIdRulesRuleIdInfinite = <
  TData = Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>,
  TError = ErrorType<NotFoundResponse>
>(
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdRulesRuleIdQueryKey(controlId, ruleId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>
  > = ({ signal, pageParam }) =>
    getControlsControlIdRulesRuleId(
      controlId,
      ruleId,
      { nextToken: pageParam, ...params },
      signal
    )

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(controlId && ruleId),
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetControlsControlIdRulesRuleIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>
>
export type GetControlsControlIdRulesRuleIdQueryError =
  ErrorType<NotFoundResponse>

export const useGetControlsControlIdRulesRuleId = <
  TData = Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>,
  TError = ErrorType<NotFoundResponse>
>(
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdRulesRuleIdQueryKey(controlId, ruleId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>
  > = ({ signal }) =>
    getControlsControlIdRulesRuleId(controlId, ruleId, params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(controlId && ruleId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Update a control's metadata, trigger and joined streams, and logic.
 * @summary Update a control
 */
export const patchControlsControlIdRulesRuleId = (
  controlId: string,
  ruleId: string,
  diceApiPatchControl: DiceApiPatchControl
) => {
  return axiosMutator<void>({
    url: `/controls/${controlId}/rules/${ruleId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiPatchControl,
  })
}

export type PatchControlsControlIdRulesRuleIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchControlsControlIdRulesRuleId>>
>
export type PatchControlsControlIdRulesRuleIdMutationBody = DiceApiPatchControl
export type PatchControlsControlIdRulesRuleIdMutationError = ErrorType<
  ForbiddenResponse | NotFoundResponse | ServerErrorResponse
>

export const usePatchControlsControlIdRulesRuleId = <
  TError = ErrorType<
    ForbiddenResponse | NotFoundResponse | ServerErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchControlsControlIdRulesRuleId>>,
    TError,
    { controlId: string; ruleId: string; data: DiceApiPatchControl },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchControlsControlIdRulesRuleId>>,
    { controlId: string; ruleId: string; data: DiceApiPatchControl }
  > = (props) => {
    const { controlId, ruleId, data } = props ?? {}

    return patchControlsControlIdRulesRuleId(controlId, ruleId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchControlsControlIdRulesRuleId>>,
    TError,
    { controlId: string; ruleId: string; data: DiceApiPatchControl },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Send new control effects identified by its `controlId` and `ruleId`. You can send one or more effects. To delete an effect, set it to `null`.
 * @summary Creates an effect for a control
 */
export const postControlsControlIdRulesRuleIdEffects = (
  controlId: string,
  ruleId: string,
  diceApiControlEffectCreate: DiceApiControlEffectCreate
) => {
  return axiosMutator<DiceApiControlEffect>({
    url: `/controls/${controlId}/rules/${ruleId}/effects`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiControlEffectCreate,
  })
}

export type PostControlsControlIdRulesRuleIdEffectsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postControlsControlIdRulesRuleIdEffects>>
>
export type PostControlsControlIdRulesRuleIdEffectsMutationBody =
  DiceApiControlEffectCreate
export type PostControlsControlIdRulesRuleIdEffectsMutationError =
  ErrorType<unknown>

export const usePostControlsControlIdRulesRuleIdEffects = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postControlsControlIdRulesRuleIdEffects>>,
    TError,
    { controlId: string; ruleId: string; data: DiceApiControlEffectCreate },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postControlsControlIdRulesRuleIdEffects>>,
    { controlId: string; ruleId: string; data: DiceApiControlEffectCreate }
  > = (props) => {
    const { controlId, ruleId, data } = props ?? {}

    return postControlsControlIdRulesRuleIdEffects(controlId, ruleId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postControlsControlIdRulesRuleIdEffects>>,
    TError,
    { controlId: string; ruleId: string; data: DiceApiControlEffectCreate },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Delecte a specific control effect identified by its `controlId`, `ruleId` and `effectId`.
 * @summary Delete a specific control effect
 */
export const deleteControlEffect = (
  controlId: string,
  ruleId: string,
  effectId: string
) => {
  return axiosMutator<void>({
    url: `/controls/${controlId}/rules/${ruleId}/effects/${effectId}`,
    method: 'delete',
  })
}

export type DeleteControlEffectMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteControlEffect>>
>

export type DeleteControlEffectMutationError = ErrorType<
  ForbiddenResponse | NotFoundResponse
>

export const useDeleteControlEffect = <
  TError = ErrorType<ForbiddenResponse | NotFoundResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteControlEffect>>,
    TError,
    { controlId: string; ruleId: string; effectId: string },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteControlEffect>>,
    { controlId: string; ruleId: string; effectId: string }
  > = (props) => {
    const { controlId, ruleId, effectId } = props ?? {}

    return deleteControlEffect(controlId, ruleId, effectId)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteControlEffect>>,
    TError,
    { controlId: string; ruleId: string; effectId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Update the information about a specific control effect identified by its `controlId`, `ruleId` and `effectId`.
 * @summary Update a control effect
 */
export const updateControlEffect = (
  controlId: string,
  ruleId: string,
  effectId: string,
  diceApiControlEffectPatch: DiceApiControlEffectPatch
) => {
  return axiosMutator<void>({
    url: `/controls/${controlId}/rules/${ruleId}/effects/${effectId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiControlEffectPatch,
  })
}

export type UpdateControlEffectMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateControlEffect>>
>
export type UpdateControlEffectMutationBody = DiceApiControlEffectPatch
export type UpdateControlEffectMutationError = ErrorType<unknown>

export const useUpdateControlEffect = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateControlEffect>>,
    TError,
    {
      controlId: string
      ruleId: string
      effectId: string
      data: DiceApiControlEffectPatch
    },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateControlEffect>>,
    {
      controlId: string
      ruleId: string
      effectId: string
      data: DiceApiControlEffectPatch
    }
  > = (props) => {
    const { controlId, ruleId, effectId, data } = props ?? {}

    return updateControlEffect(controlId, ruleId, effectId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof updateControlEffect>>,
    TError,
    {
      controlId: string
      ruleId: string
      effectId: string
      data: DiceApiControlEffectPatch
    },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * @summary Get a rule's remediations
 */
export const getControlsControlIdRulesRuleIdRemediations = (
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdRemediationsParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetControlsControlIdRulesRuleIdRemediations200>({
    url: `/controls/${controlId}/rules/${ruleId}/remediations`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetControlsControlIdRulesRuleIdRemediationsQueryKey = (
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdRemediationsParams
) => [
  `/controls/${controlId}/rules/${ruleId}/remediations`,
  ...(params ? [params] : []),
]

export type GetControlsControlIdRulesRuleIdRemediationsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>>
  >
export type GetControlsControlIdRulesRuleIdRemediationsInfiniteQueryError =
  ErrorType<unknown>

export const useGetControlsControlIdRulesRuleIdRemediationsInfinite = <
  TData = Awaited<
    ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>
  >,
  TError = ErrorType<unknown>
>(
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdRemediationsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdRulesRuleIdRemediationsQueryKey(
      controlId,
      ruleId,
      params
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>>
  > = ({ signal, pageParam }) =>
    getControlsControlIdRulesRuleIdRemediations(
      controlId,
      ruleId,
      { nextToken: pageParam, ...params },
      signal
    )

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(controlId && ruleId),
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetControlsControlIdRulesRuleIdRemediationsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>>
  >
export type GetControlsControlIdRulesRuleIdRemediationsQueryError =
  ErrorType<unknown>

export const useGetControlsControlIdRulesRuleIdRemediations = <
  TData = Awaited<
    ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>
  >,
  TError = ErrorType<unknown>
>(
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdRemediationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdRulesRuleIdRemediationsQueryKey(
      controlId,
      ruleId,
      params
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>>
  > = ({ signal }) =>
    getControlsControlIdRulesRuleIdRemediations(
      controlId,
      ruleId,
      params,
      signal
    )

  const query = useQuery<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdRemediations>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(controlId && ruleId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Get a rule's results
 */
export const getControlsControlIdRulesRuleIdResults = (
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdResultsParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetControlsControlIdRulesRuleIdResults200>({
    url: `/controls/${controlId}/rules/${ruleId}/results`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetControlsControlIdRulesRuleIdResultsQueryKey = (
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdResultsParams
) => [
  `/controls/${controlId}/rules/${ruleId}/results`,
  ...(params ? [params] : []),
]

export type GetControlsControlIdRulesRuleIdResultsInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>
  >
export type GetControlsControlIdRulesRuleIdResultsInfiniteQueryError =
  ErrorType<unknown>

export const useGetControlsControlIdRulesRuleIdResultsInfinite = <
  TData = Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>,
  TError = ErrorType<unknown>
>(
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdResultsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdRulesRuleIdResultsQueryKey(controlId, ruleId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>
  > = ({ signal, pageParam }) =>
    getControlsControlIdRulesRuleIdResults(
      controlId,
      ruleId,
      { nextToken: pageParam, ...params },
      signal
    )

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(controlId && ruleId),
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetControlsControlIdRulesRuleIdResultsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>
>
export type GetControlsControlIdRulesRuleIdResultsQueryError =
  ErrorType<unknown>

export const useGetControlsControlIdRulesRuleIdResults = <
  TData = Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>,
  TError = ErrorType<unknown>
>(
  controlId: string,
  ruleId: string,
  params?: GetControlsControlIdRulesRuleIdResultsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetControlsControlIdRulesRuleIdResultsQueryKey(controlId, ruleId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>
  > = ({ signal }) =>
    getControlsControlIdRulesRuleIdResults(controlId, ruleId, params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getControlsControlIdRulesRuleIdResults>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(controlId && ruleId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getEvents = (params: GetEventsParams, signal?: AbortSignal) => {
  return axiosMutator<GetEvents200>({
    url: `/events`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetEventsQueryKey = (params: GetEventsParams) => [
  `/events`,
  ...(params ? [params] : []),
]

export type GetEventsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEvents>>
>
export type GetEventsInfiniteQueryError =
  ErrorType<MalformedSearchQueryResponse>

export const useGetEventsInfinite = <
  TData = Awaited<ReturnType<typeof getEvents>>,
  TError = ErrorType<MalformedSearchQueryResponse>
>(
  params: GetEventsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getEvents>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEventsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvents>>> = ({
    signal,
    pageParam,
  }) => getEvents({ nextToken: pageParam, ...params }, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getEvents>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetEventsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEvents>>
>
export type GetEventsQueryError = ErrorType<MalformedSearchQueryResponse>

export const useGetEvents = <
  TData = Awaited<ReturnType<typeof getEvents>>,
  TError = ErrorType<MalformedSearchQueryResponse>
>(
  params: GetEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEvents>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEventsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvents>>> = ({
    signal,
  }) => getEvents(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getEvents>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getEventsEventId = (eventId: string, signal?: AbortSignal) => {
  return axiosMutator<GetEventsEventId200>({
    url: `/events/${eventId}`,
    method: 'get',
    signal,
  })
}

export const getGetEventsEventIdQueryKey = (eventId: string) => [
  `/events/${eventId}`,
]

export type GetEventsEventIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEventsEventId>>
>
export type GetEventsEventIdInfiniteQueryError = ErrorType<NotFoundResponse>

export const useGetEventsEventIdInfinite = <
  TData = Awaited<ReturnType<typeof getEventsEventId>>,
  TError = ErrorType<NotFoundResponse>
>(
  eventId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getEventsEventId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetEventsEventIdQueryKey(eventId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEventsEventId>>
  > = ({ signal }) => getEventsEventId(eventId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getEventsEventId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!eventId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetEventsEventIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEventsEventId>>
>
export type GetEventsEventIdQueryError = ErrorType<NotFoundResponse>

export const useGetEventsEventId = <
  TData = Awaited<ReturnType<typeof getEventsEventId>>,
  TError = ErrorType<NotFoundResponse>
>(
  eventId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEventsEventId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetEventsEventIdQueryKey(eventId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEventsEventId>>
  > = ({ signal }) => getEventsEventId(eventId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getEventsEventId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!eventId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieve all executions, but only their metadata, for the tenant of the currently logged in user.
 * @summary Get all executions (only metadata)
 */
export const getExecutions = (
  params?: GetExecutionsParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetExecutions200>({
    url: `/executions`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetExecutionsQueryKey = (params?: GetExecutionsParams) => [
  `/executions`,
  ...(params ? [params] : []),
]

export type GetExecutionsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutions>>
>
export type GetExecutionsInfiniteQueryError = ErrorType<unknown>

export const useGetExecutionsInfinite = <
  TData = Awaited<ReturnType<typeof getExecutions>>,
  TError = ErrorType<unknown>
>(
  params?: GetExecutionsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getExecutions>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetExecutionsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecutions>>> = ({
    signal,
    pageParam,
  }) => getExecutions({ nextToken: pageParam, ...params }, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getExecutions>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetExecutionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutions>>
>
export type GetExecutionsQueryError = ErrorType<unknown>

export const useGetExecutions = <
  TData = Awaited<ReturnType<typeof getExecutions>>,
  TError = ErrorType<unknown>
>(
  params?: GetExecutionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExecutions>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetExecutionsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecutions>>> = ({
    signal,
  }) => getExecutions(params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getExecutions>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieve details about an execution, including tasks and input data references.
 * @summary Get an execution
 */
export const getExecutionsExecutionId = (
  executionId: string,
  signal?: AbortSignal
) => {
  return axiosMutator<GetExecutionsExecutionId200>({
    url: `/executions/${executionId}`,
    method: 'get',
    signal,
  })
}

export const getGetExecutionsExecutionIdQueryKey = (executionId: string) => [
  `/executions/${executionId}`,
]

export type GetExecutionsExecutionIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutionsExecutionId>>
>
export type GetExecutionsExecutionIdInfiniteQueryError = ErrorType<unknown>

export const useGetExecutionsExecutionIdInfinite = <
  TData = Awaited<ReturnType<typeof getExecutionsExecutionId>>,
  TError = ErrorType<unknown>
>(
  executionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getExecutionsExecutionId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetExecutionsExecutionIdQueryKey(executionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsExecutionId>>
  > = ({ signal }) => getExecutionsExecutionId(executionId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getExecutionsExecutionId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!executionId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetExecutionsExecutionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutionsExecutionId>>
>
export type GetExecutionsExecutionIdQueryError = ErrorType<unknown>

export const useGetExecutionsExecutionId = <
  TData = Awaited<ReturnType<typeof getExecutionsExecutionId>>,
  TError = ErrorType<unknown>
>(
  executionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExecutionsExecutionId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetExecutionsExecutionIdQueryKey(executionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsExecutionId>>
  > = ({ signal }) => getExecutionsExecutionId(executionId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getExecutionsExecutionId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!executionId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieve details about an execution, including tasks and input data references.
 * @summary Get the input of an execution
 */
export const getExecutionsExecutionIdInputFileId = (
  executionId: string,
  fileId: string,
  signal?: AbortSignal
) => {
  return axiosMutator<GetExecutionsExecutionIdInputFileId200>({
    url: `/executions/${executionId}/input/${fileId}`,
    method: 'get',
    signal,
  })
}

export const getGetExecutionsExecutionIdInputFileIdQueryKey = (
  executionId: string,
  fileId: string
) => [`/executions/${executionId}/input/${fileId}`]

export type GetExecutionsExecutionIdInputFileIdInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>>
export type GetExecutionsExecutionIdInputFileIdInfiniteQueryError =
  ErrorType<void | NotFoundResponse>

export const useGetExecutionsExecutionIdInputFileIdInfinite = <
  TData = Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>,
  TError = ErrorType<void | NotFoundResponse>
>(
  executionId: string,
  fileId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetExecutionsExecutionIdInputFileIdQueryKey(executionId, fileId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>
  > = ({ signal }) =>
    getExecutionsExecutionIdInputFileId(executionId, fileId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(executionId && fileId),
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetExecutionsExecutionIdInputFileIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>
>
export type GetExecutionsExecutionIdInputFileIdQueryError =
  ErrorType<void | NotFoundResponse>

export const useGetExecutionsExecutionIdInputFileId = <
  TData = Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>,
  TError = ErrorType<void | NotFoundResponse>
>(
  executionId: string,
  fileId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetExecutionsExecutionIdInputFileIdQueryKey(executionId, fileId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>
  > = ({ signal }) =>
    getExecutionsExecutionIdInputFileId(executionId, fileId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getExecutionsExecutionIdInputFileId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(executionId && fileId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary List tasks of an execution
 */
export const getExecutionsExecutionIdTasks = (
  executionId: string,
  signal?: AbortSignal
) => {
  return axiosMutator<GetExecutionsExecutionIdTasks200>({
    url: `/executions/${executionId}/tasks`,
    method: 'get',
    signal,
  })
}

export const getGetExecutionsExecutionIdTasksQueryKey = (
  executionId: string
) => [`/executions/${executionId}/tasks`]

export type GetExecutionsExecutionIdTasksInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>
>
export type GetExecutionsExecutionIdTasksInfiniteQueryError = ErrorType<unknown>

export const useGetExecutionsExecutionIdTasksInfinite = <
  TData = Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>,
  TError = ErrorType<unknown>
>(
  executionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetExecutionsExecutionIdTasksQueryKey(executionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>
  > = ({ signal }) => getExecutionsExecutionIdTasks(executionId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!executionId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetExecutionsExecutionIdTasksQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>
>
export type GetExecutionsExecutionIdTasksQueryError = ErrorType<unknown>

export const useGetExecutionsExecutionIdTasks = <
  TData = Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>,
  TError = ErrorType<unknown>
>(
  executionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetExecutionsExecutionIdTasksQueryKey(executionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>
  > = ({ signal }) => getExecutionsExecutionIdTasks(executionId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getExecutionsExecutionIdTasks>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!executionId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Get a task
 */
export const getExecutionsExecutionIdTasksTaskId = (
  executionId: string,
  taskId: string,
  signal?: AbortSignal
) => {
  return axiosMutator<GetExecutionsExecutionIdTasksTaskId200>({
    url: `/executions/${executionId}/tasks/${taskId}`,
    method: 'get',
    signal,
  })
}

export const getGetExecutionsExecutionIdTasksTaskIdQueryKey = (
  executionId: string,
  taskId: string
) => [`/executions/${executionId}/tasks/${taskId}`]

export type GetExecutionsExecutionIdTasksTaskIdInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>>
export type GetExecutionsExecutionIdTasksTaskIdInfiniteQueryError =
  ErrorType<unknown>

export const useGetExecutionsExecutionIdTasksTaskIdInfinite = <
  TData = Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>,
  TError = ErrorType<unknown>
>(
  executionId: string,
  taskId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetExecutionsExecutionIdTasksTaskIdQueryKey(executionId, taskId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>
  > = ({ signal }) =>
    getExecutionsExecutionIdTasksTaskId(executionId, taskId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(executionId && taskId),
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetExecutionsExecutionIdTasksTaskIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>
>
export type GetExecutionsExecutionIdTasksTaskIdQueryError = ErrorType<unknown>

export const useGetExecutionsExecutionIdTasksTaskId = <
  TData = Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>,
  TError = ErrorType<unknown>
>(
  executionId: string,
  taskId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetExecutionsExecutionIdTasksTaskIdQueryKey(executionId, taskId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>
  > = ({ signal }) =>
    getExecutionsExecutionIdTasksTaskId(executionId, taskId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getExecutionsExecutionIdTasksTaskId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(executionId && taskId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieve the number of executions for the tenant of the currently logged in user.
 * @summary Get the number of executions
 */
export const getExecutionsCount = (
  params?: GetExecutionsCountParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetExecutionsCount200>({
    url: `/executions/count`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetExecutionsCountQueryKey = (
  params?: GetExecutionsCountParams
) => [`/executions/count`, ...(params ? [params] : [])]

export type GetExecutionsCountInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutionsCount>>
>
export type GetExecutionsCountInfiniteQueryError = ErrorType<unknown>

export const useGetExecutionsCountInfinite = <
  TData = Awaited<ReturnType<typeof getExecutionsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetExecutionsCountParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getExecutionsCount>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetExecutionsCountQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsCount>>
  > = ({ signal, pageParam }) =>
    getExecutionsCount({ nextToken: pageParam, ...params }, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getExecutionsCount>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetExecutionsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExecutionsCount>>
>
export type GetExecutionsCountQueryError = ErrorType<unknown>

export const useGetExecutionsCount = <
  TData = Awaited<ReturnType<typeof getExecutionsCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetExecutionsCountParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExecutionsCount>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetExecutionsCountQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExecutionsCount>>
  > = ({ signal }) => getExecutionsCount(params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getExecutionsCount>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Generates a presigned url to upload a file directly to S3
 */
export const postFiles = (diceApiFileUpload: DiceApiFileUpload) => {
  return axiosMutator<PostFiles200>({
    url: `/files`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiFileUpload,
  })
}

export type PostFilesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postFiles>>
>
export type PostFilesMutationBody = DiceApiFileUpload
export type PostFilesMutationError = ErrorType<unknown>

export const usePostFiles = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postFiles>>,
    TError,
    { data: DiceApiFileUpload },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postFiles>>,
    { data: DiceApiFileUpload }
  > = (props) => {
    const { data } = props ?? {}

    return postFiles(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postFiles>>,
    TError,
    { data: DiceApiFileUpload },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a presigned url to download a file directly from S3
 * @summary Generates a presigned url to download a file directly from S3
 */
export const getFilesFileKey = (
  fileKey: string,
  params: GetFilesFileKeyParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetFilesFileKey200>({
    url: `/files/${fileKey}`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetFilesFileKeyQueryKey = (
  fileKey: string,
  params: GetFilesFileKeyParams
) => [`/files/${fileKey}`, ...(params ? [params] : [])]

export type GetFilesFileKeyInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFilesFileKey>>
>
export type GetFilesFileKeyInfiniteQueryError = ErrorType<unknown>

export const useGetFilesFileKeyInfinite = <
  TData = Awaited<ReturnType<typeof getFilesFileKey>>,
  TError = ErrorType<unknown>
>(
  fileKey: string,
  params: GetFilesFileKeyParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getFilesFileKey>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFilesFileKeyQueryKey(fileKey, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFilesFileKey>>> = ({
    signal,
    pageParam,
  }) => getFilesFileKey(fileKey, { nextToken: pageParam, ...params }, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getFilesFileKey>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!fileKey,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetFilesFileKeyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFilesFileKey>>
>
export type GetFilesFileKeyQueryError = ErrorType<unknown>

export const useGetFilesFileKey = <
  TData = Awaited<ReturnType<typeof getFilesFileKey>>,
  TError = ErrorType<unknown>
>(
  fileKey: string,
  params: GetFilesFileKeyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFilesFileKey>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFilesFileKeyQueryKey(fileKey, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFilesFileKey>>> = ({
    signal,
  }) => getFilesFileKey(fileKey, params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getFilesFileKey>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!fileKey,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getLoginOptionsTenantAlias = (
  tenantAlias: string,
  signal?: AbortSignal
) => {
  return axiosMutator<GetLoginOptionsTenantAlias200>({
    url: `/loginOptions/${tenantAlias}`,
    method: 'get',
    signal,
  })
}

export const getGetLoginOptionsTenantAliasQueryKey = (tenantAlias: string) => [
  `/loginOptions/${tenantAlias}`,
]

export type GetLoginOptionsTenantAliasInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>
>
export type GetLoginOptionsTenantAliasInfiniteQueryError = ErrorType<unknown>

export const useGetLoginOptionsTenantAliasInfinite = <
  TData = Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>,
  TError = ErrorType<unknown>
>(
  tenantAlias: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetLoginOptionsTenantAliasQueryKey(tenantAlias)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>
  > = ({ signal }) => getLoginOptionsTenantAlias(tenantAlias, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!tenantAlias,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetLoginOptionsTenantAliasQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>
>
export type GetLoginOptionsTenantAliasQueryError = ErrorType<unknown>

export const useGetLoginOptionsTenantAlias = <
  TData = Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>,
  TError = ErrorType<unknown>
>(
  tenantAlias: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetLoginOptionsTenantAliasQueryKey(tenantAlias)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>
  > = ({ signal }) => getLoginOptionsTenantAlias(tenantAlias, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getLoginOptionsTenantAlias>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!tenantAlias,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Create a new signature
 */
export const postSignatures = (postSignaturesBody: PostSignaturesBody) => {
  return axiosMutator<PostSignatures201>({
    url: `/signatures`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: postSignaturesBody,
  })
}

export type PostSignaturesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSignatures>>
>
export type PostSignaturesMutationBody = PostSignaturesBody
export type PostSignaturesMutationError = ErrorType<
  PostSignatures403 | PostSignatures412
>

export const usePostSignatures = <
  TError = ErrorType<PostSignatures403 | PostSignatures412>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSignatures>>,
    TError,
    { data: PostSignaturesBody },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSignatures>>,
    { data: PostSignaturesBody }
  > = (props) => {
    const { data } = props ?? {}

    return postSignatures(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postSignatures>>,
    TError,
    { data: PostSignaturesBody },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get control execution stats filterable by search query.
 * @summary Get control execution stats
 */
export const getStats = (params: GetStatsParams, signal?: AbortSignal) => {
  return axiosMutator<GetStats200>({
    url: `/stats`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetStatsQueryKey = (params: GetStatsParams) => [
  `/stats`,
  ...(params ? [params] : []),
]

export type GetStatsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStats>>
>
export type GetStatsInfiniteQueryError = ErrorType<GetStats400>

export const useGetStatsInfinite = <
  TData = Awaited<ReturnType<typeof getStats>>,
  TError = ErrorType<GetStats400>
>(
  params: GetStatsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStats>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetStatsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStats>>> = ({
    signal,
    pageParam,
  }) => getStats({ nextToken: pageParam, ...params }, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStats>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStats>>
>
export type GetStatsQueryError = ErrorType<GetStats400>

export const useGetStats = <
  TData = Awaited<ReturnType<typeof getStats>>,
  TError = ErrorType<GetStats400>
>(
  params: GetStatsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getStats>>, TError, TData>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetStatsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStats>>> = ({
    signal,
  }) => getStats(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getStats>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieve information about all streams of the tenant of the currently logged in user.
 * @summary Get all stream infos
 */
export const getStreams = (signal?: AbortSignal) => {
  return axiosMutator<DiceApiStreamInfoRead[]>({
    url: `/streams`,
    method: 'get',
    signal,
  })
}

export const getGetStreamsQueryKey = () => [`/streams`]

export type GetStreamsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreams>>
>
export type GetStreamsInfiniteQueryError = ErrorType<unknown>

export const useGetStreamsInfinite = <
  TData = Awaited<ReturnType<typeof getStreams>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getStreams>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetStreamsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStreams>>> = ({
    signal,
  }) => getStreams(signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStreams>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetStreamsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreams>>
>
export type GetStreamsQueryError = ErrorType<unknown>

export const useGetStreams = <
  TData = Awaited<ReturnType<typeof getStreams>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getStreams>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetStreamsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStreams>>> = ({
    signal,
  }) => getStreams(signal)

  const query = useQuery<Awaited<ReturnType<typeof getStreams>>, TError, TData>(
    { queryKey, queryFn, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Create a new stream
 */
export const postStreams = (
  diceApiStreamInfoCreate: DiceApiStreamInfoCreate
) => {
  return axiosMutator<PostStreams200>({
    url: `/streams`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiStreamInfoCreate,
  })
}

export type PostStreamsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postStreams>>
>
export type PostStreamsMutationBody = DiceApiStreamInfoCreate
export type PostStreamsMutationError = ErrorType<unknown>

export const usePostStreams = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postStreams>>,
    TError,
    { data: DiceApiStreamInfoCreate },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postStreams>>,
    { data: DiceApiStreamInfoCreate }
  > = (props) => {
    const { data } = props ?? {}

    return postStreams(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postStreams>>,
    TError,
    { data: DiceApiStreamInfoCreate },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieve information about a stream identified by its `streamId`.
 * @summary Get a stream's info
 */
export const getStreamsStreamId = (streamId: string, signal?: AbortSignal) => {
  return axiosMutator<DiceApiStreamInfoRead>({
    url: `/streams/${streamId}`,
    method: 'get',
    signal,
  })
}

export const getGetStreamsStreamIdQueryKey = (streamId: string) => [
  `/streams/${streamId}`,
]

export type GetStreamsStreamIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreamsStreamId>>
>
export type GetStreamsStreamIdInfiniteQueryError = ErrorType<unknown>

export const useGetStreamsStreamIdInfinite = <
  TData = Awaited<ReturnType<typeof getStreamsStreamId>>,
  TError = ErrorType<unknown>
>(
  streamId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetStreamsStreamIdQueryKey(streamId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamId>>
  > = ({ signal }) => getStreamsStreamId(streamId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStreamsStreamId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!streamId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetStreamsStreamIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreamsStreamId>>
>
export type GetStreamsStreamIdQueryError = ErrorType<unknown>

export const useGetStreamsStreamId = <
  TData = Awaited<ReturnType<typeof getStreamsStreamId>>,
  TError = ErrorType<unknown>
>(
  streamId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetStreamsStreamIdQueryKey(streamId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamId>>
  > = ({ signal }) => getStreamsStreamId(streamId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getStreamsStreamId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!streamId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Update the information about a stream identified by its `streamId`.
 * @summary Update a stream's info
 */
export const patchStreamsStreamId = (
  streamId: string,
  diceApiStreamInfoPatch: DiceApiStreamInfoPatch
) => {
  return axiosMutator<DiceApiStreamInfoRead>({
    url: `/streams/${streamId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiStreamInfoPatch,
  })
}

export type PatchStreamsStreamIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchStreamsStreamId>>
>
export type PatchStreamsStreamIdMutationBody = DiceApiStreamInfoPatch
export type PatchStreamsStreamIdMutationError = ErrorType<unknown>

export const usePatchStreamsStreamId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchStreamsStreamId>>,
    TError,
    { streamId: string; data: DiceApiStreamInfoPatch },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchStreamsStreamId>>,
    { streamId: string; data: DiceApiStreamInfoPatch }
  > = (props) => {
    const { streamId, data } = props ?? {}

    return patchStreamsStreamId(streamId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchStreamsStreamId>>,
    TError,
    { streamId: string; data: DiceApiStreamInfoPatch },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Send new row data to a stream identified by its `streamId`. You can send one or more rows, each with its own primary key. To delete a row, set it to `null`.
 * @summary Write rows to a stream
 */
export const putStreamsStreamId = (
  streamId: string,
  putStreamsStreamIdBody: PutStreamsStreamIdBody
) => {
  return axiosMutator<DiceApiStreamTransaction>({
    url: `/streams/${streamId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: putStreamsStreamIdBody,
  })
}

export type PutStreamsStreamIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putStreamsStreamId>>
>
export type PutStreamsStreamIdMutationBody = PutStreamsStreamIdBody
export type PutStreamsStreamIdMutationError = ErrorType<void>

export const usePutStreamsStreamId = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putStreamsStreamId>>,
    TError,
    { streamId: string; data: PutStreamsStreamIdBody },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putStreamsStreamId>>,
    { streamId: string; data: PutStreamsStreamIdBody }
  > = (props) => {
    const { streamId, data } = props ?? {}

    return putStreamsStreamId(streamId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof putStreamsStreamId>>,
    TError,
    { streamId: string; data: PutStreamsStreamIdBody },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Create a new header authorization for a stream identified by its `streamId`.
 * @summary Create a header authorization
 */
export const createStreamAuthorizationHeader = (
  streamId: string,
  diceApiHeaderAuthInfoCreate: DiceApiHeaderAuthInfoCreate
) => {
  return axiosMutator<DiceApiHeaderAuthInfoRead>({
    url: `/streams/${streamId}/authorization/headers`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiHeaderAuthInfoCreate,
  })
}

export type CreateStreamAuthorizationHeaderMutationResult = NonNullable<
  Awaited<ReturnType<typeof createStreamAuthorizationHeader>>
>
export type CreateStreamAuthorizationHeaderMutationBody =
  DiceApiHeaderAuthInfoCreate
export type CreateStreamAuthorizationHeaderMutationError =
  ErrorType<CreateStreamAuthorizationHeader412>

export const useCreateStreamAuthorizationHeader = <
  TError = ErrorType<CreateStreamAuthorizationHeader412>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStreamAuthorizationHeader>>,
    TError,
    { streamId: string; data: DiceApiHeaderAuthInfoCreate },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createStreamAuthorizationHeader>>,
    { streamId: string; data: DiceApiHeaderAuthInfoCreate }
  > = (props) => {
    const { streamId, data } = props ?? {}

    return createStreamAuthorizationHeader(streamId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof createStreamAuthorizationHeader>>,
    TError,
    { streamId: string; data: DiceApiHeaderAuthInfoCreate },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Delete a header authorization identified by its `authId` for a stream identified by its `streamId`.
 * @summary Delete a header authorization
 */
export const deleteStreamAuthorizationHeader = (
  streamId: string,
  authId: string
) => {
  return axiosMutator<void>({
    url: `/streams/${streamId}/authorization/headers/${authId}`,
    method: 'delete',
  })
}

export type DeleteStreamAuthorizationHeaderMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteStreamAuthorizationHeader>>
>

export type DeleteStreamAuthorizationHeaderMutationError = ErrorType<
  ForbiddenResponse | NotFoundResponse
>

export const useDeleteStreamAuthorizationHeader = <
  TError = ErrorType<ForbiddenResponse | NotFoundResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteStreamAuthorizationHeader>>,
    TError,
    { streamId: string; authId: string },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteStreamAuthorizationHeader>>,
    { streamId: string; authId: string }
  > = (props) => {
    const { streamId, authId } = props ?? {}

    return deleteStreamAuthorizationHeader(streamId, authId)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteStreamAuthorizationHeader>>,
    TError,
    { streamId: string; authId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Update a header authorization identified by its `authId` for a stream identified by its `streamId`.
 * @summary Update a header authorization
 */
export const updateStreamAuthorizationHeader = (
  streamId: string,
  authId: string,
  diceApiHeaderAuthInfoPatch: DiceApiHeaderAuthInfoPatch
) => {
  return axiosMutator<void>({
    url: `/streams/${streamId}/authorization/headers/${authId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiHeaderAuthInfoPatch,
  })
}

export type UpdateStreamAuthorizationHeaderMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStreamAuthorizationHeader>>
>
export type UpdateStreamAuthorizationHeaderMutationBody =
  DiceApiHeaderAuthInfoPatch
export type UpdateStreamAuthorizationHeaderMutationError = ErrorType<unknown>

export const useUpdateStreamAuthorizationHeader = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStreamAuthorizationHeader>>,
    TError,
    { streamId: string; authId: string; data: DiceApiHeaderAuthInfoPatch },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStreamAuthorizationHeader>>,
    { streamId: string; authId: string; data: DiceApiHeaderAuthInfoPatch }
  > = (props) => {
    const { streamId, authId, data } = props ?? {}

    return updateStreamAuthorizationHeader(streamId, authId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof updateStreamAuthorizationHeader>>,
    TError,
    { streamId: string; authId: string; data: DiceApiHeaderAuthInfoPatch },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * @summary Create a grouping for a stream
 */
export const postStreamsStreamIdGroupings = (
  streamId: string,
  diceApiGroupingDefinition: DiceApiGroupingDefinition
) => {
  return axiosMutator<PostStreamsStreamIdGroupings200>({
    url: `/streams/${streamId}/groupings`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiGroupingDefinition,
  })
}

export type PostStreamsStreamIdGroupingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postStreamsStreamIdGroupings>>
>
export type PostStreamsStreamIdGroupingsMutationBody = DiceApiGroupingDefinition
export type PostStreamsStreamIdGroupingsMutationError =
  ErrorType<NotFoundResponse>

export const usePostStreamsStreamIdGroupings = <
  TError = ErrorType<NotFoundResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postStreamsStreamIdGroupings>>,
    TError,
    { streamId: string; data: DiceApiGroupingDefinition },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postStreamsStreamIdGroupings>>,
    { streamId: string; data: DiceApiGroupingDefinition }
  > = (props) => {
    const { streamId, data } = props ?? {}

    return postStreamsStreamIdGroupings(streamId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postStreamsStreamIdGroupings>>,
    TError,
    { streamId: string; data: DiceApiGroupingDefinition },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Manually invoke a scheduled stream
 */
export const manualInvokeScheduledStream = (streamId: string) => {
  return axiosMutator<void>({
    url: `/streams/${streamId}/manual-invoke`,
    method: 'post',
  })
}

export type ManualInvokeScheduledStreamMutationResult = NonNullable<
  Awaited<ReturnType<typeof manualInvokeScheduledStream>>
>

export type ManualInvokeScheduledStreamMutationError = ErrorType<
  NotFoundResponse | ManualInvokeScheduledStream412 | ServerErrorResponse
>

export const useManualInvokeScheduledStream = <
  TError = ErrorType<
    NotFoundResponse | ManualInvokeScheduledStream412 | ServerErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof manualInvokeScheduledStream>>,
    TError,
    { streamId: string },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof manualInvokeScheduledStream>>,
    { streamId: string }
  > = (props) => {
    const { streamId } = props ?? {}

    return manualInvokeScheduledStream(streamId)
  }

  return useMutation<
    Awaited<ReturnType<typeof manualInvokeScheduledStream>>,
    TError,
    { streamId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * @summary Get a stream row's remediation
 */
export const getStreamsStreamIdRemediationsRowId = (
  streamId: string,
  rowId: string,
  signal?: AbortSignal
) => {
  return axiosMutator<DiceApiRemediation>({
    url: `/streams/${streamId}/remediations/${rowId}`,
    method: 'get',
    signal,
  })
}

export const getGetStreamsStreamIdRemediationsRowIdQueryKey = (
  streamId: string,
  rowId: string
) => [`/streams/${streamId}/remediations/${rowId}`]

export type GetStreamsStreamIdRemediationsRowIdInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>>
export type GetStreamsStreamIdRemediationsRowIdInfiniteQueryError =
  ErrorType<unknown>

export const useGetStreamsStreamIdRemediationsRowIdInfinite = <
  TData = Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>,
  TError = ErrorType<unknown>
>(
  streamId: string,
  rowId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStreamsStreamIdRemediationsRowIdQueryKey(streamId, rowId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>
  > = ({ signal }) =>
    getStreamsStreamIdRemediationsRowId(streamId, rowId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(streamId && rowId),
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetStreamsStreamIdRemediationsRowIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>
>
export type GetStreamsStreamIdRemediationsRowIdQueryError = ErrorType<unknown>

export const useGetStreamsStreamIdRemediationsRowId = <
  TData = Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>,
  TError = ErrorType<unknown>
>(
  streamId: string,
  rowId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStreamsStreamIdRemediationsRowIdQueryKey(streamId, rowId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>
  > = ({ signal }) =>
    getStreamsStreamIdRemediationsRowId(streamId, rowId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getStreamsStreamIdRemediationsRowId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(streamId && rowId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Write a stream row's remediation
 */
export const postStreamsStreamIdRemediationsRowId = (
  streamId: string,
  rowId: string,
  diceApiCreateRemediation: DiceApiCreateRemediation
) => {
  return axiosMutator<void>({
    url: `/streams/${streamId}/remediations/${rowId}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiCreateRemediation,
  })
}

export type PostStreamsStreamIdRemediationsRowIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postStreamsStreamIdRemediationsRowId>>
>
export type PostStreamsStreamIdRemediationsRowIdMutationBody =
  DiceApiCreateRemediation
export type PostStreamsStreamIdRemediationsRowIdMutationError =
  ErrorType<PostStreamsStreamIdRemediationsRowId409>

export const usePostStreamsStreamIdRemediationsRowId = <
  TError = ErrorType<PostStreamsStreamIdRemediationsRowId409>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postStreamsStreamIdRemediationsRowId>>,
    TError,
    { streamId: string; rowId: string; data: DiceApiCreateRemediation },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postStreamsStreamIdRemediationsRowId>>,
    { streamId: string; rowId: string; data: DiceApiCreateRemediation }
  > = (props) => {
    const { streamId, rowId, data } = props ?? {}

    return postStreamsStreamIdRemediationsRowId(streamId, rowId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postStreamsStreamIdRemediationsRowId>>,
    TError,
    { streamId: string; rowId: string; data: DiceApiCreateRemediation },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * @summary Get a stream's rows
 */
export const getStreamsStreamIdRows = (
  streamId: string,
  params?: GetStreamsStreamIdRowsParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetStreamsStreamIdRows200>({
    url: `/streams/${streamId}/rows`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetStreamsStreamIdRowsQueryKey = (
  streamId: string,
  params?: GetStreamsStreamIdRowsParams
) => [`/streams/${streamId}/rows`, ...(params ? [params] : [])]

export type GetStreamsStreamIdRowsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreamsStreamIdRows>>
>
export type GetStreamsStreamIdRowsInfiniteQueryError =
  ErrorType<GetStreamsStreamIdRows400>

export const useGetStreamsStreamIdRowsInfinite = <
  TData = Awaited<ReturnType<typeof getStreamsStreamIdRows>>,
  TError = ErrorType<GetStreamsStreamIdRows400>
>(
  streamId: string,
  params?: GetStreamsStreamIdRowsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamIdRows>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStreamsStreamIdRowsQueryKey(streamId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamIdRows>>
  > = ({ signal, pageParam }) =>
    getStreamsStreamIdRows(
      streamId,
      { nextToken: pageParam, ...params },
      signal
    )

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStreamsStreamIdRows>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!streamId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetStreamsStreamIdRowsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreamsStreamIdRows>>
>
export type GetStreamsStreamIdRowsQueryError =
  ErrorType<GetStreamsStreamIdRows400>

export const useGetStreamsStreamIdRows = <
  TData = Awaited<ReturnType<typeof getStreamsStreamIdRows>>,
  TError = ErrorType<GetStreamsStreamIdRows400>
>(
  streamId: string,
  params?: GetStreamsStreamIdRowsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamIdRows>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStreamsStreamIdRowsQueryKey(streamId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamIdRows>>
  > = ({ signal }) => getStreamsStreamIdRows(streamId, params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getStreamsStreamIdRows>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!streamId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Get a stream row
 */
export const getStreamsStreamIdRowsRowId = (
  streamId: string,
  rowId: string,
  params?: GetStreamsStreamIdRowsRowIdParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetStreamsStreamIdRowsRowId200>({
    url: `/streams/${streamId}/rows/${rowId}`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetStreamsStreamIdRowsRowIdQueryKey = (
  streamId: string,
  rowId: string,
  params?: GetStreamsStreamIdRowsRowIdParams
) => [`/streams/${streamId}/rows/${rowId}`, ...(params ? [params] : [])]

export type GetStreamsStreamIdRowsRowIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>
>
export type GetStreamsStreamIdRowsRowIdInfiniteQueryError = ErrorType<unknown>

export const useGetStreamsStreamIdRowsRowIdInfinite = <
  TData = Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>,
  TError = ErrorType<unknown>
>(
  streamId: string,
  rowId: string,
  params?: GetStreamsStreamIdRowsRowIdParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStreamsStreamIdRowsRowIdQueryKey(streamId, rowId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>
  > = ({ signal, pageParam }) =>
    getStreamsStreamIdRowsRowId(
      streamId,
      rowId,
      { nextToken: pageParam, ...params },
      signal
    )

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(streamId && rowId),
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetStreamsStreamIdRowsRowIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>
>
export type GetStreamsStreamIdRowsRowIdQueryError = ErrorType<unknown>

export const useGetStreamsStreamIdRowsRowId = <
  TData = Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>,
  TError = ErrorType<unknown>
>(
  streamId: string,
  rowId: string,
  params?: GetStreamsStreamIdRowsRowIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStreamsStreamIdRowsRowIdQueryKey(streamId, rowId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>
  > = ({ signal }) =>
    getStreamsStreamIdRowsRowId(streamId, rowId, params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getStreamsStreamIdRowsRowId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(streamId && rowId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Get a stream row's history
 */
export const getStreamsStreamIdRowsRowIdHistory = (
  streamId: string,
  rowId: string,
  params?: GetStreamsStreamIdRowsRowIdHistoryParams,
  signal?: AbortSignal
) => {
  return axiosMutator<GetStreamsStreamIdRowsRowIdHistory200>({
    url: `/streams/${streamId}/rows/${rowId}/history`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetStreamsStreamIdRowsRowIdHistoryQueryKey = (
  streamId: string,
  rowId: string,
  params?: GetStreamsStreamIdRowsRowIdHistoryParams
) => [`/streams/${streamId}/rows/${rowId}/history`, ...(params ? [params] : [])]

export type GetStreamsStreamIdRowsRowIdHistoryInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>
>
export type GetStreamsStreamIdRowsRowIdHistoryInfiniteQueryError =
  ErrorType<unknown>

export const useGetStreamsStreamIdRowsRowIdHistoryInfinite = <
  TData = Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>,
  TError = ErrorType<unknown>
>(
  streamId: string,
  rowId: string,
  params?: GetStreamsStreamIdRowsRowIdHistoryParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStreamsStreamIdRowsRowIdHistoryQueryKey(streamId, rowId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>
  > = ({ signal, pageParam }) =>
    getStreamsStreamIdRowsRowIdHistory(
      streamId,
      rowId,
      { nextToken: pageParam, ...params },
      signal
    )

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(streamId && rowId),
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetStreamsStreamIdRowsRowIdHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>
>
export type GetStreamsStreamIdRowsRowIdHistoryQueryError = ErrorType<unknown>

export const useGetStreamsStreamIdRowsRowIdHistory = <
  TData = Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>,
  TError = ErrorType<unknown>
>(
  streamId: string,
  rowId: string,
  params?: GetStreamsStreamIdRowsRowIdHistoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStreamsStreamIdRowsRowIdHistoryQueryKey(streamId, rowId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>
  > = ({ signal }) =>
    getStreamsStreamIdRowsRowIdHistory(streamId, rowId, params, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getStreamsStreamIdRowsRowIdHistory>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(streamId && rowId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieve all tasks for the current user, optionally filtered by status.
 * @summary Get all tasks
 */
export const getTasks = (params?: GetTasksParams, signal?: AbortSignal) => {
  return axiosMutator<GetTasks200>({
    url: `/tasks`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetTasksQueryKey = (params?: GetTasksParams) => [
  `/tasks`,
  ...(params ? [params] : []),
]

export type GetTasksInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTasks>>
>
export type GetTasksInfiniteQueryError = ErrorType<unknown>

export const useGetTasksInfinite = <
  TData = Awaited<ReturnType<typeof getTasks>>,
  TError = ErrorType<unknown>
>(
  params?: GetTasksParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getTasks>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTasksQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTasks>>> = ({
    signal,
    pageParam,
  }) => getTasks({ nextToken: pageParam, ...params }, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getTasks>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetTasksQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTasks>>
>
export type GetTasksQueryError = ErrorType<unknown>

export const useGetTasks = <
  TData = Awaited<ReturnType<typeof getTasks>>,
  TError = ErrorType<unknown>
>(
  params?: GetTasksParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTasks>>, TError, TData>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTasksQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTasks>>> = ({
    signal,
  }) => getTasks(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTasks>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getTenantsTenantId = (tenantId: string, signal?: AbortSignal) => {
  return axiosMutator<DiceApiTenantInfo>({
    url: `/tenants/${tenantId}`,
    method: 'get',
    signal,
  })
}

export const getGetTenantsTenantIdQueryKey = (tenantId: string) => [
  `/tenants/${tenantId}`,
]

export type GetTenantsTenantIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTenantsTenantId>>
>
export type GetTenantsTenantIdInfiniteQueryError = ErrorType<unknown>

export const useGetTenantsTenantIdInfinite = <
  TData = Awaited<ReturnType<typeof getTenantsTenantId>>,
  TError = ErrorType<unknown>
>(
  tenantId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getTenantsTenantId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetTenantsTenantIdQueryKey(tenantId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTenantsTenantId>>
  > = ({ signal }) => getTenantsTenantId(tenantId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getTenantsTenantId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!tenantId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetTenantsTenantIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTenantsTenantId>>
>
export type GetTenantsTenantIdQueryError = ErrorType<unknown>

export const useGetTenantsTenantId = <
  TData = Awaited<ReturnType<typeof getTenantsTenantId>>,
  TError = ErrorType<unknown>
>(
  tenantId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTenantsTenantId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetTenantsTenantIdQueryKey(tenantId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTenantsTenantId>>
  > = ({ signal }) => getTenantsTenantId(tenantId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getTenantsTenantId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!tenantId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getUsers = (signal?: AbortSignal) => {
  return axiosMutator<GetUsers200Item[]>({
    url: `/users`,
    method: 'get',
    signal,
  })
}

export const getGetUsersQueryKey = () => [`/users`]

export type GetUsersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>
export type GetUsersInfiniteQueryError = ErrorType<unknown>

export const useGetUsersInfinite = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getUsers>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({
    signal,
  }) => getUsers(signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getUsers>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>
export type GetUsersQueryError = ErrorType<unknown>

export const useGetUsers = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({
    signal,
  }) => getUsers(signal)

  const query = useQuery<Awaited<ReturnType<typeof getUsers>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const postUsers = (diceApiCreateUserInfo: DiceApiCreateUserInfo) => {
  return axiosMutator<PostUsers200>({
    url: `/users`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiCreateUserInfo,
  })
}

export type PostUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUsers>>
>
export type PostUsersMutationBody = DiceApiCreateUserInfo
export type PostUsersMutationError = ErrorType<PostUsers412>

export const usePostUsers = <
  TError = ErrorType<PostUsers412>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUsers>>,
    TError,
    { data: DiceApiCreateUserInfo },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUsers>>,
    { data: DiceApiCreateUserInfo }
  > = (props) => {
    const { data } = props ?? {}

    return postUsers(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postUsers>>,
    TError,
    { data: DiceApiCreateUserInfo },
    TContext
  >(mutationFn, mutationOptions)
}

export const deleteUsersUserId = (userId: string) => {
  return axiosMutator<void>({ url: `/users/${userId}`, method: 'delete' })
}

export type DeleteUsersUserIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUsersUserId>>
>

export type DeleteUsersUserIdMutationError = ErrorType<unknown>

export const useDeleteUsersUserId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUsersUserId>>,
    TError,
    { userId: string },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUsersUserId>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {}

    return deleteUsersUserId(userId)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteUsersUserId>>,
    TError,
    { userId: string },
    TContext
  >(mutationFn, mutationOptions)
}

export const getUsersUserId = (userId: string, signal?: AbortSignal) => {
  return axiosMutator<GetUsersUserId200>({
    url: `/users/${userId}`,
    method: 'get',
    signal,
  })
}

export const getGetUsersUserIdQueryKey = (userId: string) => [
  `/users/${userId}`,
]

export type GetUsersUserIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersUserId>>
>
export type GetUsersUserIdInfiniteQueryError = ErrorType<unknown>

export const useGetUsersUserIdInfinite = <
  TData = Awaited<ReturnType<typeof getUsersUserId>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getUsersUserId>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUsersUserIdQueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserId>>> = ({
    signal,
  }) => getUsersUserId(userId, signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getUsersUserId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetUsersUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsersUserId>>
>
export type GetUsersUserIdQueryError = ErrorType<unknown>

export const useGetUsersUserId = <
  TData = Awaited<ReturnType<typeof getUsersUserId>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsersUserId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUsersUserIdQueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserId>>> = ({
    signal,
  }) => getUsersUserId(userId, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getUsersUserId>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Update a user's information. Only the fields that are specified will be updated. Depending on your permissions, you may not be able to update all fields.
 * @summary Update a user's information.
 */
export const patchUsersUserId = (
  userId: string,
  diceApiPatchUserInfo: DiceApiPatchUserInfo
) => {
  return axiosMutator<void>({
    url: `/users/${userId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: diceApiPatchUserInfo,
  })
}

export type PatchUsersUserIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUsersUserId>>
>
export type PatchUsersUserIdMutationBody = DiceApiPatchUserInfo
export type PatchUsersUserIdMutationError = ErrorType<
  ForbiddenResponse | ServerErrorResponse
>

export const usePatchUsersUserId = <
  TError = ErrorType<ForbiddenResponse | ServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUsersUserId>>,
    TError,
    { userId: string; data: DiceApiPatchUserInfo },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUsersUserId>>,
    { userId: string; data: DiceApiPatchUserInfo }
  > = (props) => {
    const { userId, data } = props ?? {}

    return patchUsersUserId(userId, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchUsersUserId>>,
    TError,
    { userId: string; data: DiceApiPatchUserInfo },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Enable a user
 * @summary Enable a currently disabled user and set the default access role `No Access`. If the user is already enabled, this operation will have no effect.
 */
export const postUsersUserIdEnable = (userId: string) => {
  return axiosMutator<void>({ url: `/users/${userId}/enable`, method: 'post' })
}

export type PostUsersUserIdEnableMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUsersUserIdEnable>>
>

export type PostUsersUserIdEnableMutationError = ErrorType<
  ForbiddenResponse | PostUsersUserIdEnable412 | ServerErrorResponse
>

export const usePostUsersUserIdEnable = <
  TError = ErrorType<
    ForbiddenResponse | PostUsersUserIdEnable412 | ServerErrorResponse
  >,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUsersUserIdEnable>>,
    TError,
    { userId: string },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUsersUserIdEnable>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {}

    return postUsersUserIdEnable(userId)
  }

  return useMutation<
    Awaited<ReturnType<typeof postUsersUserIdEnable>>,
    TError,
    { userId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Resend the user's signup email.
 */
export const postUsersUserIdResendSignup = (userId: string) => {
  return axiosMutator<void>({
    url: `/users/${userId}/resend-signup`,
    method: 'post',
  })
}

export type PostUsersUserIdResendSignupMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUsersUserIdResendSignup>>
>

export type PostUsersUserIdResendSignupMutationError =
  ErrorType<PostUsersUserIdResendSignup412>

export const usePostUsersUserIdResendSignup = <
  TError = ErrorType<PostUsersUserIdResendSignup412>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUsersUserIdResendSignup>>,
    TError,
    { userId: string },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUsersUserIdResendSignup>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {}

    return postUsersUserIdResendSignup(userId)
  }

  return useMutation<
    Awaited<ReturnType<typeof postUsersUserIdResendSignup>>,
    TError,
    { userId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Resets the user's password and sends an email with new credentials.
 */
export const postUsersUserIdResetPassword = (userId: string) => {
  return axiosMutator<void>({
    url: `/users/${userId}/reset-password`,
    method: 'post',
  })
}

export type PostUsersUserIdResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUsersUserIdResetPassword>>
>

export type PostUsersUserIdResetPasswordMutationError =
  ErrorType<PostUsersUserIdResetPassword412>

export const usePostUsersUserIdResetPassword = <
  TError = ErrorType<PostUsersUserIdResetPassword412>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUsersUserIdResetPassword>>,
    TError,
    { userId: string },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUsersUserIdResetPassword>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {}

    return postUsersUserIdResetPassword(userId)
  }

  return useMutation<
    Awaited<ReturnType<typeof postUsersUserIdResetPassword>>,
    TError,
    { userId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Returns a list of all blocks available in the system.
 */
export const getWorkflowsBlocks = (signal?: AbortSignal) => {
  return axiosMutator<GetWorkflowsBlocks200>({
    url: `/workflows/blocks`,
    method: 'get',
    signal,
  })
}

export const getGetWorkflowsBlocksQueryKey = () => [`/workflows/blocks`]

export type GetWorkflowsBlocksInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWorkflowsBlocks>>
>
export type GetWorkflowsBlocksInfiniteQueryError = ErrorType<unknown>

export const useGetWorkflowsBlocksInfinite = <
  TData = Awaited<ReturnType<typeof getWorkflowsBlocks>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getWorkflowsBlocks>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowsBlocksQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getWorkflowsBlocks>>
  > = ({ signal }) => getWorkflowsBlocks(signal)

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getWorkflowsBlocks>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetWorkflowsBlocksQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWorkflowsBlocks>>
>
export type GetWorkflowsBlocksQueryError = ErrorType<unknown>

export const useGetWorkflowsBlocks = <
  TData = Awaited<ReturnType<typeof getWorkflowsBlocks>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getWorkflowsBlocks>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowsBlocksQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getWorkflowsBlocks>>
  > = ({ signal }) => getWorkflowsBlocks(signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getWorkflowsBlocks>>,
    TError,
    TData
  >({ queryKey, queryFn, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}
