import { Button } from '@chakra-ui/react'
import { useGetUsersUserId } from '@haesh/dice-api'
import { useUser } from '@haesh/react-use-dice'

const UserNoAccessScreen = () => {
  const { signOut, userAttributes } = useUser()

  useGetUsersUserId(userAttributes.sub, {
    query: {
      refetchInterval: 3_000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: 'always',
    },
  })

  return (
    <main className='flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
      <div className='py-16'>
        <div className='text-center'>
          <h2 className='mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            You do not have access to this application.
          </h2>
          <p className='mt-2 text-base text-gray-500'>
            Please contact an administrator to be assigned a role.
          </p>
          <div className='mt-6'>
            <Button
              colorScheme='brand'
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={signOut}
              size='lg'
              variant='ghost'
            >
              Sign out
            </Button>
          </div>
        </div>
      </div>
    </main>
  )
}

export default UserNoAccessScreen
