import { Box } from '@chakra-ui/react'
import React from 'react'

type BlankCardProps = {
  children: React.ReactNode
  className?: string
  padding?: number
}

const BlankCard = ({ children, className, padding = 6 }: BlankCardProps) => {
  return (
    <Box
      borderWidth='1px'
      className={`shadow-sm ${className ?? ''} bg-white`}
      p={padding}
      rounded='md'
    >
      {children}
    </Box>
  )
}

export default BlankCard
