import { getStageFromWindow } from './utils'
import { getEnvironment, resolveFqdn } from '@haesh/constants'
import { axiosInstance } from '@haesh/dice-api'

const stage = getStageFromWindow()
export const baseURL = `https://${
  resolveFqdn(getEnvironment(stage)).apiSubdomain
}`

// use axiosInstance from @haesh/dice-api and set baseURL
axiosInstance.defaults.baseURL = baseURL
