import { servers } from '../definition/openapi.json'

class MapWithDefault<K, V> extends Map<K, V> {
  private readonly getDefaultValue

  public constructor(
    getDefaultValue: (unknownKey: K) => V,
    entries?: ReadonlyArray<readonly [K, V]> | null
  ) {
    super(entries)
    this.getDefaultValue = getDefaultValue
  }

  public get(key: K) {
    return super.get(key) ?? this.getDefaultValue(key)
  }
}

/**
 * A map of stage names to their respective config.
 *
 * If the stage name is specified in the OpenAPI definition, the config is taken from there.
 * Otherwise, a reasonable default is generated based on the stage name.
 */
export const stages = new MapWithDefault(
  (unknownStage: string) => ({
    apiUrl: new URL(`https://api.dice.${unknownStage}.playground.hae.sh/`),
  }),
  servers.map(server => [
    server['x-haesh-stage'],
    { apiUrl: new URL(server.url) },
  ])
)
