/**
 * Delays the execution of an async function for a given amount of time.
 *
 * @param ms the amount of time in milliseconds
 * @returns a promise that resolves after the given amount of time
 */
export const sleep = async (ms: number) =>
  await new Promise<void>(resolve => {
    setTimeout(resolve, ms)
  })
