import {
  type DiceApiCreateControl,
  type DiceApiWorkflowDefinition,
  type DiceApiBlockImplementation,
  type DiceApiSignatureBlock,
  type DiceApiTask,
  type DiceApiTaskAction,
  type DiceApiTaskSigner,
} from '@haesh/dice-api'
import { type Task } from 'asl-types'
import { type O } from 'ts-toolbelt'
import { type JsonataCondition } from './data'
import { type S3Location } from './server'

export enum InfrastructureBlocks {
  SET_EXECUTION_STATUS_FINISHED = 'Infra_Set Execution Finished',
  SET_EXECUTION_STATUS_RUNNING = 'Infra_Set Execution Running',
  WRITE_EXECUTION_ERROR = 'Infra_Write Execution Failure',
  WRITE_EXECUTION_RESULT = 'Infra_Write Execution Result',
}

export const isJsonataConditionBlock = (
  block: DiceApiBlockImplementation
): block is JsonataConditionBlock => block.blockId === 'JSONATA_CONDITION'
export const isSignatureBlock = (
  block: DiceApiBlockImplementation
): block is DiceApiSignatureBlock => block.blockId === 'SIGNATURE_REQUIRED'

export type BlockStatesImplementation = {
  statesDefinition: Task
}

// props when used in the designer
export type BlockImplementation = {
  blockId: string
  next?: string | [string]
  parameters?: unknown
  uniqueName: string
}

export type DiceApiControl<isWorkflow extends boolean = false> = O.Overwrite<
  DiceApiCreateControl,
  {
    controlDefinition: isWorkflow extends true
      ? { workflowDefinition: DiceApiWorkflowDefinition }
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { simpleDefinition: any[] }
  }
>

/**
 * @deprecated use {@link DiceApiSignatureBlock} instead
 */
export type SignatureBlock = BlockImplementation & {
  blockId: 'SIGNATURE_REQUIRED'
  next?: string
  parameters: {
    inputs: {
      actions: DiceApiTaskAction[]
      displayData: DiceApiTask['displayData']
      expiration?: string
      groupIds: string[]
      requiredApprovals: number
      subtitle: string
      title: string
      type: 'TIMED_VOTE' | 'X_APPROVALS_NO_REJECTS' | 'X_APPROVALS'
      userIds: string[]
    }
    outputFormat: {
      approvers: DiceApiTaskSigner[]
      outcome: 'APPROVED' | 'EXPIRED' | 'REJECTED'
      rejectors: DiceApiTaskSigner[]
    }
  }
}

export type JsonataConditionBlock = Omit<BlockImplementation, 'next'> & {
  blockId: 'JSONATA_CONDITION'
  parameters: {
    inputs: {
      conditions: Array<Omit<JsonataCondition, 'outcome'> & { outcome: string }>
      default?: string
    }
    outputFormat: {
      default: boolean
      outcome: string
    }
  }
}

export type SendEmailTemplatedBlock = BlockImplementation & {
  blockId: 'SEND_TEMPLATED_EMAIL'
  next?: string
  parameters: {
    inputs: {
      destination: {
        BccAddresses: []
        CcAddresses: []
        ToAddresses: []
      }
      templateData: {
        content: string
        headline: string
      }
    }
    outputFormat: {}
  }
}

export type DiceControlDefinition = {
  [uniqueName: string]: BlockImplementation & unknown
}

export type StateMachineInput = {
  executionMetadata: {
    controlId: string
    executionId: string
    tenantId: string
  }
} & { remoteLocation?: S3Location }
// | { row?: Omit<StreamRowInfo, 'bytes'>; rowPayload: Record<string, unknown> }
