import { handleReload, messages } from './reloader'
import { type FallbackRender } from '@sentry/react'

/**
 * This very basic fallback page will only be shown
 * if the error is not handled further down the component tree.
 */
export const RootFallbackPage: FallbackRender = () => {
  /* eslint-disable react/forbid-elements */
  return (
    <div className='flex flex-col items-center justify-center h-[100vh]'>
      <p>{messages.reloadText}</p>
      <button
        className='px-4 py-2 mt-4 font-bold text-white rounded bg-primary'
        onClick={handleReload}
        type='button'
      >
        {messages.reloadButtonLabel}
      </button>
    </div>
  )
  /* eslint-enable react/forbid-elements */
}
