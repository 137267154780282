import { HarRequestShape } from './data'
import {
  type DiceApiControlEffectEmailCreate,
  DiceApiControlEffectEmailCreateEffectType,
  type DiceApiControlEffectEmailPatch,
  type DiceApiControlEffectWebhookCreate,
  DiceApiControlEffectWebhookCreateEffectType,
  type DiceApiControlEffectWebhookPatch,
  type DiceApiEmailRecipient,
  type DiceApiEmailRecipientList,
} from '@haesh/dice-api'
import * as yup from 'yup'

const EmailRecipientShape = yup.lazy((value: unknown) => {
  if (value !== null && typeof value === 'object' && 'email' in value) {
    return yup.object({
      email: yup.string().email().required(),
    })
  }

  if (value !== null && typeof value === 'object' && 'userId' in value) {
    return yup.object({
      userId: yup.string().required(),
    })
  }

  return yup.object({
    email: yup.string().email().required('Email is required'),
    userId: yup.string().required('User is required'),
  })
}) as unknown as yup.ObjectSchema<DiceApiEmailRecipient>

const EmailRecipientsListShape: yup.ObjectSchema<DiceApiEmailRecipientList> =
  yup.object({
    bccRecipients: yup.array(EmailRecipientShape).required(),
    ccRecipients: yup.array(EmailRecipientShape).required(),
    toRecipients: yup
      .array(EmailRecipientShape)
      .required()
      .min(1, 'At least one recipient is required'),
  })

const ControlEffectTriggerConditionShape = yup
  .object({
    error: yup.boolean().defined(),
    fail: yup.boolean().defined(),
    pass: yup.boolean().defined(),
  })
  .noUnknown(true)
  .test(
    'at-least-one-true-in-effects-when',
    'At least one condition must be true',
    value => value === undefined || value.error || value.fail || value.pass
  )

export const ControlEffectEmailCreateShape: yup.ObjectSchema<DiceApiControlEffectEmailCreate> =
  yup
    .object({
      effectType: yup
        .string()
        .required()
        .oneOf<DiceApiControlEffectEmailCreateEffectType>([
          DiceApiControlEffectEmailCreateEffectType.email,
        ]),
      recipients: EmailRecipientsListShape.required(),
      when: ControlEffectTriggerConditionShape.required(),
    })
    .noUnknown(true)
    .strict()
    .defined()

export const ControlEffectEmailPatchShape: yup.ObjectSchema<DiceApiControlEffectEmailPatch> =
  yup
    .object({
      recipients: EmailRecipientsListShape.optional().nonNullable(),
      when: ControlEffectTriggerConditionShape.optional().nonNullable(),
    })
    .noUnknown(true)
    .strict()
    .defined()

export const ControlEffectWebhookCreateShape: yup.ObjectSchema<DiceApiControlEffectWebhookCreate> =
  yup
    .object({
      effectType: yup
        .string()
        .required()
        .oneOf<DiceApiControlEffectWebhookCreateEffectType>([
          DiceApiControlEffectWebhookCreateEffectType.webhook,
        ]),
      harRequest: HarRequestShape,
      when: ControlEffectTriggerConditionShape.required(),
    })
    .noUnknown(true)
    .strict()
    .defined()

export const ControlEffectWebhookPatchShape: yup.ObjectSchema<DiceApiControlEffectWebhookPatch> =
  yup
    .object({
      harRequest: HarRequestShape.optional().nonNullable(),
      when: ControlEffectTriggerConditionShape.optional().nonNullable(),
    })
    .noUnknown(true)
    .strict()
    .defined()

export const BackendCreateControlEffectShape = yup.lazy(value => {
  const effectType = value?.effectType as
    | DiceApiControlEffectEmailCreateEffectType
    | DiceApiControlEffectWebhookCreateEffectType
    | undefined

  if (effectType === 'email') {
    return ControlEffectEmailCreateShape
  }

  if (effectType === 'webhook') {
    return ControlEffectWebhookCreateShape
  }

  return yup.object({
    effectType: yup
      .string()
      .oneOf<
        | DiceApiControlEffectEmailCreateEffectType
        | DiceApiControlEffectWebhookCreateEffectType
      >(['email', 'webhook'])
      .required(),
  })
})

export const BackendUpdateControlEffectShape = yup.lazy((value: unknown) => {
  if (value === null || typeof value !== 'object') {
    return yup.object().required()
  }

  if ('recipients' in value) {
    return ControlEffectEmailPatchShape
  }

  return ControlEffectWebhookPatchShape
})
