import { type DiceApiAccessRole } from '@haesh/dice-api'
import { type DT } from '.'

export enum SubscriptionTier {
  DEMO = 'demo',
  POC = 'poc',
}

export type Tenant = DT & {
  alias: string
  billingInfo: BillingInfo
  displayName: string
  isActive: boolean
  loginOptions: LoginOptions
  subscriptionTier: string
  tenantId: string
}

export type BillingInfo = {
  address: PostalAddress
  email: string
  paymentMethod?: PaymentMethod
  vatId?: string
}

export type PostalAddress = {
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  contactName?: string
  countryCode: string
  locality: string
  organizationName: string
  postalCode: string
  region: string
}

export type PaymentMethod = {
  id: string
  // TODO: Fields tbd
}

export type LoginOptions = {
  appClientId: {
    mobile: string
    web: string
  }
  identityProviders: IdentityProvider[]
  ssoDomain?: string
  userPoolId: string
  userPoolRegion: string
  usernamePasswordIsEnabled: boolean
}

type IdentityProvider = {
  displayName: string
  isEnabled: boolean
  providerName: string
}

export type User = DT & {
  accessRole?: DiceApiAccessRole
  // haesh internal JWT scopes "admin"
  businessRoles?: string[]
  cognitoUsername: string
  email: string
  // "c-level"
  isFederated: boolean
  // indicates wheter the user is coming from a public sso provider and is not confirmed yet
  isWaiting?: boolean
  profile: {
    // all optional
    // see https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
    familyName?: string
    givenName?: string
    picture?: string
    // …
  }
  // = cognito sub
  tenantId: string
  userId: string
}
