import { type AxiosRequestConfig, type AxiosError } from 'axios'
import { axiosInstance } from '../api/axiosInstance'
import { patchAxiosConfig } from './patchAxiosConfig'

export const axiosMutator = async <T>(
  config: AxiosRequestConfig
): Promise<T> => {
  // apply specific overrides to the axios config
  const patchedConfig = patchAxiosConfig(config)

  const { data } = await axiosInstance({
    ...patchedConfig,
  })

  return data
}

/**
 * Used by orval to correctly type the axios errors
 */
export type ErrorType<Error> = AxiosError<Error> & {}
