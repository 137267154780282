import { EventItem } from './EventItem'
import { Text } from '@chakra-ui/react'
import { InfiniteList } from '@components/infiniteList/InfiniteList'
import { type DiceApiEvent, useGetEventsInfinite } from '@haesh/dice-api'

const keyExtractor = ({ id }: DiceApiEvent) => id

const EventListFiltered = ({ filter }: { filter: unknown }) => {
  const query = btoa(JSON.stringify(filter))
  const eventsInfinite = useGetEventsInfinite(
    { query },
    { query: { getNextPageParam: lastPage => lastPage.nextToken } }
  )

  return (
    <InfiniteList
      ListEmptyComponent={<Text className='text-center'>No events found.</Text>}
      infiniteQueryResult={eventsInfinite}
      itemsExtractor={page => page.events}
      keyExtractor={keyExtractor}
      renderItem={({ item }) => <EventItem event={item} />}
    />
  )
}

export default EventListFiltered
