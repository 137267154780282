/* eslint-disable import/no-unassigned-import */
import 'react-date-range/dist/styles.css'
import './timeRangePicker.css'
import {
  Button,
  IconButton,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { XIcon } from '@heroicons/react/solid'
import { defaultRange, type Range, useSearchRange } from '@hooks/useSearchRange'
import {
  endOfDay,
  endOfQuarter,
  endOfYear,
  startOfQuarter,
  startOfYear,
  sub,
} from 'date-fns'
import { useCallback } from 'react'
import {
  createStaticRanges,
  DateRangePicker,
  defaultStaticRanges,
  type RangeKeyDict,
} from 'react-date-range'
import { FormattedDate, FormattedDateTimeRange } from 'react-intl'

const now = new Date()
const endOfToday = endOfDay(now)

const staticRanges = [
  ...defaultStaticRanges,
  ...createStaticRanges([
    // @ts-expect-error wrong type in package react-date-range
    {
      label: 'This Quarter',
      range: () => ({
        endDate: endOfQuarter(now),
        startDate: startOfQuarter(now),
      }),
    },
    // @ts-expect-error wrong type in package react-date-range
    {
      label: 'Last Quarter',
      range: () => ({
        endDate: endOfQuarter(sub(now, { months: 3 })),
        startDate: startOfQuarter(sub(now, { months: 3 })),
      }),
    },
    // @ts-expect-error wrong type in package react-date-range
    {
      label: 'This Year',
      range: () => ({
        endDate: endOfYear(now),
        startDate: startOfYear(now),
      }),
    },
    // @ts-expect-error wrong type in package react-date-range
    {
      label: 'Last Year',
      range: () => ({
        endDate: endOfYear(sub(now, { years: 1 })),
        startDate: startOfYear(sub(now, { years: 1 })),
      }),
    },
  ]),
].map(staticRange => {
  // we want to truncate the range to the current day max
  // in order to compute reasonable values for the stats
  const computedRange = staticRange.range()
  const truncatedRange = () => ({
    ...computedRange,
    // minimum of endDate and endOfToday
    endDate:
      (computedRange.endDate ?? Number.POSITIVE_INFINITY) < endOfToday
        ? computedRange.endDate
        : endOfToday,
  })
  return { ...staticRange, range: truncatedRange }
})

const minDate = startOfYear(new Date('2022-01-02'))
const maxDate = endOfToday

export const TimeRangePicker = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  const [range, setRange] = useSearchRange()

  const handleDateChange = useCallback(
    (rangesByKey: RangeKeyDict) => {
      // only take the first range, we don't support multiple ranges
      const newRange = {
        endDate: maxDate,
        startDate: minDate,
        ...rangesByKey['range1'],
      }

      // react-date-range always takes the start of the day
      newRange.endDate = endOfDay(newRange.endDate)

      setRange(newRange)
    },
    [setRange]
  )

  const handleReset = useCallback(() => {
    setRange(defaultRange)
  }, [setRange])

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement='bottom-end'
    >
      <PopoverTrigger>
        <Button
          _focus={{
            bgColor: 'slate.100',
            borderColor: 'slate.400',
            textColor: 'slate.800',
          }}
          _hover={{
            bgColor: 'slate.200',
            borderColor: 'slate.500',
            textColor: 'slate.900',
          }}
          bgColor='slate.50'
          border='2px'
          borderColor='slate.300'
          textColor='slate.700'
          variant='solid'
        >
          <ButtonRangeText {...range} />
        </Button>
      </PopoverTrigger>
      <IconButton
        aria-label='Clear filter'
        bgColor='slate.50'
        border='2px'
        borderColor='slate.300'
        className='ml-3'
        colorScheme='gray'
        icon={<XIcon className='p-2' />}
        onClick={handleReset}
        size='md'
      />
      <PopoverContent boxSize='auto' className='grid place-items-center'>
        <PopoverCloseButton />

        <div className='grid shadow-lg w-fit bg-slate-200'>
          <DateRangePicker
            calendarFocus='backwards'
            direction='horizontal'
            editableDateInputs
            maxDate={maxDate}
            minDate={minDate}
            months={2}
            onChange={handleDateChange}
            preventSnapRefocus
            rangeColors={['#ffe1d6']}
            ranges={[range]}
            showDateDisplay={false}
            staticRanges={staticRanges}
          />
          <div className='grid grid-cols-3 gap-6 p-5 bg-white border-t rounded-sm shadow-sm border-slate-200'>
            <div />
            <div />
            <div className='mt-auto'>
              <Button
                className='block'
                colorScheme='gray'
                onClick={onClose}
                variant='outline'
                width='100%'
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

const ButtonRangeText = ({ startDate, endDate }: Range) => {
  if (startDate === undefined && endDate === undefined) {
    return <Text>Select time range</Text>
  } else if (startDate === undefined) {
    return (
      <Text>
        Until <FormattedDate dateStyle='medium' value={endDate} />
      </Text>
    )
  } else if (endDate === undefined) {
    return (
      <Text>
        From <FormattedDate dateStyle='medium' value={startDate} />
      </Text>
    )
  } else {
    return (
      <FormattedDateTimeRange
        dateStyle='medium'
        from={startDate}
        to={endDate}
      />
    )
  }
}
