/* eslint-disable unicorn/no-thenable */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  type DiceApiAggregation,
  DiceApiAggregationFunction,
  DiceApiAggregationFunctionWithArgument,
  type DiceApiAttachment,
  type DiceApiBaseType,
  DiceApiBlockId,
  type DiceApiBlockImplementation,
  DiceApiBooleanType,
  DiceApiChainingOperator,
  DiceApiControlCategoryType,
  DiceApiControlFrequency,
  DiceApiControlLogicType,
  type DiceApiCreateControl,
  type DiceApiCreateControlMetadata,
  type DiceApiCreateRemediation,
  type DiceApiGroupingDefinition,
  type DiceApiHarNameValue,
  type DiceApiHarRequest,
  type DiceApiHeaderAuthInfoCreate,
  DiceApiIonBaseType,
  type DiceApiJsonataConditionBlock,
  DiceApiJtdBaseType,
  DiceApiNumberType,
  type DiceApiPatchControl,
  type DiceApiPatchControlMetadata,
  type DiceApiSignatureBlock,
  type DiceApiSignatureBlockInput,
  DiceApiSimpleModeOperator,
  type DiceApiStreamInfoAllCreate,
  type DiceApiStreamInfoAllPatch,
  type DiceApiStreamInfoApiCreate,
  DiceApiStreamInfoApiCreateStreamType,
  type DiceApiStreamInfoScheduledCreate,
  type DiceApiStreamInfoScheduledPatch,
  DiceApiStreamInfoScheduledReadStreamType,
  type DiceApiStreamInfoWebhookCreate,
  DiceApiStreamInfoWebhookCreateStreamType,
  DiceApiStreamType,
  DiceApiStringType,
  DiceApiTaskAction,
  DiceApiTimestampType,
  type PatchControlsControlIdMutationBody,
  type PostControlsMutationBody,
  type PostFilesMutationBody,
} from '@haesh/dice-api'
import {
  type Condition,
  type ConditionClause,
  ControlStatus,
  isJsonataConditionBlock,
  isSignatureBlock,
  NotificationCycle,
  RiskCategory,
} from '@haesh/dice-types'
import { isSchema, isValidSchema } from 'jtd'
import { type O } from 'ts-toolbelt'
import * as yup from 'yup'

const yupUserIdObject = yup
  .object({ userId: yup.string().required('Owner is required') })
  .noUnknown(true)
  .strict()
  .defined()

const JSONATA_NUMERIC = /^[+-]?(?:0|[1-9]\d*|0\.\d+|[1-9]\d*\.\d+)$/u
const JSON_POINTER = /^\/(?:[^~]|~0|~1)*$/u

const JsonPointerShape = yup
  .string()
  .matches(
    JSON_POINTER,
    "The provided input is not a valid json pointer, like '/id'"
  )
/*
 * If the control owner type can be custom (email + name) instead of user
 *

  const yupCustomUserObject = yup
    .object({
      customUser: yup
        .object({
          email: yup.string().required(),
          name: yup.string().required(),
        })
        .noUnknown(true)
        .strict()
        .defined(),
    })
    .optional()
    .noUnknown(true)
    .strict()
    .defined()

  export const SimpleUserShape = yup.lazy((value?: SimpleRecipient) => {
    if (!value) return yup.mixed().optional()
    if (typeof value !== 'object') {
      return yup
        .mixed()
        .test(
          'test-owner',
          'Owner is not of type object',
          ownerValue => !ownerValue
        )
    }

    // workaround to get it to be not required
    if (
      !('userId' in value && value.userId) &&
      !('name' in value) &&
      !('email' in value)
    )
      return yup.mixed().optional()

    if ('userId' in value) {
      return yupUserIdObject
    } else {
      return yupCustomUserObject
    }
  })
*/

export const ControlNotificationsShape = yup
  .object({
    failCycles: yup.string().oneOf(Object.keys(NotificationCycle)),
    passCycles: yup.string().oneOf(Object.keys(NotificationCycle)),
    recipient: yupUserIdObject,
    type: yup.string().oneOf(['EMAIL']),
  })
  .noUnknown(true)
  .strict()
  .defined()

/**
 * Shape of a {@link PostControlsMutationBody} used to create a new control
 */
export const TopicsCreateShape: yup.ObjectSchema<PostControlsMutationBody> = yup
  .object({
    description: yup.string().trim().required('Topic Description is required'),
    displayName: yup.string().trim().required('Topic Name is required'),
  })
  .noUnknown(true)
  .strict()
  .defined()

/**
 * Shape of a {@link PatchControlsControlIdMutationBody} used to create a new control
 */
export const TopicsPatchShape: yup.ObjectSchema<PatchControlsControlIdMutationBody> =
  yup
    .object({
      description: yup.string(),
      displayName: yup.string(),
    })
    .noUnknown(true)
    .strict()
    .defined()

/**
 * Shape of a [`PostFilesMutationBody`] used to receive presigned s3 urls
 */
export const FilesUploadShape: yup.ObjectSchema<PostFilesMutationBody> = yup
  .object({
    contentType: yup.string().required(),
    filename: yup.string().required().trim(),
    prefix: yup.string(),
  })
  .noUnknown(true)
  .strict()
  .defined()

export const getOperatorOptions = (
  type: DiceApiBaseType
): Array<[string, DiceApiSimpleModeOperator]> => {
  if (Object.values<string>(DiceApiBooleanType).includes(type)) {
    // Compare boolean values only with
    return [['Equals', DiceApiSimpleModeOperator.EQUALS]]
  }

  if (Object.values<string>(DiceApiStringType).includes(type)) {
    // Compare string values with
    return [
      ['Equals', DiceApiSimpleModeOperator.EQUALS],
      ['Not equals', DiceApiSimpleModeOperator.NOT_EQUAL],
    ]
  }

  // Compare numeric and timestamp values with
  return [
    ['Equals', DiceApiSimpleModeOperator.EQUALS],
    ['Not equals', DiceApiSimpleModeOperator.NOT_EQUAL],
    ['Greater than', DiceApiSimpleModeOperator.GREATER_THAN],
    ['Greater than or equal', DiceApiSimpleModeOperator.GREATER_THAN_EQUAL],
    ['Less than', DiceApiSimpleModeOperator.LESS_THAN],
    ['Less than or equal', DiceApiSimpleModeOperator.LESS_THAN_EQUAL],
  ]
}

/**
 * Schema of an attribute within the simple mode of a condition
 */
const SimpleAttributeSchema = yup
  .object({
    name: yup.string().required('Attribute is required'),
    type: yup
      .string()
      .required()
      .oneOf<DiceApiBaseType>([
        ...Object.values<string>(DiceApiIonBaseType),
        ...Object.values<string>(DiceApiJtdBaseType),
      ] as DiceApiBaseType[]),
  })
  .required('Attribute is required')
  .noUnknown()

// rule disabled because this is the way to access other yup fields
/**
 * Shape of a condition clause used by a {@link ConditionShape}
 */
export const ConditionClauseShape: yup.ObjectSchema<
  DiceApiJsonataConditionBlock['parameters']['inputs']['conditions'][0]['clauses'][0]['simpleClause']
> = yup
  .object({
    attribute: yup
      .object({
        name: yup.string().required('Attribute is required'),
        type: yup
          .string()
          .required()
          .oneOf<DiceApiIonBaseType | DiceApiJtdBaseType>([
            ...Object.values<string>(DiceApiIonBaseType),
            ...Object.values<string>(DiceApiJtdBaseType),
          ] as Array<DiceApiIonBaseType | DiceApiJtdBaseType>),
      })
      .required('Attribute is required'),
    comparisonValue: yup
      .mixed()
      .required()
      // func style because we need to access 'this'
      .test(
        'type-compatibility-check-string',
        'Valid text required',
        (value, { parent }) => {
          const type = (parent as Partial<ConditionClause>).attribute?.type
          return (
            type === undefined ||
            !Object.values<string>(DiceApiStringType).includes(type) ||
            typeof value === 'string'
          )
        }
      )
      .test(
        'type-compatibility-check-numeric',
        'Must be a valid number with period as decimal separator',
        (value, { parent }) => {
          const type = (parent as Partial<ConditionClause>).attribute?.type
          if (
            type === undefined ||
            !Object.values<string>(DiceApiNumberType).includes(type)
          ) {
            return true
          }

          if (
            value === undefined ||
            typeof value !== 'string' ||
            Number.isNaN(Number.parseFloat(value))
          ) {
            return false
          }

          return JSONATA_NUMERIC.test(value)
        }
      )
      .test(
        'type-compatibility-check-timestamp',
        'Must be in format YYYY-MM-DD',
        (value, { parent }) => {
          const type = (parent as Partial<ConditionClause>).attribute?.type
          if (
            type === undefined ||
            !Object.values<string>(DiceApiTimestampType).includes(type)
          ) {
            return true
          }

          return typeof value === 'string'
            ? /^\d{4}-(?:0[1-9]|1[012])-(?:0[1-9]|[12]\d|3[01])$/u.test(value)
            : false
        }
      ),
    operator: yup
      .string()
      .required('Comparison Operator is required')
      .oneOf<DiceApiSimpleModeOperator>(
        Object.values(DiceApiSimpleModeOperator)
      ),
  })
  .noUnknown(true)
  .strict()
  .defined()

/**
 * Shape of a condition used by a {@link RulesCreateShape}
 */
export const ConditionShape: yup.ObjectSchema<Condition> = yup
  .object({
    chainingOperator: yup
      .string()
      .required()
      .oneOf<DiceApiChainingOperator>(Object.values(DiceApiChainingOperator)),
    clauses: yup
      .array(ConditionClauseShape.required() as any)
      .required()
      .min(1, 'At least 1 Fail condition is required'),
    outcome: yup.string().required('Failure Reason required'),
  })
  .noUnknown(true)
  .strict()
  .defined()
  .required()

export const SignatureBlockShape: yup.ObjectSchema<DiceApiSignatureBlock> = yup
  .object({
    blockId: yup
      .string()
      .required('Block ID is required')
      .oneOf<'SIGNATURE_REQUIRED'>(['SIGNATURE_REQUIRED']),
    next: yup.string(),
    parameters: yup
      .object({
        inputs: yup
          .object({
            actions: yup
              .array(
                yup
                  .string()
                  .required()
                  .oneOf<DiceApiTaskAction>(Object.values(DiceApiTaskAction))
              )
              .required(),
            displayData: yup.array(yup.mixed().required() as any).required(),
            expiration: yup.string(),
            groupIds: yup
              .array(yup.string().required())
              .required()
              .test({
                message: 'At least one role must be specified',
                name: 'enough-signers',
                test: (
                  value,
                  context: O.Overwrite<
                    yup.TestContext,
                    {
                      parent: Partial<
                        DiceApiSignatureBlock['parameters']['inputs']
                      >
                    }
                  >
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                ) => Boolean(value?.length || context.parent.userIds?.length),
              })
              .test({
                message: 'Not enought approvers specified',
                name: 'enough-possible-userId-signers',
                test: (
                  value,
                  context: O.Overwrite<
                    yup.TestContext,
                    {
                      parent: Partial<
                        DiceApiSignatureBlock['parameters']['inputs']
                      >
                    }
                  >
                ) =>
                  Boolean(
                    !context.parent.requiredApprovals ||
                      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                      context.parent.userIds?.length ||
                      value?.length
                  ),
              }),
            requiredApprovals: yup.number().min(1).required(),
            subtitle: yup.string(),
            title: yup
              .string()
              .required('A title for the approval task is required'),
            type: yup
              .string()
              .required()
              .oneOf<DiceApiSignatureBlockInput['type']>([
                'X_APPROVALS',
                'X_APPROVALS_NO_REJECTS',
                'TIMED_VOTE',
              ]),
            userIds: yup
              .array(yup.string().required())
              .test({
                message: 'Not enough approvers specified',
                name: 'enough-possible-userId-signers',
                test: (
                  value,
                  context: O.Overwrite<
                    yup.TestContext,
                    {
                      parent: Partial<
                        DiceApiSignatureBlock['parameters']['inputs']
                      >
                    }
                  >
                ) =>
                  Boolean(
                    !context.parent.requiredApprovals ||
                      context.parent.groupIds?.length ||
                      (value?.length &&
                        value.length >= context.parent.requiredApprovals)
                  ),
              })
              .required(),
          })
          .test({
            message: 'At least one role or user must be selected',
            name: 'at-least-one-role-or-user',
            test: (values) =>
              Boolean(values.groupIds?.length) ||
              Boolean(values.userIds?.length),
          })
          .required(),
        outputFormat: yup.object({
          // TODO: implement this
          approvers: yup.array(yup.mixed().required() as any).required(),
          outcome: yup
            .string()
            .required()
            .oneOf<'APPROVED' | 'EXPIRED' | 'REJECTED'>([
              'APPROVED',
              'REJECTED',
              'EXPIRED',
            ]),
          // TODO: implement this
          rejectors: yup.array(yup.string().required() as any).required(),
        }),
      })
      .noUnknown(true)
      .strict()
      .defined(),
    uniqueName: yup.string().required(),
  })
  .noUnknown(true)
  .strict()
  .defined()

export const JsonataSingleCondition = yup.object({
  chainingOperator: yup
    .string()
    .required()
    .oneOf<DiceApiChainingOperator>(Object.values(DiceApiChainingOperator)),
  clauses: yup
    .array(
      yup
        .object({
          expression: yup.string().trim().required(),
          isCustomExpression: yup.boolean(),
          simpleClause: yup
            .object({
              attribute: SimpleAttributeSchema,
              comparisonValue: yup
                .mixed()
                .test(
                  'type-compatibility-check-string',
                  'Valid text required',
                  function (value) {
                    // eslint-disable-next-line @babel/no-invalid-this
                    const type = (this.parent as Partial<ConditionClause>)
                      .attribute?.type
                    return (
                      type === undefined ||
                      !Object.values<string>(DiceApiStringType).includes(
                        type
                      ) ||
                      typeof value === 'string'
                    )
                  }
                )
                .test(
                  'type-compatibility-check-numeric',
                  'Must be a valid number with period as decimal separator',
                  function (value) {
                    // eslint-disable-next-line @babel/no-invalid-this
                    const type = (this.parent as Partial<ConditionClause>)
                      .attribute?.type
                    if (
                      type === undefined ||
                      !Object.values<string>(DiceApiNumberType).includes(type)
                    ) {
                      return true
                    }

                    if (
                      !value ||
                      typeof value !== 'string' ||
                      Number.isNaN(Number.parseFloat(value))
                    )
                      return false
                    return JSONATA_NUMERIC.test(value)
                  }
                )
                .test(
                  'type-compatibility-check-timestamp',
                  'Must be in format YYYY-MM-DD',
                  function (value) {
                    // eslint-disable-next-line @babel/no-invalid-this
                    const type = (this.parent as Partial<ConditionClause>)
                      .attribute?.type
                    if (
                      type === undefined ||
                      !Object.values<string>(DiceApiTimestampType).includes(
                        type
                      )
                    ) {
                      return true
                    }

                    if (!value) return false

                    return typeof value === 'string'
                      ? /^\d{4}-(?:0[1-9]|1[012])-(?:0[1-9]|[12]\d|3[01])$/u.test(
                          value
                        )
                      : false
                  }
                )
                .required(),
              operator: yup
                .string()
                .required('Comparison Operator is required')
                .oneOf<DiceApiSimpleModeOperator>(
                  Object.values(DiceApiSimpleModeOperator)
                ),
            })
            .optional(),
        })
        .test({
          message: 'Cannot set simple clause values for custom expression',
          test: (value) =>
            Boolean(!value.isCustomExpression || !value.simpleClause),
        })
        .noUnknown(true)
        .strict()
        .defined()
    )
    .min(1)
    .required(),
  outcome: yup.string().trim().required(),
})

export const JsonataConditionBlockShape: yup.ObjectSchema<DiceApiJsonataConditionBlock> =
  yup.object({
    blockId: yup
      .string()
      .required('Block ID is required')
      .oneOf<typeof DiceApiBlockId.JSONATA_CONDITION>([
        DiceApiBlockId.JSONATA_CONDITION,
      ]),
    next: yup.string(),
    parameters: yup.object({
      inputs: yup
        .object({
          conditions: yup.array(JsonataSingleCondition).min(1).required(),
          default: yup.string(),
        })
        .required(),
      outputFormat: yup.object({
        default: yup.boolean().required(),
        outcome: yup.string().trim().required(),
      }),
    }),
    uniqueName: yup.string().required(),
  })

export const CreateControlMetadataShape: yup.ObjectSchema<DiceApiCreateControlMetadata> =
  yup
    .object({
      addressedRisk: yup.string(),
      controlEntity: yup.string(),
      controlFrequency: yup
        .string()
        .oneOf<DiceApiControlFrequency>(Object.values(DiceApiControlFrequency))
        .required(),
      controlKeyControls: yup.boolean().required(),
      controlObjective: yup.string(),
      description: yup.string(),
      displayName: yup
        .string()
        .trim()
        .max(100, 'Control ID should not be longer than 100 characters')
        .required('Control ID is required'),
      owner: yupUserIdObject,
      processCycle: yup.string(),
      riskCategory: yup
        .string()
        .oneOf<RiskCategory>(
          Object.values(RiskCategory),
          `Risk Category must be one of the following values: low, medium, high`
        )
        .required(),
      shortDescription: yup
        .string()
        .trim()
        .required('Short Description is required')
        .max(100, 'Short Description should not be longer than 100 characters'),
    })
    .noUnknown(true)
    .strict()
    .defined()

export const PatchControlMetadataShape: yup.ObjectSchema<DiceApiPatchControlMetadata> =
  yup
    .object({
      addressedRisk: yup.string().nullable(),
      controlEntity: yup.string().nullable(),
      controlFrequency: yup
        .string()
        .oneOf<DiceApiControlFrequency>(Object.values(DiceApiControlFrequency)),
      controlKeyControls: yup.boolean(),
      controlObjective: yup.string().nullable(),
      description: yup.string().nullable(),
      displayName: yup
        .string()
        .max(100, 'Control ID should not be longer than 100 characters'),
      owner: yup.lazy((value) => {
        if (value === undefined || value === null) return yup.mixed()
        else return yupUserIdObject
      }) as any,
      processCycle: yup.string().nullable(),
      riskCategory: yup
        .string()
        .oneOf<RiskCategory>(
          Object.values(RiskCategory),
          `Risk Category must be one of the following values: low, medium, high`
        ),
      shortDescription: yup
        .string()
        .max(100, 'Short Description should not be longer than 100 characters'),
    })
    .noUnknown(true)
    .strict()
    .defined()

const TriggerShape = yup
  .object({ streamId: yup.string().required('Trigger stream ID is required') })
  .noUnknown(true)

const JoinsShape = yup
  .array(
    yup
      .object({
        expression: yup
          .string()
          .trim()
          .required('JSONata expression is required for each join'),
        isCustomExpression: yup.boolean(),
        simpleClause: yup
          .object({ attribute: SimpleAttributeSchema })
          .noUnknown(),
        streamId: yup.string().required('Stream ID is required for each join'),
      })
      .noUnknown(true)
      .required()
  )
  .min(1)

export const getControlDataSourceShapeCreate = () =>
  yup
    .object({
      joins: JoinsShape.optional(),
      trigger: TriggerShape.required(),
    })
    .noUnknown(true)
    .strict()
    .defined()

export const getControlDataSourceShapeUpdate = () =>
  yup
    .object({
      joins: JoinsShape.nullable(),
      trigger: TriggerShape.optional(),
    })
    .noUnknown(true)
    .strict()
    .defined()

/**
 * @deprecated Use {@link getControlDataSourceShapeCreate} or {@link getControlDataSourceShapeUpdate} instead
 */
export const getControlDataSourceShape = getControlDataSourceShapeCreate

const ControlDefinitionSimpleShape = yup.object({
  simpleDefinition: yup.array(JsonataSingleCondition).required().min(1),
})

const ControlDefinitionWorkflowShape = yup
  .object({
    workflowDefinition: yup
      .object({
        definition: yup.lazy(
          (definition: Record<string, DiceApiBlockImplementation>) =>
            yup.object(
              Object.fromEntries(
                Object.entries(definition).map(([key, value]) => {
                  if (key.trim().toLowerCase().startsWith('infra_'))
                    return [
                      key,
                      yup.object({}).test({
                        message: 'A block cannot start with Infra_',
                        name: 'infra-block-name-not-allowed',
                        test: () => false,
                      }),
                    ]

                  if (isSignatureBlock(value)) return [key, SignatureBlockShape]
                  if (isJsonataConditionBlock(value))
                    return [key, JsonataConditionBlockShape]
                  return [key, yup.mixed()]
                })
              )
            )
        ),
        start: yup
          .string()
          .required('Start is required')
          .test({
            message: 'Specified start block does not exist',
            name: 'start-block-exists',
            test: (value, context) => {
              const controlDefinition = context.parent as
                | string[]
                | (unknown & { definition?: Record<string, unknown> })

              if (Array.isArray(controlDefinition)) {
                return true
              }

              if (value === undefined) return true

              return Object.keys(controlDefinition.definition ?? {}).includes(
                value
              )
            },
          }),
      })
      .noUnknown(true)
      .strict()
      .required(),
  })
  .noUnknown(true)
  .strict()
  .defined()

export const getControlLogicShapeCreate = () =>
  yup
    .object({
      controlDefinition: yup.lazy((_lazyValue, meta) => {
        const { controlCategoryType } =
          meta.parent as Partial<DiceApiCreateControl>
        if (controlCategoryType === DiceApiControlCategoryType.simple)
          return ControlDefinitionSimpleShape.required()
        else return ControlDefinitionWorkflowShape.required()
      }),
    })
    .noUnknown(true)
    .strict()
    .defined()
    .required()

export const getControlLogicShapeUpdate = () =>
  yup
    .object({
      controlDefinition: yup.lazy((value: unknown) => {
        if (value === undefined)
          return yup.mixed() as unknown as typeof ControlDefinitionWorkflowShape

        return value !== null &&
          typeof value === 'object' &&
          'simpleDefinition' in value
          ? ControlDefinitionSimpleShape.required()
          : ControlDefinitionWorkflowShape.required()
      }),
    })
    .noUnknown(true)
    .strict()
    .defined()

/**
 * @deprecated Use {@link getControlLogicShapeCreate} or {@link getControlLogicShapeUpdate}  instead
 */
export const getControlLogicShape = getControlLogicShapeCreate

/**
 * Shape of a {@link DiceApiCreateControl} used to create a new control
 */
export const RulesCreateShape: yup.ObjectSchema<DiceApiCreateControl> =
  CreateControlMetadataShape.concat(getControlDataSourceShapeCreate())
    .concat(getControlLogicShapeCreate())
    .shape({
      controlAutomated: yup.boolean().required(),
      controlCategoryType: yup
        .string()
        .required()
        .oneOf<DiceApiControlCategoryType>(
          Object.values(DiceApiControlCategoryType)
        ),
      controlLogicType: yup
        .string()
        .required()
        .oneOf<DiceApiControlLogicType>(Object.values(DiceApiControlLogicType)),
      status: yup.string().oneOf(Object.values(ControlStatus)),
    })
    .noUnknown(true)
    .strict()
    .defined()

/**
 * Shape of a {@link DiceApiPatchControl} used to update a control
 */
export const RulesUpdateShape: yup.ObjectSchema<DiceApiPatchControl> =
  PatchControlMetadataShape.concat(getControlDataSourceShapeUpdate())
    .concat(getControlLogicShapeUpdate())
    .noUnknown(true)
    .strict()
    .defined()

/**
 * Shape of a condition used by a {@link DiceApiAttachment}
 */
export const AttachmentShape: yup.ObjectSchema<DiceApiAttachment> = yup
  .object({
    bucketName: yup.string().required(),
    filename: yup.string().trim().required(),
    objectKey: yup
      .string()
      .required()
      .test(
        'not-a-presigned-url',
        'The provided object all contained parameters',
        (value) => Boolean(value && !value.includes('?'))
      ),
  })
  .noUnknown(true)
  .strict()
  .defined()

/**
 * Shape of a {@link DiceApiCreateRemediation} used to create a new control
 */
export const RemediationCreateShape: yup.ObjectSchema<DiceApiCreateRemediation> =
  yup
    .object({
      attachments: yup.array(AttachmentShape.required()),
      controlId: yup.string().required(),
      message: yup.string().required(),
      ruleId: yup.string().required(),
    })
    .noUnknown(true)
    .strict()
    .defined()

export const StreamAuthHeaderCreateShape: yup.ObjectSchema<DiceApiHeaderAuthInfoCreate> =
  yup.object({
    description: yup.string(),
    expiresAt: yup.string(),
    name: yup
      .string()
      .trim()
      .required('Header name is required if a value is provided'),
    value: yup
      .string()
      .trim()
      .required('Header value is required if a name is provided'),
  })

const HarNameValueShape: yup.ObjectSchema<DiceApiHarNameValue> = yup.object({
  comment: yup.string(),
  name: yup.string().trim().required(),
  value: yup.string().trim().required(),
})

export const HarRequestShape: yup.ObjectSchema<DiceApiHarRequest> = yup.object({
  comment: yup.string(),
  headers: yup.array(HarNameValueShape),
  method: yup.string().required(),
  postData: yup.object({
    comment: yup.string(),
    mimeType: yup.string().required(),
    text: yup.string().trim().required(),
  }),
  queryString: yup.array(HarNameValueShape),
  url: yup
    .string()
    .required('URL is required for scheduled streams')
    .url('Invalid URL')
    .matches(/^https?/u, 'URL must be http or https')
    .test('URL cannot contain query strings', (value) => {
      return !value.includes('?')
    }),
})

const BaseStreamCreateShape: yup.ObjectSchema<DiceApiStreamInfoAllCreate> =
  yup.object({
    owner: yupUserIdObject.optional(),
    schema: yup
      .object()
      .typeError('The provided input is not valid JSON')
      .test({
        message: 'The provided schema is not a JSON Type Definition',
        name: 'is-valid-schema',
        test: (value) => {
          if (
            value !== undefined &&
            (!isSchema(value) || !isValidSchema(value))
          ) {
            return false
          }

          return true
        },
      }),
    streamDescription: yup.string(),
    streamTitle: yup.string().trim().required('Stream Title is required'),
    streamType: yup
      .string()
      .required()
      .oneOf<DiceApiStreamType>(Object.values(DiceApiStreamType)),
    titleKeyName: JsonPointerShape,
  })

export const CreateScheduledStreamShape: yup.ObjectSchema<DiceApiStreamInfoScheduledCreate> =
  BaseStreamCreateShape.shape({
    cronExpression: yup.string().trim().required(),
    harRequest: HarRequestShape,
    isActive: yup.boolean().required(),
    primaryKeyName: JsonPointerShape,
    streamType: yup
      .string()
      .oneOf<DiceApiStreamInfoScheduledReadStreamType>(
        Object.values(DiceApiStreamInfoScheduledReadStreamType)
      )
      .required(),
  })
    .noUnknown(true)
    .strict()
    .defined()

export const CreateWebhookStreamShape: yup.ObjectSchema<DiceApiStreamInfoWebhookCreate> =
  BaseStreamCreateShape.shape({
    authorization: yup.object({
      headers: yup.array().of(StreamAuthHeaderCreateShape),
    }),
    primaryKeyName: JsonPointerShape,
    streamType: yup
      .string()
      .oneOf<DiceApiStreamInfoWebhookCreateStreamType>(
        Object.values(DiceApiStreamInfoWebhookCreateStreamType)
      )
      .required(),
  })
    .noUnknown(true)
    .strict()
    .defined()

export const CreateApiStreamShape: yup.ObjectSchema<DiceApiStreamInfoApiCreate> =
  BaseStreamCreateShape.shape({
    streamType: yup
      .string()
      .oneOf<DiceApiStreamInfoApiCreateStreamType>(
        Object.values(DiceApiStreamInfoApiCreateStreamType)
      )
      .required(),
  })
    .noUnknown(true)
    .strict()
    .defined()

export const StreamsCreateShape = yup.lazy((value) => {
  const streamType = value?.streamType as DiceApiStreamType | undefined
  if (streamType === DiceApiStreamType.scheduled) {
    return CreateScheduledStreamShape
  }

  if (streamType === DiceApiStreamType.webhook) {
    return CreateWebhookStreamShape
  }

  if (streamType === DiceApiStreamType.api) {
    return CreateApiStreamShape
  }

  return yup.object({
    streamType: yup
      .string()
      .oneOf<
        | DiceApiStreamInfoApiCreateStreamType
        | DiceApiStreamInfoScheduledReadStreamType
        | DiceApiStreamInfoWebhookCreateStreamType
      >(['api', 'webhook', 'scheduled'])
      .required(),
  })
})

export const StreamInfoApiPatchSchema: yup.ObjectSchema<DiceApiStreamInfoAllPatch> =
  yup
    .object({
      owner: yupUserIdObject.optional().nullable(),
      schema: yup
        .object()
        .nullable()
        .typeError('The provided input is not valid JSON')
        .test({
          message: 'The provided schema is not a JSON Type Definition',
          name: 'is-valid-schema',
          test: (value) => {
            if (
              value !== undefined &&
              value !== null &&
              (!isSchema(value) || !isValidSchema(value))
            ) {
              return false
            }

            return true
          },
        }),
      streamDescription: yup.string().nullable(),
      streamTitle: yup.string(),
    })
    .noUnknown(true)
    .strict()
    .defined()

export const StreamInfoWebhookPatchSchema: yup.ObjectSchema<DiceApiStreamInfoAllPatch> =
  StreamInfoApiPatchSchema

export const StreamInfoScheduledPatchSchema: yup.ObjectSchema<DiceApiStreamInfoScheduledPatch> =
  StreamInfoApiPatchSchema.shape({
    cronExpression: yup.string(),
    harRequest: HarRequestShape,
    isActive: yup.boolean(),
  })

/**
 * Shape of a {@link DiceApiGroupingDefinition} used to create a new grouping
 */
export const GroupsCreateSchema: yup.ObjectSchema<DiceApiGroupingDefinition> =
  yup
    .object({
      aggregations: yup
        .array(
          yup
            .object({
              expression: yup.string().when('function', {
                is: (functionName: DiceApiAggregationFunction) =>
                  Object.values<string>(
                    DiceApiAggregationFunctionWithArgument
                  ).includes(functionName),
                then: (schema) =>
                  schema.required(
                    'JSONata expression is required for this aggregation'
                  ),
                // yup doesn't understand that depending on `function`,
                // this field is not always required
              }) as yup.StringSchema<string>,
              function: yup
                .string()
                .required()
                .oneOf(Object.values(DiceApiAggregationFunction)),
              isCustomExpression: yup.boolean(),
              name: yup
                .string()
                .required('Name is required for each aggregation')
                .trim(
                  'Aggregation name cannot contain leading or trailing whitespace'
                )
                .test({
                  message: 'Aggregation names must be unique',
                  name: 'unique-aggregation-names',
                  test: (name, context) => {
                    // @ts-expect-error we know this exists, but might have undefined values
                    const aggregations = context.from[1].value
                      .aggregations as Array<Partial<DiceApiAggregation>>

                    const names = aggregations.map((agg) => agg.name)

                    const isDuplicateName =
                      names.filter((otherName) => otherName === name).length !==
                      1

                    return !isDuplicateName
                  },
                }),
              simpleClause: yup
                .object({
                  attribute: SimpleAttributeSchema,
                })
                .noUnknown(),
            })
            .noUnknown(true)
            .required()
        )
        .required()
        .min(2),
      description: yup.string(),
      groupBy: yup
        .object({
          expression: yup
            .string()
            .trim()
            .required('JSONata expression is required for each aggreation'),
          isCustomExpression: yup.boolean(),
          simpleClause: yup
            .object({
              attributes: yup.array(SimpleAttributeSchema).required().min(1),
            })
            .noUnknown(),
        })
        .noUnknown(true)
        .required(),
      title: yup.string().trim().required('Group Title is required'),
    })
    .noUnknown(true)
    .strict()
    .defined()
