import EventListFiltered from './EventListFiltered'
import { type DiceApiEvent } from '@haesh/dice-api'

type EventDetailsConnectedEventsProps = {
  event: DiceApiEvent
}

const EventDetailsConnectedEvents = ({
  event,
}: EventDetailsConnectedEventsProps) => {
  switch (event.details.type) {
    case 'stream-row-created':
      return (
        <EventListFiltered
          filter={{
            bool: {
              must: [
                { term: { traceId: event.traceId } },
                { term: { type: 'detective-result' } },
              ],
            },
          }}
        />
      )
    case 'stream-row-deleted':
      return (
        <EventListFiltered
          filter={{
            bool: {
              must: [
                { term: { traceId: event.traceId } },
                { term: { type: 'detective-result' } },
              ],
            },
          }}
        />
      )
    case 'stream-row-updated':
      return (
        <EventListFiltered
          filter={{
            bool: {
              must: [
                { term: { traceId: event.traceId } },
                { term: { type: 'detective-result' } },
              ],
            },
          }}
        />
      )
    case 'detective-result':
      return (
        <EventListFiltered
          filter={{
            bool: {
              must: [
                { term: { traceId: event.traceId } },
                {
                  bool: {
                    should: [
                      { term: { type: 'stream-row-created' } },
                      { term: { type: 'stream-row-deleted' } },
                      { term: { type: 'stream-row-updated' } },
                    ],
                  },
                },
              ],
            },
          }}
        />
      )

    case 'scheduled-stream-error':
    case 'detective-result-error':
      return (
        <div className='mt-2 text-sm'>
          No connected events available for this event
        </div>
      )

    default:
      return <div className='mt-2 text-sm'>Unknown event</div>
  }
}

export default EventDetailsConnectedEvents
