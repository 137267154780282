import { Button } from '@chakra-ui/react'
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export type ErrorScreenProps = {
  ActionButton?: React.ReactNode
  header?: string
  resetError?: () => void
  subtext?: string
  title?: string
}

const ErrorScreen = ({
  header,
  ActionButton,
  title = 'Something Went Wrong',
  subtext,
  resetError,
}: ErrorScreenProps) => {
  const navigate = useNavigate()

  const { key } = useLocation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialKey = useMemo(() => key, [])

  useEffect(() => {
    if (key !== initialKey) {
      resetError?.()
    }
    // resetError changes on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, initialKey])

  const handleClick = () => {
    navigate('/', { replace: true })
  }

  return (
    <div className='flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
      <div className='py-16'>
        <div className='text-center'>
          {header !== undefined && (
            <p className='text-sm font-semibold tracking-wide text-orange-600 uppercase'>
              {header}
            </p>
          )}
          <h1 className='mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl'>
            {title}
          </h1>
          <p className='mt-2 text-base text-gray-500'>{subtext}</p>
          <div className='mt-6'>
            {ActionButton ?? (
              <Button
                leftIcon={<ArrowNarrowLeftIcon className='h-6' />}
                onClick={handleClick}
              >
                Go back home
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorScreen
