import { formatDistance } from 'date-fns'
import { useEffect, useState } from 'react'

/**
 * @param {Date} date - The date to calculate relative time from.
 * @returns {string} - A string representing the relative time (e.g. "2 days ago").
 */
const relativeTime = (date: Date) =>
  formatDistance(date, new Date(), { addSuffix: true })

/**
 * @param {Date} date - The date to calculate relative time from.
 * @param {number} [ms] - The interval in milliseconds for updating the relative time.
 * @returns {string} - A string representing the relative time (e.g. "2 days ago").
 */
export const useRelativeTime = (date: Date, ms = 60_000) => {
  const [relative, setRelative] = useState(relativeTime(date))

  useEffect(() => {
    const timer = setInterval(() => setRelative(relativeTime(date)), ms)

    return () => clearInterval(timer)
  }, [date])

  return relative
}
