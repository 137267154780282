import { getEnvironment } from '@haesh/constants'
import { type DiceApiUserInfo } from '@haesh/dice-api'
import {
  type ExecutionsGetResponse,
  type Tenant,
  type TenantsProvisionRequest,
  type TenantsProvisionResponse,
} from '@haesh/dice-types'
import axios from 'axios'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { useUser } from './AuthProvider'
import { getStageFromWindow, mapError } from './utils'

const domain = `https://${`${
  getEnvironment(getStageFromWindow()).apiSubdomain
    ? `${getEnvironment(getStageFromWindow()).apiSubdomain}.`
    : ''
}${getEnvironment(getStageFromWindow()).baseDomain}`}`

const axiosAdminInstance = axios.create({
  baseURL: `${domain}/systemAdmin`,
  // longer timeout because creating tenants + users might take a bit longer
  timeout: 12_000,
})
axiosAdminInstance.defaults.headers.post['content-type'] = 'application/json'

const useProvideSystemAdmin = (
  notify: (type: string, text1: string, text2?: string) => void
) => {
  const [tenants, setTenants] = useState<Tenant[] | undefined>(undefined)
  const { getIdToken } = useUser()

  /**
   * Retrieves the IdToken from Amplify and configures it as the default
   * authorization header
   *
   * @returns idToken in form of a JWT
   */
  const handleAuth = useCallback(async (): Promise<string | undefined> => {
    return await getIdToken().then(token => {
      axiosAdminInstance.defaults.headers.common.Authorization = `Bearer ${token}`
      return token
    })
  }, [getIdToken])

  /**
   * Fetches all tenants and stores them inside a state
   *
   * @returns the tenants as an array or undefined on error
   */
  const fetchTenants = useCallback(async (): Promise<Tenant[] | undefined> => {
    await handleAuth()
    return await axiosAdminInstance
      .get<Tenant[]>('/tenants')
      .then(response => {
        setTenants(response.data)
        return response.data
      })
      .catch(error => {
        notify(
          'error',
          'Failed',
          mapError(
            error,
            'Something went wrong while fetching the tenant list.'
          )
        )
        return undefined
      })
  }, [handleAuth, notify])

  /**
   * Fetches all users of a tenant
   *
   * @param tenantId the id of the tenant
   * @returns the users of a tenant as an array or undefined on error
   */
  const fetchUsers = useCallback(
    async (tenantId: string): Promise<DiceApiUserInfo[] | undefined> => {
      await handleAuth()
      return await axiosAdminInstance
        .get<DiceApiUserInfo[]>(`/tenants/${tenantId}/users/`)
        .then(response => {
          return response.data
        })
        .catch(error => {
          notify(
            'error',
            'Failed',
            mapError(
              error,
              'Something went wrong while fetching the user list.'
            )
          )
          return undefined
        })
    },
    [handleAuth, notify]
  )

  /**
   * Creates a new tenant in DB and optionally provision initial users
   *
   * @param input - the object containing the provisioning configuration
   * @returns the response of the aws stepfunctions execution
   */
  const provisionTenant = useCallback(
    async (
      input: TenantsProvisionRequest
    ): Promise<TenantsProvisionResponse | undefined> => {
      await handleAuth()
      return await axiosAdminInstance
        .post<TenantsProvisionResponse>('/tenants', input)
        .then(response => {
          return response.data as TenantsProvisionResponse
        })
        .catch(error => {
          notify(
            'error',
            'Failed',
            mapError(
              error,
              'Something went wrong while provisioning the tenant.'
            )
          )
          return undefined
        })
    },
    [handleAuth, notify]
  )

  /**
   * Fetches the current status of a step function execution
   *
   * @param executionArn - the arn of the execution ot lookup
   * @returns the response of the aws stepfunctions execution
   */
  const getStepFunctionExecutionStatus = useCallback(
    async (
      executionArn: string
    ): Promise<ExecutionsGetResponse | undefined> => {
      await handleAuth()
      return await axiosAdminInstance
        .get<ExecutionsGetResponse>(`/executions/${executionArn}`)
        .then(response => {
          return response.data as ExecutionsGetResponse
        })
        .catch(error => {
          notify(
            'error',
            'Failed',
            mapError(
              error,
              'Something went wrong while provisioning the tenant.'
            )
          )
          return undefined
        })
    },
    [handleAuth, notify]
  )

  return {
    fetchTenants,
    fetchUsers,
    getStepFunctionExecutionStatus,
    provisionTenant,
    tenants,
  }
}

const SystemAdminContext = createContext({})

export const SystemAdminProvider = (props: {
  children: React.ReactNode
  notify: (type: string, text1: string, text2?: string) => void
}): JSX.Element => {
  const value = useProvideSystemAdmin(props.notify)

  return (
    <SystemAdminContext.Provider value={value}>
      {props.children}
    </SystemAdminContext.Provider>
  )
}

export const useSystemAdmin = () => {
  return useContext(SystemAdminContext) as ReturnType<
    typeof useProvideSystemAdmin
  >
}
