import { Box } from '@chakra-ui/react'
import { EventsCard } from '@components/data/events/EventsCard'
import { TimeRangeFilterBox } from '@components/filters'
import { BaseContainer } from '@components/layout'
import { SectionHeader } from '@components/typography'

const Events = () => {
  return (
    <BaseContainer>
      <Box className='space-y-4'>
        <SectionHeader title='Events' />
        <TimeRangeFilterBox />
        <EventsCard />
      </Box>
    </BaseContainer>
  )
}

export default Events
