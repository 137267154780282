import { Button } from '@chakra-ui/react'
import { type InfiniteQueryObserverBaseResult } from '@tanstack/react-query'

/**
 * Props for the LoadMoreButton component
 *
 * @template TData - The type of data returned by the infinite query
 * @template TError - The type of error returned by the infinite query
 */
export type LoadMoreButtonProps<TData, TError> = Pick<
  InfiniteQueryObserverBaseResult<TData, TError>,
  'fetchNextPage' | 'hasNextPage' | 'isFetchingNextPage'
> & { className?: string }

/**
 * Load More Button component that can be used to load more items in an infinite list
 */
export const LoadMoreButton = <TData, TError>({
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  className,
}: LoadMoreButtonProps<TData, TError>) => {
  return (
    <Button
      className={className}
      isDisabled={isFetchingNextPage || hasNextPage !== true}
      onClick={async () => await fetchNextPage()}
      variant='link'
    >
      {hasNextPage === false ? 'nothing more to load' : 'load more…'}
    </Button>
  )
}
