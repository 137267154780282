import { type DT } from '.'
import { type S3Location } from './server'
import {
  type DiceApiAttachment,
  type DiceApiChainingOperator,
  type DiceApiSeverity,
  type DiceApiSimpleModeAttribute,
  type DiceApiSimpleModeOperator,
} from '@haesh/dice-api'

/**
 * @deprecated use {@link DiceApiSecondaryIndex} instead
 */
export type SecondaryIndex = {
  columns: string[]
  displayName: string
  id: string
  // GSI1, GSI2,.....
  indexName: string
  type: 'GROUPBY'
}

/**
 * @deprecated use {@link DiceApiStreamType} instead
 */
export enum StreamType {
  api = 'api',
  idtoken = 'idtoken',
  internal = 'internal',
  result = 'result',
}

export type SimpleRecipient =
  | { customUser: { email: string; name: string } }
  | { userId: string }

export enum NotificationCycle {
  DAILY = 'daily',
  MONTHLY = 'monthly',
  REALTIME = 'realtime',
  WEEKLY = 'weekly',
}

export type NotificationSetting = {
  failCycles: NotificationCycle[]
  passCycles: NotificationCycle[]
  recipient: SimpleRecipient
  type: 'EMAIL'
}

export type ExecutionTarget = {
  arn: string
  type: 'lambda' | 'stepfunction'
}

export type Rule = DT & {
  conditions: Condition[]
  controlId: string
  description?: string
  displayName?: string
  executionTarget: ExecutionTarget
  inputStreams?: string[]
  resultStreams?: string[]
  ruleId: string
  tenantId: string
}

export type InputRowId = {
  rowId: string
  streamId: string
  timestamp: string
  transactionId: string
}

export type AggregationHelper = {
  count: number
  distinctCount: Record<string, Record<string, number>>
  sum: Record<string, number>
}

export type StreamRowInfo = DT & {
  aggregationHelpers?: AggregationHelper
  bytes?: Uint8Array
  cid: string
  commitId?: string
  resultMetadata?: {
    controlId: string
    error?: Record<string, unknown>
    inputRowIds: InputRowId[]
    pass: boolean
    reason?: string
    remoteLocation?: S3Location
    ruleId: string
    severity?: DiceApiSeverity
  }
  rowId: string
  streamId: string
  tenantId: string
  timestamp: string
  transactionId?: string
  triggerRules?: Array<{
    controlId: string
    resultStreamId?: string
    topicId?: string
  }>
  triggerWorkflows?: Array<{ controlId: string; stateMachineArn: string }>
  type?: string
}

export type Stats = DT & {
  controlId?: string
  dateString: string
  fails: number
  passes: number
  remediations: number
  ruleId?: string
  tenantId: string
}

export type StreamUpdateMessage = {
  cid: string
  rowId: string
  streamId: string
  tenantId: string
  timestamp: string
  type: 'STREAM_UPDATE'
}

export enum RuleExecutionType {
  ENTIRE_ROW = 'fullRow',
  ROW_ID_LIST = 'rowIds',
}

export type RuleExecutionStreamMessage = {
  inputRowIds: InputRowId[]
  type: RuleExecutionType.ROW_ID_LIST
}

export enum ChainingOperator {
  AND = '&&',
  OR = '||',
}

export type ConditionClause<T = unknown> = {
  attribute: DiceApiSimpleModeAttribute
  comparisonValue: T
  operator: DiceApiSimpleModeOperator
}

export type Condition = {
  chainingOperator: DiceApiChainingOperator
  clauses: ConditionClause[]
  outcome: string
}

export type JsonataConditionClause = {
  expression: string
}

export type JsonataCondition = {
  chainingOperator: DiceApiChainingOperator
  clauses: JsonataConditionClause[]
  outcome: string
}

/**
 * @deprecated use {@link DiceApiRemediation}
 */
export type Remediation = DT & {
  attachments: DiceApiAttachment[]
  authorId: string
  controlId: string
  message: string
  rowId: string
  ruleId: string
  streamId: string
  tenantId: string
  timestamp: string
}

export enum RiskCategory {
  HIGH = 'high',
  LOW = 'low',
  MEDIUM = 'medium',
}

export enum ControlCategoryType {
  SIMPLE = 'simple',
  WORKFLOW = 'workflow',
}

export enum ControlLogicType {
  DETECTIVE = 'detective',
  PREVENTIVE = 'preventive',
}

export enum ControlFrequency {
  ANNUALLY = 'annually',
  DAILY = 'daily',
  EACH_TRANSACTION = 'each transaction',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  WEEKLY = 'weekly',
}

export enum ControlStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
