import { useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { isErrorResponse } from './utils'

export type QueryNotifierProps = {
  /**
   * The content to be rendered by the component
   */
  children: React.ReactNode
  /**
   * A function that will be called for rendering notifications
   */
  notify?: (
    type: 'error' | 'success' | 'warning',
    title: string,
    description?: string
  ) => void
}

/**
 * A component that patches the global `QueryClient`'s default options to include
 * `mutations` that will call the `notify` function when a mutation request finishes
 * with an error or success.
 *
 * This will override any existing `onError` or `onSuccess` for mutations.
 *
 * The `notify` function is passed as a prop to the component.
 */
export const QueryNotifier = ({ children, notify }: QueryNotifierProps) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const defaultOptions = queryClient.getDefaultOptions()
    queryClient.setDefaultOptions({
      ...defaultOptions,
      mutations: {
        ...defaultOptions.mutations,
        onError(error) {
          // if there is an error response message, use that
          if (error instanceof AxiosError && isErrorResponse(error.response)) {
            notify?.('error', 'Error', error.response.data.message)
          } else {
            notify?.('error', 'Error', 'An error occurred.')
          }
        },
        onSuccess() {
          notify?.('success', 'Success', 'The operation was successful.')
        },
      },
    })
  })

  return <>{children}</>
}
