import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/outline'
import { useCallback } from 'react'
import { type To, useNavigate } from 'react-router-dom'

type GoBackHomeProps = {
  position?: 'left' | 'right'
  text?: string
  /**
   * Location to navigate to
   *
   * @default the previous page
   */
  to?: To
}

const GoBackHome = ({ position = 'right', text, to }: GoBackHomeProps) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    if (to) {
      navigate(to)
    } else {
      navigate(-1)
    }
  }, [navigate, to])

  return (
    <button
      className='items-center text-base font-medium text-primary-500 hover:text-primary-550 focus:outline-none'
      onClick={handleClick}
      type='button'
    >
      {position === 'left' && (
        <ArrowNarrowLeftIcon className='inline h-6 mr-1.5 pb-0.5' />
      )}
      <span>{text ?? 'Go back home'}</span>
      {position === 'right' && (
        <ArrowNarrowRightIcon className='inline h-6 ml-1.5 pb-0.5' />
      )}
    </button>
  )
}

export default GoBackHome
