import { Box, type BoxProps, Skeleton } from '@chakra-ui/react'
import { type DiceApiExecutionStatsEntry } from '@haesh/dice-api'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  type ChartData,
  type ChartOptions,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { useIntl } from 'react-intl'

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Tooltip)

export type TimelineStatsBoxProps = BoxProps & {
  byDay?: Record<string, DiceApiExecutionStatsEntry>
}

const chartOptions = {
  interaction: { intersect: false },
  maintainAspectRatio: false,
  plugins: { legend: { display: false } },
  responsive: true,
  scales: { x: { stacked: true }, y: { display: false, stacked: true } },
} satisfies ChartOptions<'bar'>

const datasetOptions = {
  barPercentage: 1,
  categoryPercentage: 1,
}

export const TimelineStatsBox = ({
  byDay,
  ...boxProps
}: TimelineStatsBoxProps) => {
  const intl = useIntl()

  const chartData = useMemo<ChartData<'bar'> | undefined>(() => {
    if (byDay === undefined) return undefined

    return {
      datasets: [
        {
          ...datasetOptions,
          backgroundColor: 'rgb(74, 222, 128)',
          data: Object.values(byDay).map(({ pass, fail, error }) =>
            fail + error + pass === 0 ? 1 : pass / (fail + error + pass)
          ),
          label: 'Pass rate',
        },
        {
          ...datasetOptions,
          backgroundColor: 'rgb(248, 113, 113)',
          data: Object.values(byDay).map(({ fail, pass, error }) =>
            fail + error + pass === 0 ? 0 : fail / (fail + error + pass)
          ),
          label: 'Fail rate',
        },
        {
          ...datasetOptions,
          backgroundColor: 'rgb(255, 0, 0)',
          data: Object.values(byDay).map(({ error, pass, fail }) =>
            fail + error + pass === 0 ? 0 : error / (fail + error + pass)
          ),
          label: 'Error rate',
        },
      ],
      labels: Object.keys(byDay).map(date => intl.formatDate(date)),
    }
  }, [byDay, intl])

  return (
    <Box {...boxProps}>
      <Skeleton height={100} isLoaded={chartData !== undefined}>
        {chartData !== undefined && (
          <Bar data={chartData} options={chartOptions} />
        )}
      </Skeleton>
    </Box>
  )
}
