import React from 'react'

export type SectionHeaderProps = {
  children?: React.ReactNode
  className?: string
  description?: string
  /**
   * The size of the header, 0 = small; 5 = huge
   *
   * @default 3
   */
  size?: 0 | 1 | 2 | 3 | 4 | 5
  title: string
}

const SectionHeader = ({
  title,
  description,
  children,
  size = 3,
  className,
}: SectionHeaderProps) => {
  const sizes = [
    ['text-md leading-4 font-semibold', 'text-xs'],
    ['text-lg leading-5 font-semibold', 'text-sm'],
    ['text-xl leading-6 font-semibold', 'text-md'],
    ['text-2xl leading-7 font-bold', 'text-lg'],
    ['text-3xl leading-8 font-bold', 'text-xl'],
    ['text-4xl leading-9 font-bold', 'text-2xl'],
  ]
  return (
    <div className={className}>
      <div className='pb-3 sm:flex sm:items-center sm:justify-between'>
        <div>
          <div className={`${sizes[size][0]} text-slate-900`}>{title}</div>
          {description && (
            <p className={`${sizes[size][1]} font-normal text-slate-600`}>
              {description}
            </p>
          )}
        </div>
        {children && (
          <div className='flex mt-3 space-x-2 sm:mt-0 sm:ml-4'>{children}</div>
        )}
      </div>
    </div>
  )
}

export default SectionHeader
