import { isErrorResponse } from '@haesh/react-use-dice'
import { PermissionError } from '@hooks/useRequirePermission'
import { type FallbackRender } from '@sentry/react'
import ErrorScreen, { type ErrorScreenProps } from '@views/ErrorScreen'
import { AxiosError } from 'axios'

/**
 * A fallback component that is rendered when an error occurs.
 *
 * @param errorData - An object containing information about the error.
 * @returns A JSX element representing an error screen.
 */
export const ErrorScreenFallback: FallbackRender = errorData => {
  const { error } = errorData

  const errorScreenProps: ErrorScreenProps = {
    ...errorData,
    header: error.message,
    title: error.name,
  }

  if (error instanceof AxiosError) {
    errorScreenProps.title = 'Failed to Load Data'
    errorScreenProps.subtext =
      'Check that the address is correct, or contact us if you believe there is a bug.'
    if (isErrorResponse(error.response)) {
      errorScreenProps.header = error.response.data.message
    }
  } else if (error instanceof PermissionError) {
    errorScreenProps.title = 'Access Denied'
    errorScreenProps.subtext =
      'Please contact your administrator if you believe this is an error.'
  }

  return <ErrorScreen {...errorScreenProps} />
}
