/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable canonical/id-match */
/* eslint-disable canonical/filename-match-regex */

import Base64Url from 'crypto-js/enc-base64url'
import Utf8 from 'crypto-js/enc-utf8'

export const utf8_to_b64 = (string_: string) => {
  return Utf8.parse(string_).toString(Base64Url)
}

export const b64_to_utf8 = (string_: string) => {
  return Base64Url.parse(string_).toString(Utf8)
}
