import { type SelectOption } from './inputs/FormikInput'
import { Listbox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline'
import { classNames } from '@utils'
import canonicalize from 'canonicalize'
import React from 'react'

export type SelectListboxInputProps = {
  className?: string
  disabled?: boolean
  dropDownHeight?: 'lg' | 'md' | 'sm'
  error?: string
  listboxKey?: string
  onBlur?: (
    event?: React.ChangeEvent | boolean | number | string | unknown
  ) => void
  onChange?: (
    event?: React.ChangeEvent | boolean | number | string | unknown
  ) => void
  options?: SelectOption[]
  placeholder?: string
  touched?: boolean
  value?: unknown
}

const SelectListbox = ({
  className,
  listboxKey,
  placeholder,
  disabled,
  value,
  error,
  touched,
  onChange,
  onBlur,
  options = [],
  dropDownHeight = 'md',
}: SelectListboxInputProps) => {
  const listBoxHeight =
    dropDownHeight === 'lg'
      ? 'max-h-80'
      : dropDownHeight === 'sm'
      ? 'max-h-40'
      : 'max-h-60'
  return (
    <div className={className}>
      <Listbox
        disabled={disabled}
        key={listboxKey}
        onChange={onChange}
        value={value}
      >
        <div className='relative col-span-2'>
          <Listbox.Button
            className={`${
              disabled === true ? 'bg-gray-100 text-gray-400' : 'bg-white'
            } ${
              !(error === undefined) && touched === true
                ? 'chakra-error-border !border-[1px]'
                : 'border border-slate-300 focus:ring-1 focus:ring-primary focus:border-primary'
            } h-[40px] select-none transition-all relative w-full py-2 pl-3 pr-10 text-left rounded-md shadow-sm cursor-default outline-none focus:outline-none sm:text-sm`}
            // eslint-disable-next-line react/jsx-handler-names
            onBlur={onBlur}
          >
            <span className='block truncate'>
              {options.find(a => {
                return JSON.stringify(a.value) === JSON.stringify(value)
              })?.label ??
                placeholder ??
                'Please select'}
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
              <SelectorIcon
                aria-hidden='true'
                className='w-5 h-5 text-slate-400'
              />
            </span>
          </Listbox.Button>
          <Listbox.Options
            className={`absolute z-10 w-auto min-w-full py-1 mt-1 overflow-auto text-base transition-none bg-white rounded-md shadow-lg outline-none ${listBoxHeight} ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
          >
            {options.map((item, index) => {
              return (
                <Listbox.Option
                  className={({ active }) => {
                    return classNames(
                      active ? 'text-white bg-orange-600' : 'text-slate-900',
                      ' cursor-default select-none relative py-2 pl-3 pr-9 group'
                    )
                  }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.label}-${index}`}
                  value={item.value}
                >
                  {({ active }) => {
                    return (
                      <div className=''>
                        <span
                          className={classNames(
                            item.value === value
                              ? 'font-semibold'
                              : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {item.label}
                        </span>
                        {canonicalize(item.value) === canonicalize(value) ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-orange-600',
                              ' absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon aria-hidden='true' className='w-5 h-5' />
                          </span>
                        ) : undefined}
                        {item.renderDescription && (
                          <div>{item.renderDescription()}</div>
                        )}
                      </div>
                    )
                  }}
                </Listbox.Option>
              )
            })}
            {options.length === 0 && (
              <Listbox.Option
                className='relative py-2 pl-3 cursor-default pointer-events-none select-none pr-9'
                onChange={undefined}
                value={undefined}
              >
                <span className='text-xs italic font-light text-slate-400'>
                  no options available
                </span>
              </Listbox.Option>
            )}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  )
}

export default SelectListbox
