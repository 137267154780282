import { Button, type ButtonProps } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

/**
 * Props for the FormikSubmitButton component.
 *
 * This type extends the props for the Chakra Button component,
 * with the exception of the `type` prop, which is overridden to `submit`.
 */
export type FormikSubmitButtonProps = Omit<ButtonProps, 'type'>

/**
 * A submit button that is compatible with the Formik form library.
 *
 * This component uses the `useFormikContext` hook to get the
 * current status of the form, and disables the button when the
 * form state is invalid, submitting, or not dirty.
 *
 * It also displays a loading indicator when the submission handler is executing.
 *
 * @param props Reasonable defaults are provided for all props, but you can override them.
 */
export const FormikSubmitButton = (props: FormikSubmitButtonProps) => {
  // Get the current status of the form from the Formik context.
  const { isSubmitting, isValid, dirty, isValidating } = useFormikContext()

  // Determine if there is any reason to disable the button.
  const isDisabled = isSubmitting || !isValid || !dirty

  // Determine if the submission handler is executing.
  const isLoading = !isValidating && isSubmitting

  return (
    <Button
      isDisabled={isDisabled}
      isLoading={isLoading}
      loadingText='Submitting'
      spinnerPlacement='end'
      {...props}
      type='submit'
    >
      {props.children ?? 'Submit'}
    </Button>
  )
}
