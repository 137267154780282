import {
  DiceApiAccessRole,
  type DiceApiPatchUserInfo,
  type PostUsersMutationBody,
} from '@haesh/dice-api'
import {
  type TenantsProvisionRequest,
  type BillingInfo,
  type LoginOptions,
  SubscriptionTier,
} from '@haesh/dice-types'
import { type O } from 'ts-toolbelt'
import * as yup from 'yup'

/**
 * Format for a given accessRole or businessRole
 * - only letters, numbers and hyphens allowed
 * - at least 3 characters
 * - trim leading/trailing whitespace
 */
export const diceRole = yup
  .string()
  .required()
  .trim()
  .min(2)
  .matches(/^[\w-]+$/u, 'Only letters, numbers and hyphens are allowed')

/**
 * Most basic shape of a profile contained in the [`User`]
 */
const profileShape = {
  familyName: yup.string().required('Last Name is required'),
  givenName: yup.string().required('First Name is required'),
  picture: yup.string(),
}

/**
 * Shape of a {@link PostUsersMutationBody} used to create a new user
 */
export const UsersPostShape: yup.ObjectSchema<PostUsersMutationBody> = yup
  .object({
    accessRole: yup
      .string()
      .oneOf<DiceApiAccessRole>(Object.values(DiceApiAccessRole))
      .required(),
    businessRoles: yup.array().of(diceRole).required(),
    email: yup.string().email().required('E-Mail is required'),
    password: yup.string().optional(),
    permanent: yup.boolean().optional(),
    profile: yup
      .object(profileShape)
      .required('profile is a required field [object]')
      .noUnknown(true)
      .strict(),
  })
  .noUnknown(true)
  .strict()
  .defined()

/**
 * Shape of a [`DiceApiPatchUserInfo`] used to alter an existing user
 */
export const UsersPatchShape: yup.ObjectSchema<DiceApiPatchUserInfo> = yup
  .object({
    accessRole: yup
      .string()
      .oneOf<DiceApiAccessRole>(Object.values(DiceApiAccessRole)),
    businessRoles: yup.array(diceRole),
    profile: yup.object(profileShape).strict().noUnknown(true),
  })
  .noUnknown(true)
  .strict()
  .defined()

// payment method override because yup doesn't recognize the `.optional()` in the type
export const BillingInfoShape: yup.ObjectSchema<
  O.Required<BillingInfo, 'paymentMethod'>
> = yup
  .object({
    address: yup
      .object({
        addressLine1: yup.string().required('Address Line is required'),
        addressLine2: yup.string(),
        addressLine3: yup.string(),
        contactName: yup.string(),
        countryCode: yup
          .string()
          .required('The country code of the address is required'),
        locality: yup.string().required('The city of the address is required'),
        organizationName: yup.string().required('Company Name is required'),
        // TODO: check if it only contains digits
        postalCode: yup
          .string()
          .required('The ZIP Code of the address is required'),
        region: yup
          .string()
          .required('The region (province/state) of the addresss is required'),
      })
      .required(),
    email: yup
      .string()
      .email('Must be a valid E-Mail')
      .required('Billing E-Mail is required'),
    paymentMethod: yup.object({
      id: yup.string().required(),
    }),
    // TODO: vatId could be validated in more depth
    vatId: yup.string(),
  })
  .noUnknown(true)
  .strict()
  .defined()

export const LoginOptionsShape: yup.ObjectSchema<
  Pick<LoginOptions, 'usernamePasswordIsEnabled'>
> = yup
  .object({
    usernamePasswordIsEnabled: yup.boolean().required(),
  })
  .noUnknown(true)
  .strict()
  .defined()

export const NewTenantShape: yup.ObjectSchema<TenantsProvisionRequest> = yup
  .object({
    alias: yup
      .string()
      .trim()
      .required('The Tenant Alias is required')
      .min(3, 'Must be at least 3 Charakters')
      .matches(
        /^[.\w-]+$/u,
        'Must consist only of letters, dots and underscores'
      ),
    billingInfo: BillingInfoShape.required(),
    displayName: yup.string().required('Company Name is required'),
    isActive: yup.boolean().required(),
    loginOptions: LoginOptionsShape.required(),
    subscriptionTier: yup
      .string()
      .required()
      .oneOf(Object.values(SubscriptionTier)),
    users: yup.array().of(UsersPostShape).required(),
  })
  .noUnknown(true)
  .strict()
  .defined()
