/**
 * Legacy spinner
 *
 * @deprecated use Chakra spinner instead
 */
const Spinner = () => {
  return (
    <div className='z-50 flex items-center justify-center h-full md:h-3/4'>
      <div
        className='border-4 border-orange-200 rounded-full animate-spin h-28 w-28'
        style={{ borderTopColor: '#ff4500' }}
      />
    </div>
  )
}

/**
 * Legacy spinner
 *
 * @deprecated use Chakra spinner instead
 */
export const ButtonSpinner = () => {
  return (
    <div className='z-50 flex items-center justify-center h-full mx-4 md:h-3/4'>
      <div
        className='w-5 h-5 border-orange-200 rounded-full animate-spin border-3'
        style={{ borderTopColor: '#fff' }}
      />
    </div>
  )
}

export default Spinner
