import { type DiceApiUserInfo } from '@haesh/dice-api'
import React from 'react'

type UserSelectListItemProps = {
  user: DiceApiUserInfo
}

const UserSelectListItem = ({ user }: UserSelectListItemProps) => {
  return (
    <div className='text-xs '>
      <p>
        Email:{' '}
        <strong>
          {user.email ?? (
            <span className='italic font-normal text-slate-400'>none</span>
          )}
        </strong>
      </p>
      <p>
        Business Roles:{' '}
        <strong>
          {'businessRoles' in user ? (
            user.businessRoles.join(', ').trim()
          ) : (
            <span className='italic font-normal text-slate-400'>none</span>
          )}
        </strong>
      </p>
    </div>
  )
}

export default UserSelectListItem
