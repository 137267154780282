import { getLoginOptionsTenantAlias, axiosInstance } from '@haesh/dice-api'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createContext, useCallback, useContext, useEffect } from 'react'
import { useUser } from './AuthProvider'
import { QueryNotifier } from './QueryNotifier'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (
          error instanceof AxiosError &&
          error.response?.status !== undefined &&
          error.response?.status >= 400 &&
          error.response?.status < 500
        ) {
          // If the error is an instance of AxiosError with status 400-499,
          // return false to indicate that the request should not be retried.
          return false
        } else {
          // Otherwise, if the request has failed fewer than 3 times,
          // return true to indicate that the request should be retried.
          return failureCount < 3
        }
      },
      useErrorBoundary: true,
    },
  },
})

const useProvideDiceApi = (
  notify: (type: string, text1: string, text2?: string) => void
) => {
  const { getIdToken } = useUser()

  /**
   * intercepts http requests to add a JWT auth token
   * to the HTTP Authorization header
   */
  useEffect(() => {
    axiosInstance.interceptors.request.use(async config => {
      const idToken = await getIdToken()

      if (idToken !== undefined) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${idToken}`,
        }
      }

      return config
    })
  }, [getIdToken])

  /**
   * Fetches tenant metadata from the public tenant API,
   * used to retrieve the name and the configuration of e.g. userpool
   *
   * @param tenantAlias the alias of the tenant
   * @returns the tenant Configuration or a error message
   * @deprecated use `useGetLoginOptionsTenantAlias()`
   */
  const findTenant = useCallback(
    async (tenantAlias: string) => {
      return await getLoginOptionsTenantAlias(tenantAlias)
        .then(data => {
          if ('userPoolId' in data) {
            return data
          }

          const message =
            "We couldn't find an organization with this identifier"
          notify('info', 'Not found', message)
          return message
        })
        .catch(() => {
          const message = 'Failed to find an organization with this identifier'
          notify('error', 'Failed', message)
          return message
        })
    },
    [notify]
  )

  return {
    findTenant,
  }
}

const DiceApiContext = createContext({})

export const DiceApiProvider = (props: {
  children: React.ReactNode
  notify: (type: string, text1: string, text2?: string) => void
}): JSX.Element => {
  const value = useProvideDiceApi(props.notify)

  return (
    <QueryClientProvider client={queryClient}>
      <DiceApiContext.Provider value={value}>
        <QueryNotifier {...props} />
      </DiceApiContext.Provider>
    </QueryClientProvider>
  )
}

export const useDiceApi = () => {
  return useContext(DiceApiContext) as ReturnType<typeof useProvideDiceApi>
}
