import { useState } from 'react'

export const useDisclosure = (
  initialState: boolean,
  callbacks?: { onClose?: () => void; onOpen?: () => void }
) => {
  const [opened, setOpened] = useState(initialState)

  const open = () => {
    if (!opened) {
      setOpened(true)
      callbacks?.onOpen?.()
    }
  }

  const close = () => {
    if (opened) {
      setOpened(false)
      callbacks?.onClose?.()
    }
  }

  const toggle = () => (opened ? close() : open())

  return [opened, { close, open, toggle }] as const
}
