import { useUser } from '@haesh/react-use-dice'
import { PersistFormikValues } from 'formik-persist-values'
import { useLocation } from 'react-router-dom'

export const PersistForm = () => {
  const { pathname } = useLocation()
  const { userAttributes } = useUser()

  // to prevent interference between user sessions
  const name = `${userAttributes?.sub}#${pathname}`

  return <PersistFormikValues name={name} persistInvalid />
}
