import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultProps,
} from '@chakra-ui/react'

export const ChakraTheme = extendTheme(
  {
    colors: {
      brand: {
        '50': '#ffd8ca',
        '100': '#FFCBB8',
        '200': '#FFBCA3',
        '300': '#FF9E7A',
        '400': '#FF8152',
        '500': '#FF6329',
        '600': '#FF4500',
        '700': '#C73600',
        '800': '#8F2700',
        '900': '#571700',
      },
      slate: {
        '50': '#FAFBFE',
        '100': '#F4F4FB',
        '200': '#E9ECFB',
        '300': '#cbd5e1',
        '400': '#94a3b8',
        '500': '#64748b',
        '600': '#475569',
        '700': '#334155',
        '800': '#222B45',
        '900': '#0f172a',
      },
    },
    fonts: {
      body: 'Mulish',
      heading: 'Mulish',
    },
    fontWeights: {
      black: 999,
      bold: 800,
      extrabold: 900,
      hairline: 200,
      light: 450,
      medium: 650,
      normal: 550,
      semibold: 700,
      thin: 300,
    },
    shadows: {
      outline: '0 0 0 3px var(--chakra-colors-brand-300)',
    },

    styles: {
      global: {
        'html, body, td': {
          fontWeight: 600,
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: 'brand',
  }),
  withDefaultProps({
    components: ['Box', 'Button'],
    defaultProps: {
      focusBorderColor: 'green.700',
    },
  })
)
